import React from 'react';
import { useDispatch } from 'react-redux';
import { ukloniRobu, ukloniUslugu } from '../../../store/actions/RacuniActions';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/x-delete.svg';
import { getUkupnaCijenaSaPdv } from '../../../helpers/racuni';

const NoviRacunPreviewStavka = ({ roba, usluga }) => {
  const dispatch = useDispatch();

  const stavka = roba || usluga;

  const handleRemove = () => {
    if (roba) {
      dispatch(ukloniRobu(roba));
    }
    if (usluga) {
      dispatch(ukloniUslugu(usluga));
    }
  };

  function getPopustProcenat() {
    return roba?.atribut_robe?.popust_procenti || usluga?.tip_popusta
  }

  function getPopustIznos() {
    return roba?.atribut_robe?.popust_iznos || usluga?.grupa?.popust_iznos;
  }

  function getTipPopusta() {
    if(usluga.tip_popusta === 'procenat') {
      return usluga.popust.toFixed(
        2
      ) + '%'
    }
    if(usluga.tip_popusta === 'iznos_popusta_sa_pdv' || usluga.tip_popusta === 'iznos_popusta_bez_pdv') {
      return 'u iznosu od'
    }
  }

  function getUkupnaCijenaStavkeSaPopustom() {
    let ukupnaCijena = +usluga.ukupna_cijena * +stavka.kolicina;
    let popust = +usluga.popust;
    let stopa = usluga && usluga.porez && usluga.porez.stopa ? Number(usluga.porez.stopa) : 0;
    const formatValue = (value) => value.toFixed(2).replace('.', ',');

    if (usluga.tip_popusta === 'procenat') {
        return formatValue(ukupnaCijena - (ukupnaCijena * popust / 100)) + '€';
    }

    if (usluga.tip_popusta === 'iznos_popusta_sa_pdv') {
        return formatValue(ukupnaCijena - (popust * +stavka.kolicina)) + '€';
    }

    if (usluga.tip_popusta === 'iznos_popusta_bez_pdv') {
      const totalDiscount = (usluga.popust * stavka.kolicina) + (stopa*stavka.kolicina);
      return formatValue(ukupnaCijena - totalDiscount) + '€';
    }

    return '0,00€';
  }

  function getCijenaSaPopustom() {
    if(usluga.tip_popusta === 'procenat') {
      return (getUkupnaCijena() - getUkupanPopustProcenat()).toFixed(
        2
      ) + '€'
    }
    return (getUkupnaCijena() - getUkupanPopustIznos()).toFixed(
      2
    ) + '€'
  }


  function getUkupanPopustZaStavku() {
    if (!usluga.popust || usluga.popust <= 0) {
      return null;
    }

    const formatValue = (value) => value.toFixed(2).replace('.', ',');

    if (usluga.tip_popusta === 'procenat') {
      const totalDiscount = (parseFloat(usluga.ukupna_cijena) * stavka.kolicina) -
          ((parseFloat(usluga.ukupna_cijena) - parseFloat(usluga.ukupna_cijena) * (parseFloat(usluga.popust) / 100)) * stavka.kolicina);
      return '-' + formatValue(totalDiscount) + '€';
    }

    if (usluga.tip_popusta === 'iznos_popusta_sa_pdv') {
      const totalDiscount = usluga.popust * stavka.kolicina;
      return '-' + formatValue(totalDiscount) + '€';
    }

    if(usluga.tip_popusta === 'iznos_popusta_bez_pdv'){
      let stopa = usluga && usluga.porez && usluga.porez.stopa ? Number(usluga.porez.stopa) : 0;

      const totalDiscount = (usluga.popust * stavka.kolicina) + (stopa*stavka.kolicina);
      return '-' + formatValue(totalDiscount) + '€';
    }
  }

  function getUkupanPopustProcenat() {
    const popustProcenat =
      roba?.atribut_robe?.popust_procenti || 0// || usluga?.popust;
    return ((Number(popustProcenat) * Number(getUkupnaCijena())) / 100).toFixed(
      2
    );
  }
  function getUkupanPopustIznos() {
    return +getPopustIznos() > 0 ? +getPopustIznos() * +stavka.kolicina : 0;  
  }

  function getUkupnaCijena() {
    return roba
      ? Number(roba?.roba?.cijene_roba?.[0]?.ukupna_cijena) *
          Number(stavka.kolicina)
      : Number(usluga?.ukupna_cijena) * Number(stavka.kolicina);
  }

  return (
    <div className="side-info__wrapper">
      <div className="side-info__info as-end mb-10">
        <div className="side-info__info--inner-wrapper mb-0">
          <div className="col-l w-break">
            <p className="txt-dark">{roba ? roba.roba.naziv : usluga.naziv}</p>
            {stavka.opis && <p className="txt-light">{stavka.opis}</p>}
          </div>
          <div className="col-r w-break-unset">
            <div className="spn-mr-10 df">
            {roba
              ? Number(roba.roba.cijene_roba[0].ukupna_cijena)
                  .toFixed(2)
                  .replace('.', ',') + '€'
              : Number(usluga.ukupna_cijena).toFixed(2).replace('.', ',') + '€'}

              <span className="btn btn__link danger df ai-start" onClick={handleRemove}>
                <DeleteIcon />
              </span>
            </div>
          </div>
        </div>
        <div className="side-info__info--inner-wrapper mb-0">
          <div className="col-l w-break">
            <p className="ml-15 txt-dark">
              {stavka.kolicina} x{' '}
            {roba
              ? Number(roba.roba.cijene_roba[0].ukupna_cijena)
                  .toFixed(2)
                  .replace('.', ',') + '€'
              : Number(usluga.ukupna_cijena).toFixed(2).replace('.', ',') + '€'}
            </p>
          </div>
          <div className="col-r w-break-unset mr-m">
            
               {getPopustProcenat() === 'procenat'
                ? (getUkupnaCijena() - Number(getUkupanPopustProcenat()))
                    .toFixed(2)
                    .replace('.', ',') + '€'
                : (Number(getUkupnaCijena()) - Number(getUkupanPopustIznos()))
                    .toFixed(2)
                    .replace('.', ',') + '€'}
          </div>
        </div>

        {
          // prikaz popusta na stavci start
          getUkupanPopustZaStavku() && (
           <div>
           <div className="side-info__info--inner-wrapper mb-0">
             <div className="col-l w-break">
               <p className="ml-15 txt-dark">Popust {getTipPopusta()}</p>
             </div>
             <div className="col-r w-break-unset">
               <span className="mr-m">
                 {getUkupanPopustZaStavku()}
               </span>
             </div>
           </div>

           <div className="side-info__info--inner-wrapper mb-0">
           <div className="col-l w-break">
             <p className="ml-15 txt-dark">Cijena sa popustom</p>
           </div>
           <div className="col-r w-break-unset">
             <span className="mr-m">
               {getUkupnaCijenaStavkeSaPopustom()}
             </span>
           </div>
         </div>
           </div>
          )
          // prikaz popusta na stavci end
        }

        {
          getPopustIznos() && (
          <>
            <div className="side-info__info--inner-wrapper mb-0">
              <div className="col-l w-break">
                {(Number(getPopustProcenat()) > 0 ||
                  Number(getPopustIznos()) > 0) && (
                  <p className="ml-15 txt-dark">
                    Popust{' '}
                    {Number(getPopustProcenat()) > 0
                      ? getPopustProcenat() + '%'
                      : 'u iznosu'}
                  </p>
                )}
              </div>
              {(Number(getUkupanPopustIznos()) > 0 ||
                Number(getUkupanPopustProcenat()) > 0) && (
                <div className="col-r w-break-unset">
                  {Number(getUkupanPopustProcenat()) > 0 ? (
                    <span className="mr-m">
                      -
                      {Number(getUkupanPopustProcenat())
                        .toFixed(2)
                        .replace('.', ',') + '€'}
                    </span>
                  ) : (
                    <span className="mr-m">
                      -
                      {Number(getUkupanPopustIznos())
                        .toFixed(2)
                        .replace('.', ',') + '€'}
                    </span>
                  )}
                </div>
              )}
            </div>
            {(Number(getPopustProcenat()) > 0 ||
              Number(getPopustIznos()) > 0) && (
              <div className="side-info__info--inner-wrapper mb-0">
                <div className="col-l w-break">
                  <p className="ml-15 txt-dark">Cijena sa popustom</p>
                </div>
                <div className="col-r w-break-unset">
                  <span className="mr-m">
                    {Number(getPopustProcenat()) > 0
                      ? (
                          Number(getUkupnaCijena()) -
                          Number(getUkupanPopustProcenat())
                        )
                          .toFixed(2)
                          .replace('.', ',') + '€'
                      : (
                          Number(getUkupnaCijena()) -
                          Number(getUkupanPopustIznos())
                        )
                          .toFixed(2)
                          .replace('.', ',') + '€'}
                  </span>
                </div>
              </div>
            )}


            {/* {stavka.tip_popusta === 'iznos_popusta_bez_pdv' && <div className="side-info__info--inner-wrapper mb-0">
                <div className="col-l w-break">
                  <p className="ml-15 txt-dark">Ukupna cijena sa pdv</p>
                </div>
                <div className="col-r w-break-unset">
                  <span className="mr-m">
                    {getUkupnaCijenaSaPdv(stavka).toFixed(2)
                          .replace('.', ',') + '€'}
                  </span>
                </div>
              </div>} */}
          </>
        )
      }
      </div>
    </div>
  );
};

export default NoviRacunPreviewStavka;
