import React, { useEffect, useState } from 'react';

import {
  getUkupanPopust,
  getUkupnaCijenaSaPdv,
  getUkupnaCijenaBezPdv,
  formatirajCijenuBez0,
} from '../../../helpers/racuni';
import { partneriService } from '../../../services/PartneriService';
import Moment from 'react-moment/dist';

const ZavisniTroskoviPreview = ({ values }) => {
const [partners, setPartners] = useState(null)
  const getPartners =  async () => {
    let partnersFromApi =  await partneriService.getPartneriDropdown()
  setPartners(partnersFromApi)
  }

  useEffect(() => {
    getPartners()
  }, [])


  const getPartnerLabel = (partner_id) => {
    if(partners && partner_id){
      let partner = partners.find((x) => x.value === partner_id)

      return partner && partner.label ? partner.label : partner_id
    }

    return '-'
  }



  return (
    <>
      <h2 className="heading-secondary">Zavisni Troškovi</h2>
      <div className="main-content__box mt-3">
        <div className="invoice invoice-temp" style={{ width: '100%', backgroundColor: '#fff' }}>

            
          <div className="table-overflow-wrapper">
            <table className="table">
              <thead>
                <tr>
                <th>
                    <span className="heading-quaternary">Naziv</span>
                  </th>
                  <th>
                    <span className="heading-quaternary">Broj dokumenta</span>
                  </th>
                  <th>
                    <span className="heading-quaternary">Dobavljač</span>
                  </th>
                
               
                  <th>
                    <span className="heading-quaternary">Datum izdavanja/Datum za plaćanje</span>
                  </th>
                   
                  <th>
                    <span className="heading-quaternary">Iznos oslobođen</span>
                  </th>

                   
                  <th>
                    <span className="heading-quaternary">Iznos po stopi 0</span>
                  </th>

                   
                  <th>
                    <span className="heading-quaternary">Iznos po sniženoj stopi</span>
                  </th>
                   
                  <th>
                    <span className="heading-quaternary">Iznos po opštoj stopi</span>
                  </th>
                 
                  <th>
                    <span className="heading-quaternary">Ukupno bez PDV</span>
                  </th>
                 
                </tr>
              </thead>

              <tbody>
                {values && values.length>0 && values.map((stavka, index) => {
                  return (
                    <tr
                      key={'zavisni_troskovi' + String(stavka?.id)}
                      draggableId={'zavisni_troskovi' + String(stavka?.id)}
                      index={index}
                    >
                      <td>
                      <p className="fw-500">
                          { stavka.naziv }
                      </p>
                      <p className="mt-2 cl">
                          {stavka?.opis ? stavka?.opis : ''}
                      </p>
                      </td>

                      <td>{stavka?.broj_dokumenta}</td>
                      <td>{getPartnerLabel(stavka.partner_id)}</td>
                    
                     
                      
                      <td>

                      <Moment locale="me" format="DD. MMM YYYY.">
                        {stavka?.datum_izdavanja}
                      </Moment> /<br/>
                      <Moment locale="me" format="DD. MMM YYYY.">
                        {stavka?.datum_za_placanje}
                      </Moment>
                      </td>
                      <td>
                        {formatirajCijenuBez0(stavka.oslobodjena_stopa)}
                      </td>

                      <td>
                          {formatirajCijenuBez0(stavka.stopa_0)}
                      </td>

                      <td>
                          {formatirajCijenuBez0(stavka.snizena_stopa)}
                      </td>
                      <td>
                          {formatirajCijenuBez0(stavka.opsta_stopa)}
                      </td>
                      <td>
                        {formatirajCijenuBez0(parseFloat(stavka?.ukupna_cijena))}
                      </td>
                   
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <hr className="hr-main" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ZavisniTroskoviPreview;
