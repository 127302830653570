import React from 'react';
import { useDispatch } from 'react-redux';
import { LIST, STAVKE } from '../../../constants/layout';
import { getStavke } from '../../../store/actions/RacuniActions';
import List from '../../shared/lists/List';
import NoviRacunTableRow from './NoviRacunTableRow';
import Pagination from '../../shared/lists/Pagination';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const NoviRacunTable = ({ robe, usluge, view , page, tab}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { last_page } = usluge;

  const handlePageClick = (data) => {
    history.push(`/racuni/create?page=${++data.selected}`);
    dispatch(getStavke({ page: data.selected}));
  }

  return (
    <>
      <div className={`${view === LIST ? "table-overflow-wrapper" : "table-wrapper-grid-view"} ${tab === STAVKE ? "show-stavke" : "hide-stavke"}`}>
        <table className={view === LIST ? "table" : "table-grid-view-racuni"}>
          <thead>
            <tr className={view === LIST ? "table-bg" : "d-none "}>
              <th className="w-45 mob-w-100 min-width">
                <span className="heading-quaternary">Usluge/robe</span>
              </th>
              <th className="w-7">
                <span className="heading-quaternary">JM</span>
              </th>
              <th className="w-23 txt-right">
                <span className="heading-quaternary">Cijena sa PDV</span>
              </th>
              <th className="w-23">
                <span className="heading-quaternary">Tip popusta</span>
              </th>
              <th className="w-25">
                <span className="heading-quaternary">Popust</span>
              </th>
              <th className="w-25">
                <span className="heading-quaternary">Dodaj</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <List
              data={robe.data}
              renderItem={({item}) => <NoviRacunTableRow view={view} key={item.id} roba={item}/>}
              onItemClick={() => {}}
            />
            <List
              data={usluge.data}
              renderItem={({item}) => <NoviRacunTableRow view={view} key={item.id} usluga={item}/>}
              onItemClick={() => {}}
            />
          </tbody>
        </table>
      </div>
      <Pagination
       pageCount={last_page}
       page={page}
       handlePageClick={handlePageClick}
      />
    </>
  );
};

export default NoviRacunTable;