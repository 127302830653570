import React, { useState } from 'react';

function KlonirajModal({ onCancel, onConfirm }) {
  const [klonirajOption, setKlonirajOption] = useState('privremeni');

  return (
    <div className="modal open" onClick={onCancel}>
      <div
        className="modal__content"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="modal__header">
          <span className="modal__close" onClick={onCancel}>
            &times;
          </span>
          <h2 className="heading-secondary m-0">Kloniraj račun</h2>
        </div>
        <div className="modal__body">
          <p className='mb-20'>Kreiraće se identičan račun sa svim stavkama kao i izabrani.</p>
          <form>
            <input
              type="radio"
              id="privremeni"
              className="mr-3 mt-2"
              onChange={() => setKlonirajOption('privremeni')}
              checked={klonirajOption === 'privremeni'}
            />
            <label htmlFor="privremeni">
              Kloniraj račun i sačuvaj kao privremeni
            </label>
            <br></br>

            <input
              type="radio"
              id="fiskalizuj"
              className="mr-3"
              onChange={() => setKlonirajOption('fiskalizuj')}
              checked={klonirajOption === 'fiskalizuj'}

            />
            <label htmlFor="fiskalizuj">
              Kloniraj račun i fiskalizuj na današnji dan
            </label>
          </form>
        </div>

        <div className="modal__footer">
          <button
            type="submit"
            className="btn btn__primary"
            onClick={() => {
              onConfirm(klonirajOption);
            }}
          >
            Kloniraj
          </button>

          <button
            type="button"
            className="btn btn__link ml-m"
            onClick={onCancel}
          >
            Obustavi
          </button>
        </div>
      </div>
    </div>
  );
}

export default KlonirajModal;
