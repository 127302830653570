export const STATUS = [
  { value: 'placen', label: 'Plaćen' },
  { value: 'nenaplativ', label: 'Nenaplativ' },
  { value: 'cekaSe', label: 'Čeka Se' },
  { value: 'privremeni', label: 'Privremeni' },
  { value: 'nenaplativDug', label: 'Nenaplativ Dug' },
];

export const TIPOVI_POPUSTA = [
  { value: 'procenat', label: 'Procenat %' },
  { value: 'iznos_popusta_bez_pdv', label: 'Iznos na jed. cijenu bez PDV' },
  { value: 'iznos_popusta_sa_pdv', label: 'Iznos na jed. cijenu sa PDV' },
];

export const TIPOVI_POREZA = [
  { id: 4, value: 0.21, label: '21%' },
  { id: 3, value: 0.07, label: '7%' },
  { id: 2, value:"0.0", label: '0%' },
  { id: 1, value: '0', label: 'Oslobodjen PDV' },
];

export const KOREKTIVNI_RACUN = [
  { value: '0', label: 'Nije' },
  // { value: 'CORRECTIVE', label: 'Korektivni račun' },
  // { value: 'DEBIT', label: 'Debit' },
  // { value: 'CREDIT', label: 'Credit' },
];

export const TIPOVI_RACUNA = [
  { value: 'racun', label: 'Račun' },
  { value: 'avans', label: 'Avansni račun' },
  { value: 'knjizno', label: 'Knjižno odobrenje' },
];

export const POSALJI_PODSJETNIK = [
  { value: 'bez_slanja', label: 'Bez slanja podsjetnika' },
  { value: 'dan_isteka_roka', label: 'Pošalji na dan isteka roka plaćanja' },
  { value: '2_dana_prije_isteka_roka', label: 'Pošalji 2 dana prije roka plaćanja' },
  { value: '5_dana_prije_isteka_roka', label: 'Pošalji 5 dana prije roka plaćanja' },
  { value: '10_dana_prije_isteka_roka', label: 'Pošalji 10 dana prije roka plaćanja' },
];

export const VRIJEME_PODSJETNIKA = [
  { value: 'dnevni', label: 'Svaki dan' },
  { value: 'sedmicni', label: 'Svake nedjelje' },
  { value: 'mjesecni', label: 'Svakog mjeseca' },
  { value: 'godisnji', label: 'Svake godine' },
];

export const DAN_SLANJA_NEDELJA = [
  { value: 1, label: 'Svakog Ponedjeljka' },
  { value: 2, label: 'Svakog Utorka' },
  { value: 3, label: 'Svake Srijede' },
  { value: 4, label: 'Svakog Četvrtka' },
  { value: 5, label: 'Svakog Petka' },
];

export const PDV_OBVEZNIK = [
  { value: 1, label: 'Da' },
  { value: 0, label: 'Ne' },
];

export const NACIN_PLACANJA_BEZGOTOVINSKI = [
  { value: 'ACCOUNT', label: 'ACCOUNT - Transakcioni Račun, Virman' },
  { value: 'BUSINESSCARD', label: 'BUSINESSCARD - Biznis kartica' },
  { value: 'SVOUCHER', label: 'SVOUCHER - Jednokratni Vaučer' },
  { value: 'COMPANY', label: 'COMPANY - Poklon Kartice, Prepaid Kartice itd.' },
  { value: 'ADVANCE', label: 'ADVANCE - Plaćanje Avansom' },
  { value: 'FACTORING', label: 'FACTORING - Faktoring' },
  { value: 'OTHER', label: 'OTHER - Ostala Plaćanja' },
];

export const NACIN_PLACANJA_GOTOVINSKI = [
  { value: 'BANKNOTE', label: 'BANKNOTE - Novčanice i Kovanice' },
  { value: 'CARD', label: 'CARD - Kartica' },
  // {
  //   value: 'ORDER',
  //   label: 'ORDER - račun još nije plaćen biće plaćen zbirnim/sumarnim računom',
  // },
  { value: 'OTHER-CASH', label: 'OTHER-CASH - ostala gotovinska plaćanja' },
];
