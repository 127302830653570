import ApiService from './ApiService';
import axios from 'axios';
import { BASE_URL } from '../config/index';

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
};

const ENDPOINTS = {
  RACUNI: 'racuni',
  AVANSNI_RACUNI: 'avansni-racuni',
  IKOF_REFERENCE: 'ikof-reference',
  RACUN: 'racuni/{id}',
  ROBE: 'robe-racuni',
  INFORMACIJE: 'racuni-informacije',
  USLUGE: 'usluge',
  ATRIBUTI_GRUPE: 'atributi-grupe',
  UPDATE_STATUS: 'update-status/{id}',
  UPDATE_OPIS: 'update-opis/{id}',
  FISKALIZUJ: 'nefiskalizovani-racuni/{id}',
  STORNIRAJ: 'storniraj-racun/{id}',
  KLONIRAJ: 'kloniraj-racun/{id}',
  NAPRAVI_RACUN: 'predracuni/napravi-racun/{id}',
  DODAJ_PODSJETNIK: 'podsjetnici',
  UPDATE_OPIS_ULAZNIH_RACUNA: 'ulazni-racuni-opis/{id}',
  UPDATE_STATUS_ULAZNIH_RACUNA: 'ulazni-racuni-status/{id}',
  UPDATE_RACUN_PREVIEW: "racun-postavke/{id}"


};

class RacuniService extends ApiService {
  getRacuni = (params) => this.apiClient.get(ENDPOINTS.RACUNI, { params });

  getAvansniRacuni = (params) => this.apiClient.get(ENDPOINTS.AVANSNI_RACUNI, { params });
  getIkofReference = (params) => this.apiClient.get(ENDPOINTS.IKOF_REFERENCE, { params });

  getRacuniInformacije = (params) =>
      this.apiClient.get(ENDPOINTS.INFORMACIJE, { params });

  storeRacun = (noviRacun) => {
    const usluge = Object.keys(noviRacun.usluge).map((uslugaId) => ({
      usluga_id: uslugaId,

      popust_procenati: noviRacun.usluge[uslugaId].grupa.popust_procenti,
      popust_iznos: noviRacun.usluge[uslugaId].grupa.popust_iznos,

      tip_popusta: noviRacun.usluge[uslugaId].tip_popusta,
      popust: noviRacun.usluge[uslugaId].popust,

      pdv_iznos: noviRacun.usluge[uslugaId].porez.stopa,
      kolicina: noviRacun.usluge[uslugaId].kolicina,
    }));

    const robe = Object.keys(noviRacun.robe).map((robaId) => ({
      usluga_id: robaId,
      atribut_id: noviRacun.robe[robaId].atribut_robe?.id,
      kolicina: noviRacun.robe[robaId].kolicina,
    }));
    const stavke = [...usluge, ...robe];

    return this.apiClient.post(ENDPOINTS.RACUNI, {
      stavke,
      vrsta_racuna: 'gotovinski',
      nacin_placanja: noviRacun.nacin_placanja,
      partner_id: noviRacun?.partner_id,
    });
  };

  storeBezgotovinskiRacun = (values) => {
    values.stavke = values.stavke.map((stavka) => {
      return stavka;
    });

    return this.apiClient.post(ENDPOINTS.RACUNI, {
      ...values,
      // preduzece_id: '028b4491-6a9a-459b-be42-f7bccca522d6'
    });
  };

  getRobe = (params) => this.apiClient.get(ENDPOINTS.ROBE, { params });

  getUsluge = (params) => this.apiClient.get(ENDPOINTS.USLUGE, { params });

  getRacun = (id) => this.apiClient.get(ENDPOINTS.RACUN.replace('{id}', id));

  updateRacun = (data) =>
    this.apiClient.put(ENDPOINTS.RACUN.replace('{id}', data.id), data);


  updateRacunPreview = (data) =>
    this.apiClient.put(ENDPOINTS.UPDATE_RACUN_PREVIEW.replace('{id}', data.id), data);

  deleteRacun = (id) =>
    this.apiClient.delete(ENDPOINTS.RACUN.replace('{id}', id));

  getRacuniDropdown = async (search) => {
    const { data } = await this.getRacuni({ search });
    return data.data.map((racun) => ({
      value: racun.id,
      label: racun.ime,
    }));
  };

  updateStatus = (params) => {
    return this.apiClient.post(
      ENDPOINTS.UPDATE_STATUS.replace('{id}', params.id),
      params
    );
  };


  updateStatusUlaznihRacuna = (params) => {
    return this.apiClient.post(
      ENDPOINTS.UPDATE_STATUS_ULAZNIH_RACUNA.replace('{id}', params.id),
      params
    );
  };

  updateOpis = async (params) => {
    await this.apiClient.post(
      ENDPOINTS.UPDATE_OPIS.replace('{id}', params.id),
      params
    );
  };



  updateOpisUlaznihRacuna = async (params) => {
    await this.apiClient.post(
      ENDPOINTS.UPDATE_OPIS_ULAZNIH_RACUNA.replace('{id}', params.id),
      params
    );
  };

  getAtributiGrupe = (params) => {
    return this.apiClient.get(ENDPOINTS.ATRIBUTI_GRUPE, { params });
  };

  fiskalizujRacun = (id, vrijemeKorektivnog = 'trenutni') => {
    console.log("vrijemeKorektivnog", vrijemeKorektivnog)
    return this.apiClient.post(ENDPOINTS.FISKALIZUJ.replace('{id}', id), {vrijemeKorektivnog: vrijemeKorektivnog});
  };

  stornirajRacun = (id, vrijemeStorniranja) => {
    return this.apiClient.post(ENDPOINTS.STORNIRAJ.replace('{id}', id), vrijemeStorniranja);
  };

  klonirajRacun = (id, data) => {
    return this.apiClient.post(ENDPOINTS.KLONIRAJ.replace('{id}', id), data);
  };

  napraviRacun = (id, fiskalizuj) => {
    return this.apiClient.post(ENDPOINTS.NAPRAVI_RACUN.replace('{id}', id), {fiskalizuj: fiskalizuj});
  };

  napraviIFiskalizujRacun = (id, fiskalizuj) => {
    return this.apiClient.post(ENDPOINTS.NAPRAVI_RACUN.replace('{id}', id), {fiskalizuj: fiskalizuj});
  };

  napraviIFiskalizujRacun = (id, fiskalizuj) => {
    return this.apiClient.post(ENDPOINTS.NAPRAVI_RACUN.replace('{id}', id), {fiskalizuj: fiskalizuj});
  };

  dodajPodsjetnik = (values) => {
    return this.apiClient.post(ENDPOINTS.DODAJ_PODSJETNIK, {...values});
  };
}

export const racuniService = new RacuniService();
