import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setKolicinaUsluge,
} from '../../../store/actions/RacuniActions';
import {
  noviRacunRobaSelector,
  noviRacunUslugaSelector,
} from '../../../store/selectors/RacuniSelector';
import KolicinaStavke from './KolicinaStavke';
import {TIPOVI_POPUSTA} from "../../../constants/racuni";
import Select from "react-select";
import { toast } from 'react-toastify';
import { GRID } from '../../../constants/layout';

const NoviRacunTableRow = ({ usluga = {}, roba = {}, view}) => {
  const [tipPopusta, setTipPopusta] = useState('procenat');
  const [popust, setPopust] = useState(0);
const tempStavka = usluga || roba;
  const stavka = useSelector(
    usluga?.id
      ? noviRacunUslugaSelector(usluga?.id)
      : noviRacunRobaSelector(roba.id)
  ) || { kolicina: 0 };

  const dispatch = useDispatch();


  const handlePopust = (e) => {
    let inputValue = e.target.value;
    if(inputValue === '') {
      inputValue = '0';
    }
    if (inputValue === '-' || parseFloat(inputValue) < 0) {
      inputValue = '0';
      e.target.value = '0';
    }

    const newPopust = parseFloat(inputValue);
    if (!isNaN(newPopust) && newPopust >= 0) {
      if(tipPopusta.value === "iznos_popusta_bez_pdv" || tipPopusta.value === "iznos_popusta_sa_pdv"){

          if(newPopust < Number(stavka && stavka.cijena_bez_pdv ? stavka.cijena_bez_pdv : tempStavka.cijena_bez_pdv)*0.90){
            setPopust(newPopust);
              dispatch(setKolicinaUsluge(usluga, stavka.kolicina, newPopust, tipPopusta.value));
          }else{
            toast.error('Popust ne moze biti veći od cijene!')
            setPopust('')
            dispatch(setKolicinaUsluge(usluga, stavka.kolicina, '', tipPopusta.value));
          }



         
      }

      if(tipPopusta.value === "procenat"){
        if(newPopust < 90){
          setPopust(newPopust);
          dispatch(setKolicinaUsluge(usluga, stavka.kolicina, newPopust, tipPopusta.value));
        }else{
          toast.error('Popust ne moze biti veći od cijene!')
          setPopust('')
          dispatch(setKolicinaUsluge(usluga, stavka.kolicina, '', tipPopusta.value));

        }
    }
      
    } else {
      setPopust('');
    }
  }

  return (
    <tr className={`${stavka.kolicina ? 'active' : ''} ${view === GRID ? 'active-grid': ''}`}>
      <td>
        <p className={`${view === GRID ? 'heading-grid' : ''}`}>{usluga?.naziv || roba?.roba?.naziv}</p>
        <h3 className="heading-quaternary">
          {usluga?.opis?.length > 50
            ? usluga?.opis.substring(1, 50)
            : usluga?.opis || roba?.roba?.opis}
        </h3>
      </td>
      <td className="cl">
        {usluga?.jedinica_mjere?.naziv || roba?.roba?.jedinica_mjere?.naziv}
      </td>
      <td className="cd fw-500 txt-right mob-txt-left mb-05">
        <p>
          {Number(usluga?.ukupna_cijena)?.toFixed(2).replace('.', ',') + '€'}
        </p>
      </td>
      <td className="cd fw-500 txt-right mob-txt-left mt-2 ">
      <Select
      classNamePrefix="custom-select"
      options={TIPOVI_POPUSTA}
      name="tip_popusta"
      onChange={(option) => {
          setTipPopusta(option);
          setPopust('')
          if (stavka.kolicina > 0) {
              dispatch(setKolicinaUsluge(usluga, stavka.kolicina, '', option.value));
          }
      }}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
  />
  
</td>

      <td className="cd fw-500 txt-right mob-txt-left mt-2">
      <input
       name="popust"
       type="number"
       pattern="\d*"
       inputmode="numeric"                             
       autocomplete="off"
       className="form__input input-select"
       step=".01"
       min="0"
       placeholder="Popust"
       title="Iznos povrata bez PDV-a"
       value={popust ===0 ? '' : popust}
  onChange={handlePopust}
/>
      </td>
      <td>
        <KolicinaStavke usluga={usluga} roba={roba} stavka={stavka} popust={popust} tipPopusta={tipPopusta.value} />
    </td>
 
    
    </tr>
  );
};

export default NoviRacunTableRow;
