import React from 'react';
import {
  formatirajCijenuBez0,
  formatirajCijenuBez0BezE,
} from '../../../helpers/racuni';

function BezgotovinskiNoveCijenePreview({ values }) {
  return (
    <>
      <h2 className="heading-secondary">Nova nabavna</h2>
      <div className="main-content__box mt-3">
        <div
          className="invoice invoice-temp"
          style={{ width: '100%', backgroundColor: '#fff' }}
        >
          <div className="table-overflow-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <span className="heading-quaternary">Naziv</span>
                  </th>
                  {/* <th>
                    <span className="heading-quaternary">
                      Nova nabavna cijena
                    </span>
                  </th> */}

                  <th>
                    <span className="heading-quaternary">
                      Nova cijena bez pdv-a
                    </span>
                  </th>

                  <th>
                    <span className="heading-quaternary">Zavisni trošak</span>
                  </th>

                  <th>
                    <span className="heading-quaternary">Marža</span>
                  </th>

                  <th>
                    <span className="heading-quaternary">Pdv iznos</span>
                  </th>

                  <th>
                    <span className="heading-quaternary">
                      Nova ukupna cijena
                    </span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {values &&
                  values.length > 0 &&
                  values.map((stavka, index) => {
                    if (stavka.ulazne_stavke_kalkulacije) {
                      return (
                        <tr
                          key={'zavisni_troskovi' + String(stavka?.id)}
                          draggableId={'zavisni_troskovi' + String(stavka?.id)}
                          index={index}
                        >
                          <td>
                            <p className="fw-500">{stavka.naziv}</p>
                          </td>

                          {/* <td>
                            {formatirajCijenuBez0(
                              stavka?.ulazne_stavke_kalkulacije?.nabavna_cijena
                            )}
                          </td> */}

                          <td>
                            {formatirajCijenuBez0(
                              stavka?.ulazne_stavke_kalkulacije?.cijena_bez_pdv
                            )}
                          </td>

                          <td>
                            {formatirajCijenuBez0(
                              stavka?.ulazne_stavke_kalkulacije
                                ?.zavisni_troskovi
                            )}
                          </td>

                          <td>
                            {formatirajCijenuBez0BezE(
                              stavka?.ulazne_stavke_kalkulacije?.marza
                            )}
                            %
                          </td>

                          <td>
                            {formatirajCijenuBez0(
                              Number(
                                stavka?.ulazne_stavke_kalkulacije?.ukupna_cijena
                              ) -
                                Number(
                                  stavka?.ulazne_stavke_kalkulacije
                                    ?.cijena_bez_pdv
                                )
                            )}{' '}
                            <br />
                          </td>
                          <td>
                            {formatirajCijenuBez0(
                              stavka?.ulazne_stavke_kalkulacije?.ukupna_cijena
                            )}
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default BezgotovinskiNoveCijenePreview;
