import { useField } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { GRUPE, PROIZVODJACI } from '../../../constants/routes';
import { grupeService } from '../../../services/GrupeService';
import { proizvodjacService } from '../../../services/ProizvodjacService';
import Label from './Label';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const DropDown = React.memo(
  ({NewMargin,onKeyDown,
    onChangeExtra = null,
    label,
    defaultOptions = true,
    loadOptions,
    defaultValue,
    invalid,
    clearValue,
    ...props
  }) => {
    
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [isInitialValid, setIsInitialValid] = useState(false);

    const [field, meta, helpers] = useField(props);

    const { error } = meta;
    const { setValue, setError } = helpers;

    const [blurred, setBlurred] = useState(false);

    const [nazivGrupe, setNazivGrupe] = useState('');
    const [nazivProizvodjaca, setNazivProizvodjaca] = useState('');
    const selectRef = useRef();

    useEffect(() => {
      setSelectedLabel(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
     if(clearValue === null || clearValue === ''){
      setSelectedLabel('');
      setValue('')
     }
    }, [clearValue]);

    const handleProizvodjac = () => {
      const nazivProizvodjaca = prompt('Unesite naziv proizvođača: ');
      setNazivProizvodjaca(nazivProizvodjaca);

      proizvodjacService
        .storeProizvodjac({
          naziv: nazivProizvodjaca,
        })
        .then((data) => {
          setSelectedLabel({ value: data.data.id, label: data.data.naziv });
          setIsInitialValid(true);
          selectRef.current.loadOptions(
            proizvodjacService.getProizvodjaciDropdown
          );
        })
        .catch((err) => {
          toast.error(
            'Proizvođač sa tim nazivom već postoji! Unesite drugi naziv' + err,
            toastSettings
          );
        });
    };

    const handleGrupe = (e) => {
      e.preventDefault();
      const nazivGrupe = prompt('Unesite naziv grupe: ');
      if (nazivGrupe !== null && nazivGrupe !== '') {
        setNazivGrupe(nazivGrupe);

        grupeService
          .storeGrupa({ naziv: nazivGrupe })
          .then((data) => {
            setSelectedLabel({ value: data.data.id, label: data.data.naziv });
            setValue(data.data.id);
            setIsInitialValid(true);
            setError(null);
          })
          .catch((err) => {
            // var errormessage = '';
            // Object.keys(err.response.data.errors).forEach(function (key) {
            //   errormessage += err[key] + '<br />';
            // });

            // toast.error(
            //   'Desila se greška prilikom unosa grupe: ' + errormessage,
            //   toastSettings
            // );

            toast.error(
              'Nije moguće unijeti grupu: ' + err.response &&
                err?.response?.data?.errors?.naziv[0],
              toastSettings
            );
          });

      }
    };
    const selectStyle = {
      control: (styles) => ({
        ...styles,
        
        backgroundColor: '#F3F4F6',
        borderRadius: 4,
        height: '45px',
        minHeight: 'unset',
        width:NewMargin?.[1] ?? null,
        marginLeft:NewMargin?.[0] ?? null
        
      }),
    };

    return (
      <React.Fragment key={props.key}>
        <div className='df jc-sb'>
        <Label htmlFor={props.id || props.name} className="form__label">
          {label}
        </Label>
        {props.name === 'proizvodjac_robe_id' && (
          <button
            className="btn btn__link success"
            to={PROIZVODJACI.CREATE}
            onClick={handleProizvodjac}
          >
            + Novi
          </button>
        )}
        {props.name === 'grupa_id' && (
          <button className="btn btn__link success" to={GRUPE.CREATE} onClick={handleGrupe} type="button">
            + Nova
          </button>
        )}
        </div>
        <AsyncSelect
          ref={selectRef}
          name={field.name}
          onChange={(option) => {
            if (props.isMulti) {
              setValue(option.map((item) => item.value));
            } else {
              setSelectedLabel(option.label);
              setValue(option.value);
            }
            setSelectedLabel(option);
            if (onChangeExtra) onChangeExtra(option);
          }}
          value={selectedLabel !== null ? selectedLabel : defaultValue}
          styles={selectStyle}
          cacheOptions
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          isSearchable
          onBlur={() => setBlurred(true)}
          isInitialValid={isInitialValid}
          isValid={isInitialValid}
          onKeyDown={onKeyDown}
          {...props}
        />
        {!blurred && invalid && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
        {blurred && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </React.Fragment>
    );
  }
);

export default DropDown;
