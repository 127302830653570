import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  izracunajUkupnuCijenuStavki,
  izracunajUkupnuCijenuStavkiBezPdv,
  izracunajPojedinacnePoreze,
} from '../../../helpers/racuni';
import AsyncSelect from 'react-select/async';
import {
  storeRacun,
  getRacuni,
} from '../../../store/actions/RacuniActions';
import { noviRacunSelector } from '../../../store/selectors/RacuniSelector';
import NoviRacunPreviewStavka from './NoviRacunPreviewStavka';
import NoviRacunKusur from './NoviRacunKusur';
import { NACIN_PLACANJA_GOTOVINSKI } from '../../../constants/racuni';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { depozitWithdrawService } from '../../../services/DepozitWithdrawService';
import { RACUNI } from '../../../constants/routes';
import { partneriService } from '../../../services/PartneriService';
import { RACUN } from '../../../constants/layout';
import ModalDeposit from '../../shared/forms/ModalDeposit';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const NoviRacunPreview = ({tab}) => {
  // const componentRef = useRef();
  const noviRacun = useSelector(noviRacunSelector());
  const dispatch = useDispatch();

  // let previousUrl = localStorage.getItem('previousUrl');
  const [anonimniKupac, setAnonimniKupac] = useState();
  const [uslugeOrder, setUslugeOrder] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await partneriService.getPartneriDropdown().then((data) => {
        setAnonimniKupac(
          data.find(
            (data) =>
              data.label === 'Anonimni Korisnik' ||
              data.label === 'Anonimni Kupac'
          )
        );
      });
    };
    fetchData();
  }, []);

  const [value, setValue] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [nacinPlacanja, setNacinPlacanja] = useState('BANKNOTE');
  const [selectedValueKupac, setSelectedValueKupac] = useState(
    anonimniKupac?.value || 1
  );
  const [selectedLabelKupac, setSelectedLabelKupac] = useState(null);
  const [kupac, setKupac] = useState();
  const [showModal, setShowModal] = useState(false);
  const handleDepositLoaded = (props) => {
   setDepozitLoaded(props)
  };


  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   pageStyle: () => `
  //   // @page {
  //   //   size: 70mm 180mm;
  //   // }`,
  // });

  const history = useHistory();

  const [depozitLoaded, setDepozitLoaded] = useState(false);

  useEffect(() => {
    depozitWithdrawService
      .getDepozitToday()
      .then((data) => {
        if (data.data.fiskalizovan  === 1) {
          setDepozitLoaded(true);
        }
      })
      .catch((err) =>
        toast.error('Nije moguće učitati depozit!', toastSettings)
      );
  }, [depozitLoaded]);

  const handleSacuvaj = () => {

     if(ukPlati <= 0){ 
      toast.error('Račun ne može biti nula ili manje!', toastSettings);
      return;
     }

    if (
      // (noviRacun.robe.length === 0 || noviRacun.robe.length == undefined) &&
      // (noviRacun.usluge.length === 0 || noviRacun.usluge.length == undefined)
      ! Object.keys(noviRacun.robe).length &&
      ! Object.keys(noviRacun.usluge).length
    ) {
      toast.error('Račun mora imati bar jednu stavku!', toastSettings);
      return;
    }

    if (! depozitLoaded) {
      toast.error(
        'Depozit za današnji dan mora biti dodat prije nego što izdate gotovinski račun!',
        toastSettings
      );
      setShowModal(true)
      // history.push(RACUNI.INDEX);
      return;
    }

    dispatch(
      storeRacun({
        nacin_placanja: nacinPlacanja,
        partner_id: kupac ? kupac : anonimniKupac?.value,
      }, history)
    );
    // let racunId;
    // setTimeout(() => {
    //   racuniService.getRacuni().then((data) => {
    //     racunId = data.data.data[0].id;
    //     setTimeout(() => {
    //       if (previousUrl === '/racuni/create' && racunId) {
    //         history.push('/racuni/show/' + racunId);
    //       }
    //     }, 500);
    //   }, 1500);
    // });
    // dispatch(setRacun({}));
    // dispatch(resetNoviRacun());
    dispatch(getRacuni({tip: 'racun'}));
    history.push(RACUNI.INDEX);
  };

  const usluge = Object.keys(noviRacun.usluge).map(
    (id) => noviRacun.usluge[id]
  );
  const robe = Object.keys(noviRacun.robe).map((id) => noviRacun.robe[id]);
  // const ukupnaCijena = izracunajUkupnuCijenuStavki([...usluge, ...robe]);
  // const ukupnaCijenaBezPdv = izracunajUkupnuCijenuStavkiBezPdv([
  //   ...usluge,
  //   ...robe,
  // ]);
  const porezi = izracunajPojedinacnePoreze([...usluge, ...robe]);
  function vratiUkupanPdv() {
    var pdvUkupno = 0;
    for (const p in porezi) {
      pdvUkupno += Math.round(Number(porezi[p].pdvIznos) * 100) / 100;
    }
    return pdvUkupno;
  }

  function vratiUkupnoPlacanje() {
    var ukupnoPlacanje = 0;
    for (const p in porezi) {
      ukupnoPlacanje += Number(porezi[p].ukupno);
    }
    return ukupnoPlacanje;
  }
  const ukPdv = vratiUkupanPdv();
  const ukPlati = vratiUkupnoPlacanje();

  const uslugeStavka = uslugeOrder && uslugeOrder.length > 0 ? uslugeOrder.map((uslugaId) => (
    <NoviRacunPreviewStavka
      key={'usluga_' + uslugaId}
      usluga={{ ...noviRacun.usluge[uslugaId], usluga_id: uslugaId }}
    />
  )): [];


  const robeStavka =  Object.keys(noviRacun.robe).map((robaId) => (
    <NoviRacunPreviewStavka
      key={'roba_' + robaId}
      roba={{ ...noviRacun.robe[robaId], roba_id: robaId }}
    />
  ));

  let totalUkupnoZaPDV = 0;
  let oslobodenPDV = null;

  Object.keys(porezi).forEach((porezId) => {
    if (porezi[porezId].naziv === 'Oslobođen PDV-a') {
    oslobodenPDV = porezi[porezId];
    } else {
    totalUkupnoZaPDV += porezi[porezId].ukupno;
    }
  });

  useEffect(() => {
    if (Object.keys(noviRacun.usluge).length > uslugeOrder.length) {
      const newServiceId = Object.keys(noviRacun.usluge).find(
        id => !uslugeOrder.includes(id)
      );
      if (newServiceId) {
        setUslugeOrder(prevOrder => [...prevOrder, newServiceId]);
      }
    }else{

      const removedServiceId = uslugeOrder.find(
        id => !Object.keys(noviRacun.usluge).includes(id)
      );
      if (removedServiceId) {
        setUslugeOrder(uslugeOrder.filter(x => x !== removedServiceId));
      }
    }
  }, [noviRacun.usluge]);

  return (
    <div className={`side-info ${tab === RACUN ? 'show-racun' : 'hide-racun'}`}>
      {/* NoviRacunPrint - Template */}
      <div style={{ display: 'none' }}>
        {porezi.pdvUk}
        {/* <NoviRacunPrintTemplate
          ref={componentRef}
          ukupnaCijena={ukupnaCijena}
          usluge={uslugeStavka} robe={robeStavka} noviRacun={noviRacun}
          ukupnaCijenaBezPdv={ukupnaCijenaBezPdv}
          ukupnoPlacanje={ukupnaCijena}
          porezi={porezi}
        /> */}
      </div>

      <div className="side-info__wrapper w-100-imp">
        {/* Ukupno */}
        <p className="txt-light txt-up">ukupno</p>
        <h1 className="heading-primary">
          {ukPlati.toFixed(2).replace('.', ',')}{' '}
          <span className="txt-light">€</span>
        </h1>
      </div>

      {uslugeStavka}
      {robeStavka}
      <hr className="mtb-20" />
      {/* Porezi */}
      <>
        <div className="side-info__wrapper">
          {Object.keys(porezi).map((porezId) => (
            <React.Fragment key={porezId}>
              {porezi[porezId].naziv === 'Oslobođen PDV-a' ? (
                  <div className="side-info__info--inner-wrapper mb-0">
                    <div className="col-l w-break">
                      <p>{oslobodenPDV.naziv}</p>
                    </div>
                    <div className="col-r w-break-unset">
                      <p className="txt-right">
                        {oslobodenPDV.ukupno.toFixed(2).replace('.', ',') + '€'}
                      </p>
                    </div>
                  </div>
              ) : (
                <>
                  <div className="side-info__info--inner-wrapper mb-0">
                    <div className="col-l w-break">
                      <p>{`Ukupno za PDV ${porezi[porezId].naziv}`}</p>
                    </div>
                    <div className="col-r w-break-unset">
                      <p className="txt-right">
                        {porezi[porezId].ukupno.toFixed(2).replace('.', ',') + '€'}
                      </p>
                    </div>
                  </div>
                  <div className="side-info__info--inner-wrapper mb-0">
                    <div className="col-l">
                      <p>{`PDV ${porezi[porezId].naziv}`}</p>
                    </div>
                    <div className="col-r">
                      <p className="txt-right">
                        {porezi[porezId].pdvIznos.toFixed(2).replace('.', ',') + '€'}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          ))} 
          {totalUkupnoZaPDV !== 0 && (
            <div className="side-info__info--inner-wrapper mb-0">
              <div className="col-l w-break">
                <p>Ukupno za PDV</p>
              </div>
              <div className="col-r w-break-unset">
                <p className="txt-right">
                {totalUkupnoZaPDV.toFixed(2).replace('.', ',') + '€'}
                </p>
              </div>
            </div> 
          )}
        </div>

        <hr className="mtb-20" />
        <div className="side-info__wrapper">
          <div className="side-info__info--inner-wrapper mb-0">
            {/* Ukupan PDV */}
            <div className="col-l">
              <p>Ukupan PDV</p>
            </div>
            <div className="col-r">
              <p className="txt-right">
                {Number(ukPdv).toFixed(2).replace('.', ',') + '€'}
              </p>
            </div>
          </div>

          {/* Ukupno za plaćanje */}

          <div className="side-info__info--inner-wrapper mb-0">
            <div className="col-l">
              <p className="fw-600">Ukupno za plaćanje</p>
            </div>
            <div className="col-r">
              <p className="txt-right fw-600">
                {ukPlati.toFixed(2).replace('.', ',') + '€'}
              </p>
            </div>
          </div>
        </div>
        <hr className="mtb-20" />

        {/* Kusur */}
        <NoviRacunKusur ukupnaCijena={ukPlati} />
        <hr className="mtb-20" />
        {/* onClick={handlePrint} */}
        {/* <button className="btn btn__primary mb-10" onClick={handlePrint}>Fiskalizuj i štampaj</button> */}
        <div className="mtb-20">
          <label className="form__label">Način Plaćanja</label>
          <Select
            options={NACIN_PLACANJA_GOTOVINSKI}
            name="nacin_placanja"
            onChange={(option) => {
              setValue(option.value);
              setSelectedLabel(option);
              setNacinPlacanja(option.value);
            }}
            value={selectedLabel ? selectedLabel : NACIN_PLACANJA_GOTOVINSKI[0]}
            defaultValue={NACIN_PLACANJA_GOTOVINSKI[0]}
          />
        </div>
        <div className="mtb-20">
          <label className="form__label">Kupac</label>
          <AsyncSelect
            name="partner_id"
            loadOptions={partneriService.getPartneriDropdown}
            onChange={(option) => {
              setSelectedLabelKupac(option);
              setSelectedValueKupac(option.value);
              setKupac(option.value);
            }}
            value={
              selectedLabelKupac !== null ? selectedLabelKupac : anonimniKupac
            }
            defaultValue={anonimniKupac || 1}
            cacheOptions
            defaultOptions={true}
            isSearchable
          />
        </div>
        <button
          className="btn btn__primary mb-10 w-100"
          onClick={handleSacuvaj}
        >
          Fiskalizuj i štampaj
        </button>
        {/* <button className="btn btn__secondary w-100" onClick={handleSacuvaj}>
          Sačuvaj
        </button> */}
      </>


      <ModalDeposit
            showModal={showModal}
            closeModal={() =>{setShowModal(false)}}
            handleDepositLoaded={handleDepositLoaded}
          />
    </div>
  );
};

export default NoviRacunPreview;
