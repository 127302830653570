import React from 'react'
import { useDispatch } from 'react-redux';
import { ReactComponent as StepperMinus } from '../../../assets/icon/stepper-minus.svg';
import { ReactComponent as StepperPlus } from '../../../assets/icon/stepper-plus.svg';
import { setKolicinaRobe, setKolicinaUsluge } from '../../../store/actions/RacuniActions';

const KolicinaStavke = ({ stavka={ kolicina: 0 }, usluga, roba, popust, tipPopusta }) => {
  const dispatch = useDispatch();

  const handlePlusClick = () => {
    if (usluga.id) {
      dispatch(setKolicinaUsluge(usluga, stavka.kolicina + 1, popust, tipPopusta));
    }
    if (roba.id) {
      dispatch(setKolicinaRobe(roba, stavka.kolicina + 1, popust, tipPopusta));
    }
  }

  const handleMinusClick = () => {
    if (stavka.kolicina > 0) {
      if (usluga?.id) {
        dispatch(setKolicinaUsluge(usluga, stavka.kolicina - 1, popust, tipPopusta));
      }
      if (roba?.id) {
        dispatch(setKolicinaRobe(roba, stavka.kolicina - 1, popust, tipPopusta));
      }
    }
  }

  const handleInputChange = (event) => {
    const newQuantity =event.target.value
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      if (usluga?.id) {
        dispatch(setKolicinaUsluge(usluga, newQuantity));
      }
      if (roba?.id) {
        dispatch(setKolicinaRobe(roba, newQuantity));
      }
    } else if (event.target.value === '') {
      if (usluga?.id) {
        dispatch(setKolicinaUsluge(usluga, 0));
      }
      if (roba?.id) {
        dispatch(setKolicinaRobe(roba, 0));
      }
    }
  }
  

  return (
    <div className="stepper">
      <button className="stepper__btn-left" onClick={handleMinusClick}>
        <StepperMinus />
      </button>
      <input onChange={handleInputChange} className="stepper__input" value={stavka.kolicina}/>
      <button className="stepper__btn-right" onClick={handlePlusClick}>
        <StepperPlus />
      </button>
    </div>
  )
}

export default KolicinaStavke;
