import React from 'react';
import {
  formatirajCijenuBez0,
} from '../../../helpers/racuni';
import moment from 'moment';

const BezgotovinskiDjelimicnoPlacanjePreview = ({ dajlimicno_placanje }) => {

  return (
    <>
      <thead>
        <tr>
          <th className="w-50">
            <p className="heading-quaternary mb-0 nowrap">Datum</p>
          </th>
          <th className="w-50">
            <p className="heading-quaternary mb-0 nowrap">iznos uplate</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {dajlimicno_placanje.map((item, index) => {
          return (
            <tr key={index}>
              <td className="w-50">
               {moment(item?.datum).format("DD. MMM YYYY.")}
               <p className="mt-2 cl">
{item?.opis ?? ''}
                </p>
              </td>
              <td className="txt-right w-50">
                {formatirajCijenuBez0(item?.iznos)}{' '}
                <span className="txt-light"></span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

export default BezgotovinskiDjelimicnoPlacanjePreview;
