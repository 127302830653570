import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import { getNeprocitaneNovosti, getNovosti, getPoslednjeNovosti} from "../../store/actions/NovostiActions";
import { novostiSelector, poslednjeSelector } from "../../store/selectors/NovostiSelector";
import NovostiTable from './NovostiTable';
import PoslednjeNovostiTable from './PoslednjeNovostiTable';
import GridLoader from 'react-spinners/GridLoader';
import { spinnerStyleGrid } from '../../constants/spinner';

let filteri = {};

const Novosti = () => {
   const dispatch = useDispatch();
   const novosti = useSelector(novostiSelector());
   const poslednjeNovosti = useSelector(poslednjeSelector());
   const query = useLocation().search;
   const page = new URLSearchParams(query).get('page');

   const [ odabranaKategorija, setOdabranaKategorija ] = useState(null);

   let novostiKategorije = Array.from(
      new Set(novosti && novosti.data && novosti?.data?.map((novost) => novost.categories.naziv))
    ).map((naziv) => {
      return novosti?.data?.find((u) => u.categories.naziv === naziv);
    });

   useEffect(() => {
      dispatch(getNovosti({ page }));

       dispatch(getPoslednjeNovosti())
       dispatch(getNeprocitaneNovosti());
   }, [dispatch]);

   const resetFilters = () => {
      filteri = {};
      handleSearch(filteri);
      setOdabranaKategorija(null);
    };

   const handleKategorijaChange = (event) => {
      filteri.category_id = event;
      handleSearch(filteri);
      setOdabranaKategorija(filteri.category_id);
   };

   const handleSearch = (value) => {
      let filtered;
      if (Object.keys(value).length === 0 || value.hasOwnProperty('category_id')) {
        filteri.search = filtered;
      } else {
        filtered = value.replace(/[^0-9a-zA-Zžćšđč -]/gi, '');
      }
      dispatch(getNovosti(filteri));
   };

   if (! novosti) {
      return <GridLoader css={spinnerStyleGrid} size={20} />
   }

   return (
       <>
          <div className="title">
             <h1 className="heading-primary">Novosti</h1>
          </div>
          <div className="row">
             <div className="col-md-7 col-xl-8 col-sm-12 tab-pr-0">
                 {novosti &&
                 novosti.data &&
                 novosti.data.length === 0 &&
                 !novosti.path ? (
                     <GridLoader css={spinnerStyleGrid} size={15} />
                 ) : novosti && novosti.data && novosti.data.length === 0 ? (
                     <div className="msg-center">
                         <p> {'Nema sadržaja u listi'}</p>
                     </div>
                 ) : (
                     <NovostiTable novosti={novosti} page={page} />
                 )}
             </div>

             <div className="col-md-5 col-xl-4 col-sm-12">
                <div className="box-news">
                   <h3 className="heading-tertiary fw-500">Kategorije</h3>
                   <hr/>
                   <ul className="box-news__items">
                     <li className="box-news__item">
                        <p
                           onClick={() => resetFilters()}
                           className={`txt-dark ${! odabranaKategorija ? 'active' : ''}`}
                           style={{ cursor: "pointer" }}
                        >
                           Sve Kategorije
                        </p>
                     </li>
                     {novostiKategorije.map((novostKategorija) => (
                        <>
                           <li className="box-news__item">
                              <p
                                 onClick={() => handleKategorijaChange(novostKategorija?.categories?.id)}
                                 className={`txt-dark ${odabranaKategorija == novostKategorija?.categories?.id ? 'active' : ''}`}
                                 style={{ cursor: "pointer" }}
                              >
                                 { novostKategorija?.categories?.naziv }
                              </p>
                           </li>
                        </>
                     ))}
                   </ul>
                </div>
               <PoslednjeNovostiTable novosti={poslednjeNovosti} />
             </div>
          </div>
       </>
   );
};

export default Novosti;
