import { ErrorMessage, useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getStavke } from '../../../store/actions/RacuniActions';
import { getRoba, setRoba } from '../../../store/actions/RobeActions';
import { getUsluga, setUsluga } from '../../../store/actions/UslugeActions';
import {
  stavkeUslugeSelector,
} from '../../../store/selectors/RacuniSelector';
import Label from '../../shared/forms/Label';

const searchDebounced = debounce((callback) => callback(), 150);

const StavkeDropdown = ({
  onKeyDown,
  label,
  defaultValue,
  onChangeExtra = null,
  ...props
}) => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();

  // const robe = useSelector(stavkeRobeSelector()) || { data: [] };
  const usluge = useSelector(stavkeUslugeSelector()) || { data: [] };

  let options;
  if (usluge.data.length >= 15) {
    options = [
      // ...robe.data.map((roba) => ({ value: roba, label: roba.roba.naziv })),
      ...usluge.data.map((usluga) => ({ value: usluga, label: usluga.naziv })),
      {value: null, label: 'Za prikaz ostalih stavki unesite naziv...', disabled: true}
    ];
  } else {
    options = [
      // ...robe.data.map((roba) => ({ value: roba, label: roba.roba.naziv })),
      ...usluge.data.map((usluga) => ({ value: usluga, label: usluga.naziv })),
    ];
  }

  function onInputChange(searchValue) {
    if (values?.tip_racuna === 'knjizno' || values?.tip_racuna === 'avans') {
        return;
    }

    searchDebounced(() =>
      dispatch(getStavke({ search: searchValue || undefined }))
    );
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.keyCode === 38 || e.keyCode === 40) {
      return;
    }

    if (onChangeExtra) onChangeExtra(e.target.value, 'addManually');

    setValue(e.target.value);
  };

  const onStavkaChange = (option) => {
    if (option?.value?.roba_id) {
      dispatch(getRoba(option.value.roba_id));
      dispatch(setUsluga({}));
    } else {
      dispatch(getUsluga(option.value.id));
      dispatch(setRoba({}));
    }

    setValue({ ...option.value, kolicina: 0, tip_popusta: 'procenat', usluga_id: option.value.id });

    setFieldValue(`niz_stavke[0].nabavna_cijena`, option.value.cijena_bez_pdv);

    if (onChangeExtra) onChangeExtra(option.value, '');
  };

  const [field, meta, helpers] = useField(props);

  const { error } = meta;
  const { setValue } = helpers;
  const selectStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#F3F4F6',
      borderRadius: 4,
      height: '45px',
      minHeight: 'unset',
    }),
  };

  const renderTekst = () => {
    if (values.tip_racuna === 'racun' || values.tip_racuna === 'predracun' || values.tip_racuna === 'ulazni') {
      return 'Unesite naziv stavke...'
    } else if (values.tip_racuna === 'knjizno') {
      return 'Unesite naziv odobrenja...'
    } else if (values.tip_racuna === 'avans') {
      return 'Upišite na šta se odnosi uplata...';
    }
  }
  return (
    <div onKeyUp={onKeyUp}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Select
        styles={selectStyle}
        options={values.tip_racuna !== 'avans' ? options : []}
        isOptionDisabled={(option) => option.disabled}
        name={field.name}
        onChange={onStavkaChange}
        onInputChange={onInputChange}
        noOptionsMessage={() => 'Nova stavka'}
        value={{
          label:
            field.value?.roba?.naziv ||
            field.value?.naziv ||
            field.value ||
            values?.niz_stavke[0]?.naziv ||
            renderTekst(),
          value: field.value,
        }}
        onKeyDown={onKeyDown}
      />
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export default StavkeDropdown;
