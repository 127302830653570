import { Link } from "react-router-dom";
import { PREDRACUNI, RACUNI, ULAZNI_RACUNI } from "../constants/routes";

export const renderTitles = (pathName) => {
    if (pathName.includes('/predracuni')) {
      return {
        title: 'Kreiraj novi predračun',
        povratak: 'Povratak na Predračune',
        link: PREDRACUNI.INDEX,
      };
    } else if (pathName.includes('/ulazni-racuni')) {
      return {
        title: 'Kreiraj novi ulazni račun',
        povratak: 'Povratak na Ulazne Račune',
        link: ULAZNI_RACUNI.INDEX,
      };
    } else {
      return {
        title: 'Kreiraj novi bezgotovinski račun',
        povratak: 'Povratak na Račune',
        link: RACUNI.INDEX,
      };
    }
}


export const renderButtons = (setFieldValue, history, pathName) => {
  if (pathName.includes('/predracuni')) {
    return (
      <>
        <button
            onClick={() => {
              setFieldValue('fiskalizuj', false);
              setFieldValue('napraviRacun', false);
            }}
            className="btn btn__primary"
        >
          Sačuvaj
        </button>
        <button
          onClick={() => {
              setFieldValue('fiskalizuj', false);
              setFieldValue('napraviRacun', true);
          }}
          className="btn btn__secondary ml-m"
        >
          Sačuvaj i napravi račun
        </button>
        <button
          onClick={() => {
              setFieldValue('fiskalizuj', true);
              setFieldValue('napraviRacun', true);
          }}
          className="btn btn__secondary ml-m"
          >
          Sačuvaj, napravi i fiskalizuj račun
        </button>
        <button
            onClick={() => {
              history.push(PREDRACUNI.INDEX);
            }}
            className="btn btn__link ml-m"
        >
          <Link to={PREDRACUNI.INDEX}>Obustavi</Link>
        </button>
      </>
    );
  } else if (pathName.includes('/ulazni-racuni')) {
    return (
      <>
        <button
            type="submit"
            className="btn btn__primary"
        >
            Sačuvaj
        </button>
        <button
            onClick={() => {
              history.push(ULAZNI_RACUNI.INDEX);
            }}
            className="btn btn__link ml-m"
        >
          <Link to={ULAZNI_RACUNI.INDEX}>Obustavi</Link>
        </button>
      </>
    );
  } else {
    return (
      <>
        <button
            onClick={() => {
              setFieldValue('fiskalizuj', true);
            }}
            type="submit"
            className="btn btn__primary"
        >
          Sačuvaj i Fiskalizuj
        </button>
        <button
            onClick={() => {
                setFieldValue('fiskalizuj', false);
            }}
            className="btn btn__secondary ml-m"
        >
          Sačuvaj kao privremeni
        </button>
        <button
            onClick={() => {
              history.push(RACUNI.INDEX);
            }}
            className="btn btn__link ml-m"
        >
          <Link to={RACUNI.INDEX}>Obustavi</Link>
        </button>
      </>
    );
  }
}

export const renderStatus = (status) => {
  let color;
  let value;
  switch (status) {
    case 'nijeplacen':
      value = 'Nije Plaćen';
      color = 'tag tag__warning';
      break;

      case 'djelimicno_placen':
        value = 'Djelimično Plaćen';
        color = 'tag tag__warning';
        break;
    case 'storniran':
      value = 'Storniran';
      color = 'tag tag__danger';
      break;
    case 'placen':
      value = 'Plaćen';
      color = 'tag tag__success';
      break;
    case 'nenaplativ':
      value = 'Nenaplativ';
      color = 'tag tag__danger';
      break;
    case 'privremeni':
      value = 'Privremeni';
      color = 'tag tag__neutral';
      break;
    case 'korektivni':
      value = 'Korektivni';
      color = 'tag tag__neutral';
      break;
    case 'avansni':
      value = 'Avansni';
      color = 'tag tag__neutral';
      break;
    case 'knjizno':
      value = 'Knjižno odobrenje';
      color = 'tag tag__neutral';
      break;
    case 'poslat':
          value = 'Poslat';
          color = 'tag tag__success';
          break;
      case 'kreiran':
          value = 'Kreiran';
          color = 'tag tag__neutral';
          break;
  }

  return {value, color}
}
