import React, { useCallback, useEffect, useState } from "react";
import { formatirajCijenu } from "../../../helpers/racuni";


const ModalUlazniRacuni = ({ title, message, confirmText, cancelText, onCancel, onConfirm, stavke,saberiFalse, saberiTrue}) => {
    const [dodajTrosak, setDodajTrosak] = useState(true)

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            onCancel();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction);

        return () => {
            document.removeEventListener("keydown", escFunction);
        };
    }, [escFunction]);


    return (
        <div className="modal open" onClick={onCancel}>
            <div className="modal__content" onClick={(event) => event.stopPropagation()}>
                <div className="modal__header">
                    <span className="modal__close" onClick={onCancel}>
                      &times;
                    </span>
                    <h2 className="heading-secondary m-0">{title}</h2>
                </div>
                <div className="modal__body">
                    <p>
                        {message}
                    
                    </p>
                 

                 {stavke && stavke.length > 0 && 
               <div>
               <p>Stavke koje imaju zavisni trošak:</p>
                <ul style={{padding: '2rem'}}>
                    {stavke.map((stavka) =>(
                    <li><p>
                        {stavka.naziv}  - {formatirajCijenu(stavka.zavisni_troskovi ?? stavka?.ulazne_stavke_kalkulacije?.zavisni_troskovi ?? 0)}
                        </p></li>
                ))}
                </ul>
        <p>Da li želite da se zavisni trošak sabere sa već postojećim ili da se postavi novi?</p>
                <div className="form__radio-group">
                  <input
                    className="form__radio-input"
                    type="radio"
                    value="Saberi"
                    id="Saberi"
                    checked={dodajTrosak === true}
                    onChange={() => {
                        saberiTrue()
                        setDodajTrosak(true)
                    }}
                  />
                  <label className="form__radio-label" htmlFor="Saberi">
                    <span className="form__radio-button"></span>
                    <span className="mob-ml-10">Saberi</span>
                  </label>
                </div>


                <div className="form__radio-group">
                  <input
                    className="form__radio-input"
                    type="radio"
                    value="postavi novi"
                    id="novi"
                    checked={dodajTrosak === false}
                    onChange={() =>{
                         setDodajTrosak(false)
                        saberiFalse()
                        }}
                  />
                  <label className="form__radio-label" htmlFor="novi">
                    <span className="form__radio-button"></span>
                    <span className="mob-ml-10">Postavi novi</span>
                  </label>
                </div>
               </div>}
                </div>
                <div className="modal__footer">
                    <button
                        type="submit"
                        className="btn btn__primary"
                        onClick={() =>{
                            onConfirm()}}
                    >
                        {confirmText}
                    </button>

                    <button
                        type="button"
                        className="btn btn__link ml-m"
                        onClick={onCancel}
                    >
                        {cancelText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalUlazniRacuni;
