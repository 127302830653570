import { ErrorMessage, useField } from 'formik';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Label from './Label';

const DropDownStatic = ({onKeyDown, onChangeExtra, label, defaultValue, disabled, options, ...props }) => {
  const [selectedLabel, setSelectedLabel] = useState(null);

  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    setSelectedLabel(defaultValue);
  }, [defaultValue]);

  const { error } = meta;
  const { setValue } = helpers;
  const selectStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#F3F4F6',
      borderRadius: 4,
      height: '45px',
      minHeight: 'unset',
    }),
  };
  return (
    <div>
      <Label htmlFor={props.id || props.name} className="form__label">{label}</Label>

      <Select
        options={options}
        name={field.name}
        onChange={(option) => {
          setValue(option.value);
          setSelectedLabel(option);
          if (onChangeExtra) onChangeExtra(option);
        }}
        value={(field.value || selectedLabel) && options.find((x) => x.value === field.value) ? options.find((x) => x.value === field.value) : defaultValue ? defaultValue :   ''}
        styles={selectStyle}
        isSearchable
        onKeyDown={onKeyDown}
        isDisabled={disabled}

      />

      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export default DropDownStatic;