import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authPreduzeceSelector } from '../../store/selectors/PreduzecaSelector';
import GlobalErrorBar from './GlobalErrorBar';

const AppLayout = ({ children }) => {
  const pathName = window.location.pathname;
  const preduzece = useSelector(authPreduzeceSelector());

  useEffect(() => {
    // delete current class
    document.body.className = "";

    if (pathName === '/login') {
      document.body.classList.add('theme-default');
    } else {
      // console.log(preduzece?.podesavanje?.boja);
      // console.log(`${preduzece?.podesavanje?.boja !== undefined ? 'nije undefined' : 'jeste undefined'}`);
      document
        .body
        .classList
        .add(`theme-${preduzece?.podesavanje?.boja !== undefined
                  ? preduzece?.podesavanje?.boja
                  : 'default'}`);
    }
  }, [preduzece])

  return (
    <div className="container">
      <GlobalErrorBar />
      {children}
    </div>
  );
};

export default AppLayout;
