import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as EditIcon } from '../../../assets/icon/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/x-delete.svg';
import { ReactComponent as SelectorIcon } from '../../../assets/icon/selector.svg';

import 'react-toastify/dist/ReactToastify.css';
import {
  getUkupanPopust,
  getUkupnaCijenaSaPdv,
  getUkupnaCijenaBezPdv,
  formatirajCijenu,
  formatirajCijenuBezE,
} from '../../../helpers/racuni';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TIPOVI_POREZA } from '../../../constants/racuni';
import { userSelector } from '../../../store/selectors/UserSelector';
import { useSelector } from 'react-redux';
import { ReactComponent as SelectorSort } from '../../../assets/icon/selector.svg';


const BezgotovinskiStavkeFieldArray = ({
  remove,
  pathName,
  setShow,
  setIsEditingStavka,
  isEditingStavka,
  setChnage,
  change,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [stavka, setStavka] = useState(null);
  const [index, setIndex] = useState(null);
  const [chnageEffect, setChnageEffect] = useState(false);
  const [sortiranjeStavki, setSortiranjeStavki] = useState('naziv_az');

  const editNiz = (index, stavka) => {
    //console.log("edit", index, stavka);
    setChnageEffect(!chnageEffect);
    if (values?.niz_stavke[0]?.edit === true) {
      setIsEditingStavka(true);
      setChnage(!change);
    }

    setStavka(stavka);
    setIndex(index);
  };

  useEffect(() => {
    if (!isEditingStavka && stavka) {
      setFileds(stavka, index);

      // setTimeout(() => {
      //   setFileds(stavka, index)
      // }, 100);
    }
  }, [isEditingStavka, chnageEffect]);

  const setFileds = (stavka, index) => {
    const nova_cijena_bez_pdv = stavka.nova_cijena_bez_pdv
      ? +stavka.nova_cijena_bez_pdv
      : stavka.ulazne_stavke_kalkulacije &&
        stavka.ulazne_stavke_kalkulacije.cijena_bez_pdv
      ? +stavka.ulazne_stavke_kalkulacije.cijena_bez_pdv
      : '';
    const nova_ukupna_cijena = stavka.nova_ukupna_cijena
      ? +stavka.nova_ukupna_cijena
      : stavka.ulazne_stavke_kalkulacije &&
        stavka.ulazne_stavke_kalkulacije.ukupna_cijena
      ? stavka.ulazne_stavke_kalkulacije.ukupna_cijena
      : '';
    const marza = stavka.marza
      ? +stavka.marza
      : stavka.ulazne_stavke_kalkulacije &&
        stavka.ulazne_stavke_kalkulacije.marza
      ? stavka.ulazne_stavke_kalkulacije.marza
      : 0;
    const zavisnitroskovi = stavka.zavisni_troskovi
      ? +stavka.zavisni_troskovi
      : stavka.ulazne_stavke_kalkulacije &&
        stavka.ulazne_stavke_kalkulacije.zavisni_troskovi
      ? stavka.ulazne_stavke_kalkulacije.zavisni_troskovi
      : '';
    const novi_pdv = stavka.novi_pdv
      ? +stavka.novi_pdv
      : nova_cijena_bez_pdv && nova_ukupna_cijena
      ? nova_ukupna_cijena - nova_cijena_bez_pdv
      : '';
    const novi_porez_id = stavka?.novi_porez_id
      ? +stavka?.novi_porez_id
      : stavka.ulazne_stavke_kalkulacije &&
        stavka.ulazne_stavke_kalkulacije.porez_id
      ? stavka.ulazne_stavke_kalkulacije.porez_id
      : 4;
    const nabavna_cijena = stavka.nabavna_cijena
      ? stavka.nabavna_cijena
      : nova_cijena_bez_pdv
      ? nova_cijena_bez_pdv / (marza / 100 + 1) - zavisnitroskovi < 0
        ? 0
        : nova_cijena_bez_pdv
        ? nova_cijena_bez_pdv / (marza / 100 + 1) - zavisnitroskovi
        : 0
      : 0;
    const porez = TIPOVI_POREZA.find((x) => x.id === novi_porez_id);
    const novi_porez = porez?.value ?? 0.21;
    const novi_porez_naziv = porez.label ?? '21%';

    setFieldValue(`niz_stavke[0].naziv`, stavka.naziv);
    setFieldValue(`niz_stavke[0].opis`, stavka.opis);
    setFieldValue(
      `niz_stavke[0].cijena_bez_pdv`,
      stavka.cijena_bez_pdv ? +stavka.cijena_bez_pdv : 0
    );
    setFieldValue(
      `niz_stavke[0].cijena_bez_pdv_popust`,
      stavka.cijena_bez_pdv_popust ? +stavka.cijena_bez_pdv_popust : 0
    );
    setFieldValue(
      `niz_stavke[0].cijena_sa_pdv_popust`,
      stavka.cijena_sa_pdv_popust ? +stavka.cijena_sa_pdv_popust : 0
    );
    setFieldValue(`niz_stavke[0].jedinica_mjere`, stavka.jedinica_mjere);
    setFieldValue(`niz_stavke[0].jedinica_mjere_id`, +stavka.jedinica_mjere_id);
    setFieldValue(
      `niz_stavke[0].kolicina`,
      stavka.kolicina ? +stavka.kolicina : 0
    );
    setFieldValue(`niz_stavke[0].marza`, marza);
    setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, nova_cijena_bez_pdv);
    setFieldValue(
      `niz_stavke[0].nova_jedinica_mjere_id`,
      stavka.nova_jedinica_mjere_id ? stavka.nova_jedinica_mjere_id : null
    );
    setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, nova_ukupna_cijena);
    setFieldValue(`niz_stavke[0].novi_porez_id`, novi_porez_id);
    setFieldValue(`niz_stavke[0].novi_porez`, novi_porez);
    setFieldValue(`niz_stavke[0].novi_porez_naziv`, novi_porez_naziv);

    setFieldValue(
      `niz_stavke[0].popust`,
      stavka.popust && stavka.popust >= 0 ? +stavka.popust : 0
    );
    setFieldValue(`niz_stavke[0].porez`, stavka.porez.stopa);
    setFieldValue(`niz_stavke[0].porez_id`, stavka.porez.id);
    setFieldValue(`niz_stavke[0].porez_naziv`, stavka.porez.naziv);
    setFieldValue(`niz_stavke[0].roba_id`, stavka.roba_id);
    setFieldValue(`niz_stavke[0].tip_popusta`, stavka.tip_popusta);
    setFieldValue(
      `niz_stavke[0].ukupna_cijena`,
      stavka.ukupna_cijena ? +stavka.ukupna_cijena : 0
    );
    setFieldValue(
      `niz_stavke[0].ukupan_pdv`,
      stavka.ukupan_pdv ? +stavka.ukupan_pdv : 0
    );
    setFieldValue(`niz_stavke[0].zavisni_troskovi`, zavisnitroskovi);
    setFieldValue(
      `niz_stavke[0].nabavna_cijena`,
      nabavna_cijena ? Number(nabavna_cijena).toFixed(6) : 0
    );
if(stavka.iznos_povrata_bez_pdv){
  setFieldValue(
    `niz_stavke[0].iznos_povrata_bez_pdv`,
   stavka.iznos_povrata_bez_pdv
  );
}

if(stavka.iznos_povrata_sa_pdv){
  setFieldValue(
    `niz_stavke[0].iznos_povrata_sa_pdv`,
   stavka.iznos_povrata_sa_pdv
  );
}

    setFieldValue(`niz_stavke[0].novi_pdv`, novi_pdv);
    setFieldValue(`niz_stavke[0].edit`, true);

    // setShow(false);
    remove(index);
  };

  function handleOnDragEnd(result) {
    setSortiranjeStavki(null);
    if (!result.destination) return;

    const items = Array.from(values.stavke);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFieldValue('stavke', items);
  }

  const sortirajStavke = (values) => {
    if (sortiranjeStavki === 'naziv_az') {
      return values.sort((a, b) => a.naziv.localeCompare(b.naziv));
    }

    if (sortiranjeStavki === 'naziv_za') {
      return values.sort((a, b) => b.naziv.localeCompare(a.naziv));
    }

    return values;
  };

  const handleChangeSortiranje = () => {
    setSortiranjeStavki(sortiranjeStavki === "naziv_az" ? "naziv_za" : "naziv_az");
  };

  return (
    <>
      <h2 className="heading-secondary">Stavke</h2>
      <div className="main-content__box mt-3">
        <div className="content">
          <div className="table-overflow-wrapper">
            <table className="table">
              <thead>
                <tr className="table-bg">
                  {pathName.includes('/ulazni-racuni') ? (
                    <>
                      <th className="cd">
                        <p className="heading-quaternary txt-left" />
                      </th>
                      <th className="cd">
                      <button type='button' onClick={handleChangeSortiranje}>        

                        <p className="heading-quaternary txt-left d-flex">Naziv  
                            <SelectorSort stroke="#6B7280"  />
                        </p>
                        </button>

                      </th>
                      <th className="cd">
                        <p className="heading-quaternary nowrap">Jed. c.</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Količina</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Popust sa PDV</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Ukupan PDV</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Ukupno</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Nova jed.</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Marža</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Zavisni trošak</p>
                      </th>
                    </>
                  ) : (
                    <>
                      <th className="cd">
                        <p className="heading-quaternary txt-left" />
                      </th>
                      <th className="cd w-100">
                      <button type='button' onClick={handleChangeSortiranje}> 
                      <p className="heading-quaternary txt-left d-flex">Naziv  
                             
                          <SelectorSort stroke="#6B7280"  />
                      </p>  
                      </button>
                   
                       </th>
                      <th className="cd">
                        <p className="heading-quaternary nowrap">
                          Jed. c. bez PDV
                        </p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary nowrap">
                          Jed. c. sa PDV
                        </p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Količina</p>
                      </th>
                      {values.tip_racuna === 'knjizno' && (
                        <>
                          <th className="cd">
                            <p className="heading-quaternary">
                              Iznos povrata bez PDV
                            </p>
                          </th>
                          <th className="cd">
                            <p className="heading-quaternary">
                              Iznos povrata sa PDV
                            </p>
                          </th>
                        </>
                      )}
                      {values.tip_racuna !== 'knjizno' && (
                        <th className="cd">
                          <p className="heading-quaternary">Popust sa PDV</p>
                        </th>
                      )}
                      <th className="cd">
                        <p className="heading-quaternary">Ukupno bez PDV</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Ukupan PDV</p>
                      </th>
                      <th className="cd">
                        <p className="heading-quaternary">Ukupno sa PDV</p>
                      </th>
                    </>
                  )}
                  <th className="cd">
                    <p className="heading-quaternary" />
                  </th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="stavke">
                  {(provided) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      {sortirajStavke(values.stavke).map((stavka, index) => {
                        const nova_cijena_bez_pdv = stavka.nova_cijena_bez_pdv
                          ? +stavka.nova_cijena_bez_pdv
                          : stavka.ulazne_stavke_kalkulacije &&
                            stavka.ulazne_stavke_kalkulacije.cijena_bez_pdv
                          ? stavka.ulazne_stavke_kalkulacije.cijena_bez_pdv
                          : '';
                        const nova_ukupna_cijena = stavka.nova_ukupna_cijena
                          ? +stavka.nova_ukupna_cijena
                          : stavka.ulazne_stavke_kalkulacije &&
                            stavka.ulazne_stavke_kalkulacije.ukupna_cijena
                          ? stavka.ulazne_stavke_kalkulacije.ukupna_cijena
                          : '';
                        const marza = stavka.marza
                          ? +stavka.marza
                          : stavka.ulazne_stavke_kalkulacije &&
                            stavka.ulazne_stavke_kalkulacije.marza
                          ? stavka.ulazne_stavke_kalkulacije.marza
                          : '';
                        const zavisnitroskovi = stavka.zavisni_troskovi
                          ? +stavka.zavisni_troskovi
                          : stavka.ulazne_stavke_kalkulacije &&
                            stavka.ulazne_stavke_kalkulacije.zavisni_troskovi
                          ? stavka.ulazne_stavke_kalkulacije.zavisni_troskovi
                          : '';

                        return (
                          <Draggable
                            key={String(stavka?.id)}
                            draggableId={String(stavka?.id)}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              return (
                                <tr
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={
                                    snapshot.isDragging ? 'table-dragging' : ''
                                  }
                                  ref={provided.innerRef}
                                >
                                  {pathName.includes('/ulazni-racuni') ? (
                                    <>
                                      <td>
                                        <p>
                                          <span title="Pomjerite ovu stavku za preraspored unutar liste">
                                            <SelectorIcon />
                                          </span>
                                        </p>
                                      </td>
                                      <td className="w-100">
                                        <p className="cd fw-500">
                                          {stavka.naziv}
                                        </p>
                                        <p className="mt-2 cl">
                                          {stavka?.opis ? stavka?.opis : ''}
                                        </p>
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(
                                            stavka.roba
                                              ? stavka.roba.cijene_roba[0]
                                                  .cijena_bez_pdv
                                              : stavka.cijena_bez_pdv
                                          )
                                        )}
                                        <br />
                                        {formatirajCijenu(
                                          parseFloat(
                                            stavka.roba
                                              ? stavka.roba.cijene_roba[0]
                                                  .ukupna_cijena
                                              : stavka.ukupna_cijena
                                          )
                                        )}
                                      </td>
                                      <td>
                                        {`${parseFloat(stavka?.kolicina)} ${
                                          stavka?.jedinica_mjere['naziv']
                                        }`}
                                      </td>
                                      <td>
                                        {stavka.popust
                                          ? '-' + getUkupanPopust(stavka) + '€'
                                          : '/'}
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(
                                            getUkupnaCijenaSaPdv(stavka) -
                                              getUkupnaCijenaBezPdv(stavka)
                                          )
                                        )}
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(
                                            getUkupnaCijenaBezPdv(stavka)
                                          )
                                        )}
                                        <br />
                                        {formatirajCijenu(
                                          parseFloat(
                                            getUkupnaCijenaSaPdv(stavka)
                                          )
                                        )}
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(nova_cijena_bez_pdv)
                                        )}
                                        <br />
                                        {formatirajCijenu(
                                          parseFloat(nova_ukupna_cijena)
                                        )}
                                      </td>
                                      <td>
                                        {formatirajCijenuBezE(
                                          parseFloat(marza)
                                        )}
                                        %
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(zavisnitroskovi)
                                        )}
                                      </td>
                                      {stavka.izmijeni_stavku ? (
                                        <td>Ažurirano</td>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        <p>
                                          <span title="Pomjerite ovu stavku za preraspored unutar liste">
                                            <SelectorIcon />
                                          </span>
                                        </p>
                                      </td>
                                      <td className="w-100">
                                        <p className="cd fw-500">
                                          {stavka.naziv}
                                        </p>
                                        <p className="mt-2 cl">
                                          {stavka?.opis ? stavka?.opis : ''}
                                        </p>
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(
                                            stavka.roba
                                              ? stavka.roba.cijene_roba[0]
                                                  .cijena_bez_pdv
                                              : stavka.cijena_bez_pdv
                                          )
                                        )}
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(
                                            stavka.roba
                                              ? stavka.roba.cijene_roba[0]
                                                  .ukupna_cijena
                                              : stavka.ukupna_cijena
                                          )
                                        )}
                                      </td>
                                      <td>
                                        {`${stavka?.kolicina} ${stavka?.jedinica_mjere['naziv']}`}
                                      </td>
                                      {values.tip_racuna === 'knjizno' && (
                                        <>
                                          <td>
                                            {formatirajCijenu(
                                              stavka.iznos_povrata_bez_pdv
                                            )}
                                          </td>
                                          <td>
                                            {formatirajCijenu(
                                              stavka.iznos_povrata_sa_pdv
                                            )}
                                          </td>
                                        </>
                                      )}
                                      {values.tip_racuna !== 'knjizno' && (
                                        <td>
                                          {stavka.popust
                                            ? '-' +
                                              getUkupanPopust(stavka) +
                                              '€'
                                            : '/'}
                                        </td>
                                      )}
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(
                                            getUkupnaCijenaBezPdv(stavka)
                                          )
                                        )}
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(
                                            getUkupnaCijenaSaPdv(stavka) -
                                              getUkupnaCijenaBezPdv(stavka)
                                          )
                                        )}{' '}
                                        ({stavka?.porez?.naziv})
                                      </td>
                                      <td>
                                        {formatirajCijenu(
                                          parseFloat(
                                            getUkupnaCijenaSaPdv(stavka)
                                          )
                                        )}
                                      </td>
                                    </>
                                  )}
                                  <td>
                                    <p
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <span className="btn btn__close danger">
                                        <DeleteIcon />
                                      </span>
                                    </p>
                                    <p
                                      onClick={() => {
                                        editNiz(index, stavka);
                                      }}
                                    >
                                      <span className="btn btn__close">
                                        <EditIcon />
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
            <hr className="hr-main" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BezgotovinskiStavkeFieldArray;
