import React from 'react';

class PeriodicniIzvjestaj extends React.Component {
    render() {
        const izvjestajDetails = this.props.izvjestaj.izvjestaj;

        const renderEnu = () => {
            if (izvjestajDetails.tip_dokumenta_izvjestaja === 'Periodični analitički pregled (izvještaj) – elektronski žurnal') {
                return (
                    <tr>
                        <td width="325">
                            <p>
                                <strong>ENU KOD:</strong>
                            </p>
                        </td>
                        <td colSpan="6" width="343">
                            <p>
                                <em>{izvjestajDetails.enu_kod}</em>
                            </p>
                        </td>
                    </tr>
                )
            } else {
                return (
                    <tr>
                        <td width="340">
                            {/* <p><strong>ENU prva instalacija </strong></p> */}
                        </td>
                        <td colSpan="6" width="296">
                            {/* <p>(DATUM I VRIJEME)</p> */}
                        </td>
                    </tr>
                )
            }
        }

        const renderKalkulacije = () => {
            if (izvjestajDetails.tip_dokumenta_izvjestaja !== 'Periodični analitički pregled (izvještaj) – elektronski žurnal') {
                return (
                    <>
                        <tr>
                            <td colSpan="7" width="636">
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="7" width="636">
                                <p>
                                    <strong>
                                        UKUPNO &ndash; OSNOVICA/POREZ/PROMET (IZDATIH U
                                        OFFLINE)
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p><strong>UKUPNO OSNOVICA (21%, 7%, 0%) </strong></p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{izvjestajDetails.ukupno.ukupno_osnovica_po_stopama_21_7_0.toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p><strong>UKUPNO POREZ (21%, 7%, 0%)</strong></p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{izvjestajDetails.ukupno.ukupno_porez_po_stopama_21_7_0.toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p><strong>UKUPNO PROMET (21%, 7%, 0%)</strong></p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{izvjestajDetails.ukupno.ukupan_promet.toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="7" width="636">
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p><strong>OSLOBOĐENI PROMET </strong></p>
                            </td>
                            <td colSpan="6" width="296">
                                <p><strong>{izvjestajDetails.oslobodjeni_promet.toFixed(2)}</strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="7" width="636">
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p><strong>UKUPNO PROMET (21%, 7%, 0%, OSLOBOĐENI)</strong></p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>
                                    {izvjestajDetails.ukupan_promet.toFixed(2)}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="7" width="636">
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                    </>
                )
            }
            return null;
        }

        const listRacuni = izvjestajDetails.racuni.map((racun) => {
            if (izvjestajDetails.tip_dokumenta_izvjestaja === 'Periodični analitički pregled (izvještaj) – elektronski žurnal') {
                if (racun.iznos_depozit === undefined) {
                    return (
                        <>
                            <tr>
                                <td width="340">
                                    <p>Broj računa</p>
                                </td>
                                <td colSpan="6" width="296" className="word-break">
                                    <p>{racun.broj_racuna}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>IKOF</p>
                                </td>
                                <td colSpan="6" width="296"  className="word-break">
                                    <p>{racun.ikof}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>JIKR</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{racun.jikr}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Datum izdavanja</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{racun.datum_izdavanja}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Datum za plaćanje</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{racun.datum_za_placanje}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Nacin placanja</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{racun.nacin_placanja}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Popust iznos</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{racun.popust_iznos}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Popust na cijenu bez pdv</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{racun.popust_na_cijenu_bez_pdv}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Popust ukupno</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{parseFloat(racun.popust_ukupno).toFixed(2)}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Redni broj</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{racun.redni_broj}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Ukupan iznos pdv</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{parseFloat(racun.ukupan_iznos_pdv).toFixed(2)}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Ukupna cijena bez pdv</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{parseFloat(racun.ukupna_cijena_bez_pdv).toFixed(2)}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Ukupna cijena bez pdv sa popustom</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{parseFloat(racun.ukupna_cijena_bez_pdv_popust).toFixed(2)}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Ukupna cijena sa pdv</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{parseFloat(racun.ukupna_cijena_sa_pdv).toFixed(2)}</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="340">
                                    <p>Ukupna cijena sa pdv sa popustom</p>
                                </td>
                                <td colSpan="6" width="296">
                                    <p>{parseFloat(racun.ukupna_cijena_sa_pdv_popust).toFixed(2)}</p>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="7" width="636">
                                    <p>&nbsp;</p>
                                </td>
                            </tr>
                        </>
                    );
                } else {
                    if (racun.iznos_depozit !== null) {
                        return (
                            <>
                                <tr>
                                    <td width="340">
                                        <p>Depozit iznos</p>
                                    </td>
                                    <td colSpan="6" width="296">
                                        <p>{parseFloat(racun.iznos_depozit).toFixed(2)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="7" width="636">
                                        <p>&nbsp;</p>
                                    </td>
                                </tr>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <tr>
                                    <td width="340">
                                        <p>Withdrawal iznos</p>
                                    </td>
                                    <td colSpan="6" width="296">
                                        <p>{parseFloat(racun.iznos_withdraw).toFixed(2)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="7" width="636">
                                        <p>&nbsp;</p>
                                    </td>
                                </tr>
                            </>
                        );
                    }
                }
            } else {
                return (
                    <>
                        <tr>
                            <td width="340">
                                <p>IKOF</p>
                            </td>
                            <td colSpan="6" width="296"  className="word-break">
                                <p>{racun.ikof}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Broj računa</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{racun.broj_racuna}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Datum izdavanja</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{racun.datum_izdavanja}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Datum za plaćanje</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{racun.datum_za_placanje}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Nacin placanja</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{racun.nacin_placanja}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Popust iznos</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{racun.popust_iznos}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Popust na cijenu bez pdv</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{racun.popust_na_cijenu_bez_pdv}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Popust ukupno</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{parseFloat(racun.popust_ukupno).toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Redni broj</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{racun.redni_broj}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Ukupan iznos pdv</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{parseFloat(racun.ukupan_iznos_pdv).toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Ukupna cijena bez pdv</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{parseFloat(racun.ukupna_cijena_bez_pdv).toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Ukupna cijena bez pdv sa popustom</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{parseFloat(racun.ukupna_cijena_bez_pdv_popust).toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Ukupna cijena sa pdv</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{parseFloat(racun.ukupna_cijena_sa_pdv).toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="340">
                                <p>Ukupna cijena sa pdv sa popustom</p>
                            </td>
                            <td colSpan="6" width="296">
                                <p>{parseFloat(racun.ukupna_cijena_sa_pdv_popust).toFixed(2)}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="7" width="636">
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                    </>
                )
            }
        }
    );

        const listCodes = izvjestajDetails.racuni.map((racun) =>
            <>
                <tr>
                    <td width="340">
                        <p>IKOF</p>
                    </td>
                    <td colSpan="6" width="296"  className="word-break">
                        <p>{racun.ikof}</p>
                    </td>
                </tr>
                <tr>
                    <td width="340">
                        <p>JIKR</p>
                    </td>
                    <td colSpan="6" width="296">
                        <p>{racun.jikr}</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="7" width="636">
                        <p>&nbsp;</p>
                    </td>
                </tr>
            </>
        );

        return (
            <div className="page-izvjestaji">
                <h2 className="mb-20">{izvjestajDetails.tip_dokumenta_izvjestaja}</h2>
                <table width="636">
                    <colgroup>
                        <col span="1" style={{width: '65%'}}></col>
                        <col span="1" style={{width: '35%'}}></col>
                    </colgroup>
                    <tbody>
                    <tr>
                        <td width="325">
                            <p><strong>Naziv poreskog obveznika </strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p><em>{izvjestajDetails.naziv_poreskog_obveznika}</em></p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p><strong>Sjedi&scaron;te poreskog obveznika</strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p><em>{izvjestajDetails.sjediste_poreskog_obveznika}</em></p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p><strong>Pib: </strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p><em>{izvjestajDetails.pib}</em></p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p><strong>Naziv objekta:</strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p><em>{izvjestajDetails.naziv_objekta}</em></p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p><strong>Adresa objekta: </strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p><em>{izvjestajDetails.adresa_objekta}</em></p>
                        </td>
                    </tr>
                    { renderEnu() }
                    <tr>
                        <td colSpan="7" width="636">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p><strong>Tip dokumenta (izvje&scaron;taja): </strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p><strong>{izvjestajDetails.tip_dokumenta_izvjestaja}</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="667">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p><strong>OD (POČETAK POSLOVNOG RADNOG DANA)</strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p>{izvjestajDetails.od}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p>
                                <strong
                                >DO (ZADAVANJE NAREDBE ZA ISPIS IZVJE&Scaron;TAJA)</strong
                                >
                            </p>
                        </td>
                        <td colSpan="6" width="343">
                            <p>
                                {izvjestajDetails.do}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    { listRacuni }
                    { renderKalkulacije() }
                    <tr>
                        <td width="325">
                            <p><strong>DATUM DOKUMENTA</strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p>{izvjestajDetails.datum_dokumenta}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p><strong>VRIJEME DOKUMENTA</strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p>{izvjestajDetails.vrijeme_dokumenta}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="325">
                            <p><strong>OPERATER (OZNAKA) </strong></p>
                        </td>
                        <td colSpan="6" width="343">
                            <p>
                                {izvjestajDetails.operater}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7" width="636">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    {listCodes}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PeriodicniIzvjestaj;
