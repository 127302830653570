import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { TIPOVI_POREZA } from '../../../constants/racuni';
import { jediniceMjereService } from '../../../services/JediniceMjereService';
import DropDown from '../../shared/forms/DropDown';
import DropDownStatic from '../../shared/forms/DropDownStatic';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import DropDownStaticStavka from '../../shared/forms/DropDownStaticStavka';
toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function PositiveNumberField({ field, form, ...props }) {
  const handleChange = (event) => {
    const { name, value } = event.target;
  
   if(name === 'niz_stavke[0].nabavna_cijena') {
    form.setFieldValue('niz_stavke[0].marza', '');
    form.setFieldValue('niz_stavke[0].nova_cijena_bez_pdv', '');
    form.setFieldValue('niz_stavke[0].nova_ukupna_cijena', '');
    form.setFieldValue('niz_stavke[0].novi_pdv', '');
    form.setFieldValue('niz_stavke[0].zavisni_troskovi', '');
   }


    let sanitizedValue = value;

    if (value && Number(value) < 0) {
      sanitizedValue = '';
    }

    form.setFieldValue(name, sanitizedValue);
  };

  return <input {...field} {...props} onChange={handleChange} />;
}
const BezgotovinskiNoveCijeneStavkiForm = ({
  azurirajStavku,
  setAzurirajStavku,
  authPreduzece,
  odabranaStavka,
  onEnterPress,
  setNabanvanCijena,
  nabanvanCijena,
  clearValue

}) => {
  const { values, setFieldValue } = useFormikContext();
  const [lastClickedInput, setLastClickedInput] = useState('');
  const [promjenaNabavneCijene, setPromjenaNabavneCijene] = useState(false)

  const handleCheckboxChange = () => {
     setAzurirajStavku(!azurirajStavku, promjenaNabavneCijene);
  };

  useEffect(() => {
    setFieldValue('niz_stavke[0].pocetna_nabavna', values?.niz_stavke[0]?.nabavna_cijena ?? 0)
  }, [values?.niz_stavke[0]?.naziv])

  useEffect(() => {
     setDefaultPdv()
    
  }, [clearValue]);

  const recalculateAfterZavisniTrosak = (value) => {
    setAzurirajStavku(false);
  
    const parsedValue = value && Number(value) > 0 ? Number(value) : '';
    setFieldValue(`niz_stavke[0].zavisni_troskovi`, parsedValue);
  
    const marza = Number(values?.niz_stavke[0]?.marza) || 0;
    const nabavnaCijena = Number(values?.niz_stavke[0]?.nabavna_cijena);
    const cijenaBezPdv = Number(values?.niz_stavke[0]?.cijena_bez_pdv);
    const currentNabavnaCijena = !isNaN(nabavnaCijena) ? nabavnaCijena : cijenaBezPdv;
  
    const zavisniTroskovi = parsedValue ? Number(parsedValue) : 0;
    const novaCijenaBezPdv = (currentNabavnaCijena + zavisniTroskovi) * (marza / 100 + 1);
    const noviPorez = Number(values?.niz_stavke[0]?.novi_porez) || 0;
    const novaUkupnaCijena = novaCijenaBezPdv * (1 + noviPorez);
    const noviPdv = novaCijenaBezPdv * noviPorez;
  
    if (!values.niz_stavke[0].novi_porez) {
      setDefaultPdv();
    }
  
    setFieldValue(`niz_stavke[0].nabavna_cijena`, currentNabavnaCijena);
    setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, novaCijenaBezPdv);
    setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, novaUkupnaCijena);
    setFieldValue(`niz_stavke[0].novi_pdv`, noviPdv);
  
    setLastClickedInput('bez_pdv');
  };
  
  

  const setManualValues = (values, event, type) => {
    setAzurirajStavku(false); // Reset azuriraj stavku
    if (!values?.novi_porez) {
      setDefaultPdv();
    }

    if (type === 'bez_pdv') {
      setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, +event.target.value);
      setFieldValue(
        `niz_stavke[0].nova_ukupna_cijena`,
        +event.target.value * (1 + +values.novi_porez)
      );
      setFieldValue(
        `niz_stavke[0].novi_pdv`,
        +event.target.value * +values.novi_porez
      );

      setFieldValue(
        `niz_stavke[0].marza`,
        ((+event.target.value - +values.nabavna_cijena) /
          +values.nabavna_cijena) *
          100
      );
    }

    if (type === 'sa_pdv') {
      setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, +event.target.value);
      setFieldValue(
        `niz_stavke[0].nova_cijena_bez_pdv`,
        +event.target.value / (1 + +values.novi_porez)
      );
      setFieldValue(
        `niz_stavke[0].novi_pdv`,
        (+event.target.value / (1 + +values.novi_porez)) * +values.novi_porez
      );

      setFieldValue(
        `niz_stavke[0].marza`,
        calculateMargin(values, event) 
      );
    }
  };


  function calculateMargin(valuesN, event) {
    if (event.target.value && valuesN.novi_porez && valuesN.nabavna_cijena) {
      const totalCijenaSaPDV = +event.target.value;
      const noviPorez = +valuesN.novi_porez;
      const nabavnaCijena = +valuesN.nabavna_cijena || +valuesN.cijena_bez_pdv;
  
      if (!isNaN(totalCijenaSaPDV) && !isNaN(noviPorez) && !isNaN(nabavnaCijena)) {
        let novaCijenaBezPdv = totalCijenaSaPDV / (1 + noviPorez);  
        if (nabavnaCijena > 0) {
          let marza = ((novaCijenaBezPdv - nabavnaCijena) / nabavnaCijena) * 100;
          return marza;
        } else {
          console.log("Error: Nabavna cijena must be greater than zero.");
        }
      } else {
        console.log("Error: One or more inputs are not valid numbers.");
      }
    } else {
      console.log("Error: Missing input values.");
    }
    return null;
  }
  

  const setManualPdv = (values, option) => {
    setFieldValue(`niz_stavke[0].novi_porez_id`, option.id);
    setFieldValue(`niz_stavke[0].novi_porez`, option.value);
    setFieldValue(`niz_stavke[0].novi_porez_naziv`, option.label);

    if (lastClickedInput === 'bez_pdv') {
      if (values?.nova_cijena_bez_pdv) {
        setFieldValue(
          `niz_stavke[0].nova_ukupna_cijena`,
          values.nova_cijena_bez_pdv * (1 + +option.value)
        );
      }
    }

    if (lastClickedInput === 'sa_pdv') {
      if (values?.nova_ukupna_cijena) {
        setFieldValue(
          `niz_stavke[0].nova_cijena_bez_pdv`,
          values.nova_ukupna_cijena / (1 + +option.value)
        );
      }
    }

    if(values.nova_cijena_bez_pdv ) {
      const pdv =  +values.nova_cijena_bez_pdv
      * +option.value
      setFieldValue(
        `niz_stavke[0].novi_pdv`,
       pdv
      );
      setFieldValue(`niz_stavke[0].nova_ukupna_cijena`,+values.nova_cijena_bez_pdv + pdv )
    }
  };

  function izracunajMarzu(value) {
    setAzurirajStavku(false); // Reset azuriraj stavku

    const nabavnaCijena = values.niz_stavke[0].nabavna_cijena !== undefined && values.niz_stavke[0].nabavna_cijena !== null
    ? values.niz_stavke[0].nabavna_cijena
    : values.niz_stavke[0].cijena_bez_pdv;

    const totalNabavnaCijena = +nabavnaCijena + (+values.niz_stavke[0].zavisni_troskovi || 0);
    const novaCijenaBezPdv = totalNabavnaCijena * (+value / 100 + 1);
    setFieldValue(`niz_stavke[0].marza`, value);
    setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, novaCijenaBezPdv);

    if (!values.niz_stavke[0].novi_porez) {
      setDefaultPdv();
    }

    setFieldValue(
      `niz_stavke[0].nova_ukupna_cijena`,
      novaCijenaBezPdv * (1 + +values.niz_stavke[0].novi_porez)
    );
    setFieldValue(
      `niz_stavke[0].novi_pdv`,
      novaCijenaBezPdv * +values.niz_stavke[0].novi_porez
    );
    setLastClickedInput('bez_pdv');
  }

  function setDefaultPdv() {
    // console.log('authPreduzece', authPreduzece);
    // if (authPreduzece.pdv_obveznik) {
      setFieldValue(`niz_stavke[0].novi_porez`, 0.21);
      setFieldValue(`niz_stavke[0].novi_porez_id`, 4);
      setFieldValue(`niz_stavke[0].novi_porez_naziv`, '21%');
    // } else {
      // By defualt 21% pdv
    //   setFieldValue(`niz_stavke[0].novi_porez`, 0.0);
    //   setFieldValue(`niz_stavke[0].novi_porez_id`, 1);
    //   setFieldValue(`niz_stavke[0].novi_porez_naziv`, 'Oslobodjen PDV');
    // }
  }

  const onEnterCheckEvent = (event) => {
    if (event.key === 'Enter') {
      let blnCheck = true;
      let msg = '';
      if (values && values.niz_stavke[0]) {
        if (values.niz_stavke[0].nabavna_cijena > 0) {
        } else {
          blnCheck = false;
          msg += 'Nabavna cijena, ';
        }
        if (
          values.niz_stavke[0].zavisni_troskovi !== '' &&
          values.niz_stavke[0].zavisni_troskovi >= 0
        ) {
        } else {
          blnCheck = false;
          msg += 'Zavisni trošak, ';
        }
        // if (
        //   values.niz_stavke[0].marza>0
        // ) {
        // } else {
        //   blnCheck = false;
        //   msg += 'Marža, ';
        // }
        if (values.niz_stavke[0].nova_cijena_bez_pdv > 0) {
        } else {
          blnCheck = false;
          msg += 'Cijena bez pdv, ';
        }
      } else {
        blnCheck = false;
        msg += 'stavke';
      }

      if (blnCheck) {
        onEnterPress(event);
      } else {
        toast.error('Molimo vas da provjerite polja:' + msg, toastSettings);
      }
    }
  };
  const blockInvalidChar = (e) =>
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const handleKeyDownWithValidation = (event) => {
    onEnterCheckEvent(event);
    blockInvalidChar(event);
  };
  
  const handleNabavnaNovaCijena = (value) => {
    setAzurirajStavku(false);
  
    let novaCijenaBezPdv = Number(value);
  
    if (value === "" && Number(values?.niz_stavke[0]?.zavisni_troskovi)) {
      novaCijenaBezPdv = Number(values?.niz_stavke[0]?.cijena_bez_pdv) || 0;
      setFieldValue(`niz_stavke[0].nabavna_cijena`, "");
    } else {
      setFieldValue(`niz_stavke[0].nabavna_cijena`, novaCijenaBezPdv);
    }
  
    const zavisniTroskovi = Number(values?.niz_stavke[0]?.zavisni_troskovi) || 0;
    let novaCijenaBezPdvUpdated = novaCijenaBezPdv + zavisniTroskovi;
  
    const marza = Number(values?.niz_stavke[0]?.marza) || 0;
    novaCijenaBezPdv = novaCijenaBezPdvUpdated * (1 + marza / 100);
    novaCijenaBezPdvUpdated = novaCijenaBezPdv;
  
    setFieldValue(`niz_stavke[0].nova_cijena_bez_pdv`, novaCijenaBezPdvUpdated);
  
    const noviPorez = Number(values?.niz_stavke[0]?.novi_porez) || 0;
    setFieldValue(`niz_stavke[0].nova_ukupna_cijena`, novaCijenaBezPdvUpdated * (1 + noviPorez));
    setFieldValue(`niz_stavke[0].novi_pdv`, novaCijenaBezPdvUpdated * noviPorez);
  
    setLastClickedInput('bez_pdv');
  };
  
  
  
  return (
    <>
      <div className="main-content__box--body mb-20 mt-4">
        <div className="container">
          <div className="section-box">
            <div className="section-box__left">
              <div className="section-box__left--top">
                <div className="form__group">
                  <label htmlFor="" className="form__label bm-show">
                   Nova nabavna cijena bez pdv
                  </label>
                  <Field
                  type="number"
                  min={0}
                  name={`niz_stavke[0].nabavna_cijena`}
                  className="form__input"
                  placeholder="Nova nabavna cijena bez pdv"
                  onKeyDown={handleKeyDownWithValidation}
                  value={nabanvanCijena}
                  onChange={(event) => handleNabavnaNovaCijena(event.target.value)}
                />
                
                </div>
                <div className="form__group">
                  <label htmlFor="" className="form__label bm-show">
                    Nova ukupna cijena
                  </label>
                  {/* TODO: izgaseno edit Cijena dok se ne rijesi slanje izmijenjene cijene */}
                  <Field
                    name={`niz_stavke[0].nova_ukupna_cijena`}
                    type="number"
                    onKeyDown={handleKeyDownWithValidation}
                    value={values.niz_stavke[0]?.nova_ukupna_cijena ?? ''}
                    className="form__input"
                    placeholder="Nova ukupna cijena"
                    onChange={(event) => {
                      setLastClickedInput('sa_pdv');
                      setManualValues(values?.niz_stavke[0], event, 'sa_pdv')
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="section-box__right">
              <div className="section-box__right--top-wrap">
                <div className="el">
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      Zavisni trošak
                    </label>
                    <Field
                      type="number"
                      name={`niz_stavke[0].zavisni_troskovi`}
                      className="form__input"
                      onKeyDown={handleKeyDownWithValidation}
                      min={0}
                      // component={PositiveNumberField}
                      value={values?.niz_stavke[0]?.zavisni_troskovi ?? ''}
                      placeholder="Zavisni trošak"
                      onChange={(value) => recalculateAfterZavisniTrosak(value.target.value)}
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      Stopa PDV-a
                    </label>
                    <label
                      htmlFor="stavke.0.jedinica_mjere_id"
                      className="form__label"
                    />
                   <DropDownStaticStavka
                      name={`niz_stavke[0].novi_porez`}
                      options={TIPOVI_POREZA}
                      onChangeExtra={(option) => {
                        setManualPdv(values.niz_stavke[0], option);
                      }}
                      defaultValue={values?.niz_stavke[0]?.novi_porez_id ?  TIPOVI_POREZA.find((x) => x.id === values?.niz_stavke[0]?.novi_porez_id) : TIPOVI_POREZA[3]}
                    />
                  </div>
                </div>
                <div className="el">
                  <div className="form__group mb-4">
                    <label htmlFor="" className="form__label bm-show">
                      Marža
                    </label>
                    <Field
                      type="number"
                      name={`niz_stavke[0].marza`}
                      className="form__input"
                      value={values?.niz_stavke[0]?.marza ?? ''}
                      onKeyDown={handleKeyDownWithValidation}
                      placeholder="Marža(%)"
                      onChange={(event) => izracunajMarzu(event.target.value)}
                    />
                  </div>
                </div>
                <div className="el">
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      Nova cijena bez pdv
                    </label>
                    <Field
                      type="number"
                      name={`niz_stavke[0].nova_cijena_bez_pdv`}
                      className="form__input"
                      value={values?.niz_stavke[0]?.nova_cijena_bez_pdv ?? ''}
                      onKeyDown={handleKeyDownWithValidation}
                      placeholder="Nova cijena bez PDV"
                      onChange={(event) => {
                        setLastClickedInput('bez_pdv');
                        setManualValues(values.niz_stavke[0], event, 'bez_pdv');
                      }}
                    />
                  </div>
                </div>
                <div className="el">
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      PDV
                    </label>
                    <Field
                      name={`niz_stavke[0].novi_pdv`}
                      type="number"
                      value={values.niz_stavke[0]?.novi_pdv ?? ''}
                      className="form__input"
                      component={PositiveNumberField}
                      onKeyDown={onEnterCheckEvent}
                      placeholder="PDV"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
         {!odabranaStavka ?   <div>
              <input
              type="checkbox"
        id="azuriraj"
        name="azuriraj"
        checked={azurirajStavku}  // Control component with state
        onChange={handleCheckboxChange}

              />
              <label htmlFor="azuriraj" className="ml-2">
                {!odabranaStavka
                  ? 'Sačuvaj stavku  u listu stavki'
                  : 'Postavi novu cijenu stavke u listi stavki'}
              </label>
            </div> : <div>
          {nabanvanCijena  &&  <>
          <input
              type="checkbox"
        id="azuriraj"
        name="azuriraj"
        checked={azurirajStavku}  // Control component with state
        onChange={handleCheckboxChange}

              />
              <label htmlFor="azuriraj" className="ml-2">
                Postavi novu cijenu stavke u listi stavki
              </label>
          </>}
            </div> }
          </div>
        </div>
      </div>
    </>
  );
};

export default BezgotovinskiNoveCijeneStavkiForm;
