import React, { useState } from 'react';
import { ReactComponent as Success } from '../../assets/icon/success.svg';
import { ReactComponent as IconLg } from '../../assets/icon/icon-lg.svg';
import { ReactComponent as Izmjeni } from '../../assets/icon/izmjeni.svg';
import { ReactComponent as Delete } from '../../assets/icon/stepper-minus.svg';
import { ReactComponent as Clone } from '../../assets/icon/hero-predracuni.svg';

import { ReactComponent as A4 } from '../../assets/icon/hero-racuni.svg';
import { useDispatch, useSelector } from 'react-redux';
import { racunSelector } from '../../store/selectors/RacuniSelector';

import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/me';
import { racuniService } from '../../services/RacuniService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
import Modal from "../shared/forms/Modal";
import { renderStatus } from '../../helpers/bezgotovinskiForm';
import { formatirajCijenu } from '../../helpers/racuni';
import { getRacuni } from '../../store/actions/RacuniActions';
import KlonirajModal from '../shared/forms/KlonirajModal';
import { authPreduzeceSelector } from '../../store/selectors/PreduzecaSelector';
import ModalPredracuni from '../shared/forms/ModalPredracuni';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const RacuniTableRow = ({ item, racuni, searchParams }) => {
  const history = useHistory();
  const [_item, setItem] = useState(item);
  const dispatch = useDispatch();
  const authPreduzece = useSelector(authPreduzeceSelector());


  let fizickaLicaPartneri;
  let preduzecaPartneri;
  if (racuni.partneri) {
    fizickaLicaPartneri = racuni.partneri
      .map(
        (tmp) =>
          tmp.fizicko_lice && {
            ime: tmp?.fizicko_lice?.ime + ' ' + tmp?.fizicko_lice?.prezime,
            id: tmp?.id,
          }
      )
      .filter((tmp) => tmp != null);
    preduzecaPartneri = racuni?.partneri
      ?.map(
        (tmp) =>
          !tmp.fizicko_lice && {
            ime: tmp?.preduzece_partner?.kratki_naziv,
            id: tmp?.id,
          }
      )
      .filter((tmp) => tmp != null);
  }

  let status = renderStatus(_item.status);

  const handleClick = () => {
    // TODO: item.vrsta_racuna.toLowerCase() === 'gotovinski'
    const vrstaRacuna = item.vrsta_racuna.toLowerCase();
    const params = new URLSearchParams(window.location.search) || "";
    if (
      item.vrsta_racuna === 'GOTOVINSKI' ||
      item.vrsta_racuna === 'gotovinski'
      // item.status === 'KREIRAN' &&
      // !item.partner
    ) {
      history.push({
        pathname: `/racuni/show/${item.id}`,
        state: { params: params.toString() }
    });
    } else {
      history.push({
        pathname: `/racuni/bezgotovinski/show/${item.id}`,
        state: { params: params.toString() }
    });
    }
  };

  const storniranjeConfirm = (e) => {
    e.stopPropagation();
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

    const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);

    let date = new Date();

    ReactDOM.render(
        <Modal
            title="Storniraj račun?"
            message=""
            onConfirm={(value) => {handleStorniraj(value); confirm()}}
            onCancel={cancel}
            confirmText="Storniraj"
            cancelText="Obustavi"
            racunIzOvogMjeseca={new Date(item.datum_izdavanja) > new Date(date.getFullYear(), date.getMonth(), 1)}
        />
        , node
    );
  };

  const handleStorniraj = (vrijeme) => {
    if (_item.status !== 'storniran') {
      racuniService
        .stornirajRacun(item.id, {vrijemeStorniranja: vrijeme})
        .then((response) => {
            if (response.data === 'Ne mozete stornirati ovaj racun!') {
            toast.error(
              'Storniranje računa nije moguće: ' + response.data,
              toastSettings
            );
            return;
          }
          toast.success(
            `Storniranje računa broj ${item.redni_broj} je uspjelo!`,
            toastSettings
          );
          dispatch(getRacuni({...searchParams}))
          setItem({..._item, status: "storniran"})
        })
        .catch((err) => {
          let message = err?.response?.data
            ? err.response.data.error
            : err.message;
          toast.error(
            'Storniranje računa nije moguće: ' + message,
            toastSettings
          );
        });
    }
  };



  function jeStarijiOdMjesec(datum) {
    let mjesecIzdavanaja = new Date(datum).getMonth()
    let currentMonth = new Date().getMonth()

    return mjesecIzdavanaja !== currentMonth;
}


const daLiSuAvansniIzProslogMjeseca = (values) =>{
  let prosliMjesec = false
  const date = new Date()
  values?.ikof_reference.map((ikof) =>{
        if(new Date(ikof.datum_izdavanja) > new Date(date.getFullYear(), date.getMonth(), 1)){
            prosliMjesec =  true
          
        }
      
  
  })



  return prosliMjesec;
}



const napraviKorektivni = (e, noviRacun) => {
  e.stopPropagation();
  const node = document.getElementById("custom-prompt");

  const cleanUp = (answer) => {
    ReactDOM.unmountComponentAtNode(node);
  };

  const confirm = () => cleanUp(true);
  const cancel = () => cleanUp(false);

  ReactDOM.render(
      <Modal
          title="Korektivni račun?"
          message=""
          onConfirm={(value) => {fiskalizujRacun(noviRacun.id, value); confirm()}}
          onCancel={cancel}
          confirmText="Kreiraj račun"
          cancelText="Obustavi"
          racunIzOvogMjeseca={daLiSuAvansniIzProslogMjeseca(noviRacun)}
      />
      , node
  );
};



const handleFiskalizuj = (e) => {
  // if(item.nacin_placanja === "ADVANCE" && daLiSuAvansniIzProslogMjeseca(item) === false){
  // napraviKorektivni(e, item)
  // }else{
    if(jeStarijiOdMjesec(item.datum_izdavanja)){
      prikaziModal(e)
    }else{
      prikaziModalZaKorektivni(e, item)
    }
  // }

};


const prikaziModal = (e) => {
  e.stopPropagation();
  const node = document.getElementById("custom-prompt");

const cleanUp = (answer) => {
ReactDOM.unmountComponentAtNode(node);
};

const confirm = () => cleanUp(true);
const cancel = () => cleanUp(false);


ReactDOM.render(
  <ModalPredracuni
      onConfirm={() => {
        confirm()
        prikaziModalZaKorektivni(e, item);
      }}
      onCancel={cancel}
     racun={item}
     izProslogMjeseca={true}
     history={history}
     isPredracun={false}
  />
  , node
);
};

const prikaziModalZaKorektivni = (e, item) => {
  if(item.nacin_placanja === "ADVANCE" && daLiSuAvansniIzProslogMjeseca(item) === false){
    napraviKorektivni(e, item)
    }else{
      fiskalizujRacun(e)
    }
}



  const fiskalizujRacun = (e, value = "trenutni") => {
    racuniService
      .fiskalizujRacun(item.id, value)
      .then((data) => {
        dispatch(getRacuni())
        toast.success(
          `Fiskalizacija računa je uspjela!`,
          toastSettings
        );
      })
      .catch((err) => {
        let message = err?.response?.data?.error
          ? err.response.data.error
          : err.message;
        toast.error(
          'Fiskalizacija računa nije moguća: ' + message,
          toastSettings
        );
      });
  };

  const handleIzmjeni = (e) => {
    e.stopPropagation();
    const params = new URLSearchParams(window.location.search).toString();
    history.push({
      pathname: `/racuni/bezgotovinski/edit/${item.id}`,
      state: { params: params }
  });
  };
  const handlePogledajA4 = (e) => {
    e.stopPropagation();
    const currentPage = new URLSearchParams(window.location.search).get('page') || 1;
    history.push({
      pathname: `/racuni/bezgotovinski/show/${item.id}`,
      state: { page: currentPage }
  });
  };

  const vrstaRacuna = (racun) => {
    let value;
    if (racun === 'gotovinski' || racun === 'GOTOVINSKI') {
      // value = racun.substring(0, 3) + '.';
      value = 'g';
    } else if (racun === 'bezgotovinski' || racun === 'BEZGOTOVINSKI') {
      // value = racun.substring(0, 6) + '.';
      value = 'b';
    } else {
      value = racun;
    }
    return value;
  };

  const renderKupac = () => {
    if (preduzecaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime) {
      return (
          <td className="cd fw-500">
            {preduzecaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime}
          </td>
      );
    }

    if (fizickaLicaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime) {
      return (
          <td className="cd fw-500">
            {fizickaLicaPartneri?.find((fl) => fl.id === _item?.partner_id)?.ime}
          </td>
      )
    }
  }

  const renderStornirajDugme = (item) => {
    if (! item?.qr_url || item?.status === 'storniran' || item?.status === 'korektivni') {
      return null;
    }

    if (item?.tip_racuna === 'avans' && item?.ikof_reference.length) {
      return null;
    }

    return (
        <a
            onClick={storniranjeConfirm}
            className={`${_item?.qr_url ? 'disabled' : ''}`}
        >
          <Delete className="icon icon__dark md" />
          Storniraj
        </a>
    )
  }



  const klonirajRacun = (e) =>{
    e.stopPropagation();
    if (authPreduzece.najjaci_paket === 1) {
      showModalforStart()
      return;
    }
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

    const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);


    ReactDOM.render(
        <KlonirajModal
            onConfirm={(value) => {handleKloniraj(value); confirm()}}
            onCancel={cancel}
        />
        , node
    );
  }


  const showModalforStart = () =>{
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

        const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);


    ReactDOM.render(

        <Modal
            title="Start Paket"
            message="            Ova funkcionalnost je dostupna samo korisnicima Start paketa"
            onConfirm={confirm}
            onCancel={cancel}
            confirmText="Zatvori"
        />
        , node
    
  )

  }

  const handleKloniraj = (value) =>{
    racuniService
    .klonirajRacun(item.id, value === 'fiskalizuj' ? {fiskalizuj: 1} : {})
    .then((response) => {
      toast.success(
        `Kloniranje računa je uspjelo!`,
        toastSettings
      );
      dispatch(getRacuni())

    })
    .catch((err) => {
      let message = err?.response?.data?.error
        ? err.response.data.error
        : err.message;
      toast.error(
        'Kloniranje računa nije moguće: ' + message,
        toastSettings
      );
    });
  }

  const renderKlonirajDugme = (item) => {
    if(item.tip_racuna === "knjizno" || item.nacin_placanja === "ADVANCE"){
      return null;
    }
    return (
      <a
          onClick={klonirajRacun}
      >

      <Clone className="icon icon__dark md" />
        Kloniraj   <span
                      className="tag tag__neutral ml-3 text-secondary"
                      title="Opcija dostupna korisnicima Start paketa"
                    >
                      Start
                    </span>
      </a>
  )
  }

  return (
    <tr onClick={handleClick}>
      <td className="cl">
        <div className="inner-td-wrapper lowercase">
          {_item?.qr_url && <Success />}
          &nbsp;
          {
            <span title={_item.vrsta_racuna} className="tag tag__neutral">
              {vrstaRacuna(_item?.vrsta_racuna)}
            </span>
          }
        </div>
      </td>
      <td className="cl">{ ! _item?.redni_broj ?
          (
              <span title="Privremeni" className="tag tag__neutral">
                p
              </span>
          ) : _item?.redni_broj }
      </td>

      {/* {renderKupac()}

      {! preduzecaPartneri && ! fizickaLicaPartneri && ( */}
          <td className="cd fw-500">
            {_item?.partner?.fizicko_lice_id
                ? _item?.partner?.fizicko_lice?.ime +
                ' ' +
                _item?.partner?.fizicko_lice?.prezime
                : _item?.partner?.preduzece_partner?.kratki_naziv}
          </td>
      {/* )} */}
      <td className="cl dshow-cell">
        {formatirajCijenu(_item?.ukupna_cijena_bez_pdv_popust)}
      </td>
      <td className="cl dshow-cell">
        {formatirajCijenu(_item?.ukupan_iznos_pdv)}
      </td>
      <td className="cd fw-500">
        {formatirajCijenu(_item?.ukupna_cijena_sa_pdv_popust)}
      </td>
      <td className="cd">
        {/* <span className={bojaStatus[item.status].klasa}>
          {bojaStatus[item.status].naziv}
        </span> */}
        {<span className={status.color}>{status.value}</span>}
      </td>

      <td className="cl">
        {/* {new Date(item.created_at).toLocaleDateString('en-GB')} */}
        <Moment locale="me" format="DD. MMM YYYY.">
          {_item?.datum_izdavanja}
        </Moment>
      </td>
      <td className="mob-absolute-topright">
       {_item.status !== 'korektivni' && <div className="df jc-end ai-c">
          <button className="btn btn__light-dd btn__xs">
            <IconLg />
            <div className="drop-down">
              {_item.vrsta_racuna == 'gotovinski' && (
                <a onClick={handlePogledajA4}>
                  <A4 className="icon icon__dark md" />
                  Prikaži na A4
                </a>
              )}

              {! _item?.qr_url && _item?.vrsta_racuna === 'bezgotovinski' && ! _item?.ikof && (
                <a
                  onClick={handleIzmjeni}
                  className={`${_item?.qr_url ? 'disabled' : ''}`}
                >
                  <Izmjeni />
                  Izmjeni
                </a>
              )}
              {!_item?.qr_url && (
                <a
                  onClick={handleFiskalizuj}
                  className={`${_item?.qr_url ? 'disabled' : ''}`}
                >
                  <Success />
                  Fiskalizuj
                </a>
              )}

              {renderStornirajDugme(_item)}
              {renderKlonirajDugme(_item)}

            </div>
          </button>
        </div>}
      </td>
    </tr>
  );
};

export default RacuniTableRow;
