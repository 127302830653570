import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppLayout from './components/layout/AppLayout';
import AppRouter from './routes/AppRouter';
import { authService } from './services/AuthService';
import { getUser } from './store/actions/UserActions';
import './main.scss';

import i18n from 'i18n-js';
import { getAuthPreduzece } from "./store/actions/PreduzecaActions";
import { preduzeceSelector } from './store/selectors/PreduzecaSelector';
import { getNeprocitaneNovosti } from './store/actions/NovostiActions';
import { ToastContainer } from 'react-toastify';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    authService.getCsrfCookie();
    if (authService.isAuthenticated()) {
      dispatch(getUser());
      dispatch(getAuthPreduzece());
      dispatch(getNeprocitaneNovosti());
    }
  }, [dispatch]);

  const preduzece = useSelector(preduzeceSelector());

  const [language, setLanguage] = useState('sr');
  i18n.locale = language;

  return (
    <AppLayout preduzece={preduzece}>
      <AppRouter />
      <ToastContainer style={{zIndex: 9999999999999}} />
    </AppLayout>
  );
}

export default App;
