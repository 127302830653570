import React from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as DeleteIcon } from '../../../assets/icon/x-delete.svg';

import 'react-toastify/dist/ReactToastify.css';
import {
    formatirajCijenu,
} from "../../../helpers/racuni";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Moment from 'react-moment';

const BezgotovinskiZavisniTroskoviFieldArray = ({ remove }) => {
    const { values, setFieldValue } = useFormikContext();

    const removeNiz = (ind) => {
        values.niz_zavisni_troskovi.splice(ind);
    };

    function makeId(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }

       return result;
    }

    function handleOnDragEnd(result) {
        if (! result.destination) return;

        const items = Array.from(values.zavisni_troskovi);
        const [ reorderedItem ] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFieldValue('zavisni_troskovi', items);
    }
    return (
        <>
            <h2 className="heading-secondary">Zavisni troškovi</h2>
            <div className="main-content__box mt-3">
                <div className="content">
                    <div className="table-overflow-wrapper" >
                        <table className="table">
                            <thead>
                                <tr className='table-bg '>
                                <th className="cd table-margin">
                                        <p className="heading-quaternary txt-left">
                                            Naziv
                                        </p>
                                    </th>
                                <th className="cd">
                                        <p className="heading-quaternary">
                                            Broj dokumenta
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Dobavljač
                                        </p>
                                    </th>
                               
                             
                                   
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Datum izdavanja/Datum za plaćanje
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                           Iznos oslobođen
                                        </p>
                                    </th>

                                    <th className="cd">
                                        <p className="heading-quaternary">
                                           Iznos po stopi 0
                                        </p>
                                    </th>


                                    <th className="cd">
                                        <p className="heading-quaternary">
                                           Iznos po sniženoj stopi
                                        </p>
                                    </th>

                                    <th className="cd">
                                        <p className="heading-quaternary">
                                           Iznos po opštoj stopi
                                        </p>
                                    </th>
                            
                                    <th className="cd">
                                        <p className="heading-quaternary ">
                                         Ukupno bez PDV
                                        </p>
                                    </th>

                                    <th className="cd" style={{width: 'fit-content'}}>
                                        <p className="heading-quaternary" />
                                    </th>
                                </tr>
                            </thead>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="zavisni_troskovi">
                                    {(provided) => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                            {values.zavisni_troskovi.map((stavka, index) => {
                                                return (
                                                    <Draggable key={"zavisni_troskovi" + String(stavka?.id)} draggableId={"zavisni_troskovi" + String(stavka?.id)} index={index}>
                                                         {(provided, snapshot) => (
                                                            <tr {...provided.draggableProps} {...provided.dragHandleProps} className={snapshot.isDragging ? 'table-dragging' : ''}
                                                            ref={provided.innerRef}>
                                                                     <td>
                                                                    <p className="cd fw-500">
                                                                        { stavka.naziv }
                                                                    </p>
                                                                    <p className="mt-2 cl">
                                                                        {stavka?.opis ? stavka?.opis : ''}
                                                                    </p>
                                                                </td>
                                                                 <td>
                                                                    {stavka?.broj_dokumenta}
                                                                </td>
                                                                <td>
                                                               {stavka?.partner_name?.label}
                                                                </td>
                                                           
                                                             
                                                               
                                                                <td>
                                                                   <Moment locale="me" format="DD. MMM YYYY.">
                                                                    {stavka?.datum_izdavanja}
                                                                   </Moment> <br/>
                                                                   <Moment locale="me" format="DD. MMM YYYY.">
                                                                    {stavka?.datum_za_placanje}
                                                                   </Moment>
                                                                </td>
                                                                <td>
                                                                  {formatirajCijenu(stavka.oslobodjena_stopa)}
                                                                </td>

                                                                <td>
                                                                    {formatirajCijenu(stavka.stopa_0)}
                                                                </td>

                                                                <td>
                                                                    {formatirajCijenu(stavka.snizena_stopa)}
                                                                </td>
                                                                <td>
                                                                    {formatirajCijenu(stavka.opsta_stopa)}
                                                                </td>
                                                                <td>
                                                                    { formatirajCijenu(parseFloat(stavka?.ukupna_cijena)) }
                                                                </td>
                                                              
                                                                <td>
                                                                    <p
                                                                        onClick={() => {
                                                                            remove(index);
                                                                            removeNiz(index);
                                                                        }}
                                                                    >
                                                                        <span className="btn btn__close danger">
                                                                            <DeleteIcon />
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </table>
                        <hr className="hr-main" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BezgotovinskiZavisniTroskoviFieldArray;
