import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType)

const BezgotovinskiDokumenta = ({ files, setFiles }) => {
    const acceptedFileTypes = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg'];
    return (
        <>
            <h2 className="heading-secondary">Dokumenta</h2>
            <div className="main-content__box">
                <div className="content p-4">
                    <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={true}
                        name="files"
                        labelIdle='Prevucite fajlove ovdje ili <span class="filepond--label-action">Dodajte ručno</span>'
                        acceptedFileTypes={acceptedFileTypes}
                    />
                </div>
            </div>
        </>
    )
}

export default BezgotovinskiDokumenta;