import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  partneriSelector,
  partnerSelector,
} from '../../store/selectors/PartneriSelector';
import { getPartneri, setPartner } from '../../store/actions/PartneriActions';
import PartneriTable from './PartneriTable';
import PreduzeceDetails from '../Preduzeca/PreduzeceDetails';
import FizickoLiceDetails from '../FizickaLica/FizickoLiceDetails';
import { debounce } from 'lodash';
import { ReactComponent as PlusLightSvg } from '../../assets/icon/plusLight.svg';
import { ReactComponent as PreduzeceSvg } from '../../assets/icon/hero-preduzecaDropdown.svg';
import { ReactComponent as UserSvg } from '../../assets/icon/user.svg';
import { Link, useLocation } from 'react-router-dom';
import { FIZICKA_LICA, PREDUZECA } from '../../constants/routes';

import GridLoader from 'react-spinners/GridLoader';
import { spinnerStyleGrid } from '../../constants/spinner';
import { setPreduzece } from '../../store/actions/PreduzecaActions';
import { setFizickoLice } from '../../store/actions/FizickaLicaActions';
import Select from 'react-select';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const searchDebounced = debounce((callback) => callback(), 200);

let filters = {};

const options = [
  { value: 'sve', label: 'Sve' },
  { value: 'fizicko_lice', label: 'Fizička lica' },
  { value: 'preduzece', label: 'Preduzeća' },
];

const Partneri = () => {
  const dispatch = useDispatch();
  const history = useHistory()

  const partneri = useSelector(partneriSelector());
  const partner = useSelector(partnerSelector());
  const query = useLocation().search;
  const page = new URLSearchParams(query).get('page');
  const paramsObject = Array.from(new URLSearchParams(query).entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
}, {})

  filters = paramsObject;


  const [filterObject, setFilterObject] = useState(paramsObject.filter && options.find(x => x.value === paramsObject.filter) ?  options.find(x => x.value === paramsObject.filter) : { label: 'Sve', value: 'sve' });

  const [filter, setFilter] = useState(paramsObject.filter && options.find(x => x.value === paramsObject.filter) ?  options.find(x => x.value === paramsObject.filter).value : 'sve');
  const [search, setSearch] = useState(paramsObject.search  ?? '');

  const [infoOpen, setInfoOpen] = useState(false);

  const openInfo = (props) => {
    setInfoOpen(props);
  };

  useEffect(() => {
    if (filter === 'sve') delete filters.filter;
    else filters.filter = filter;
    getFiltered();
  }, [filter]);

  useEffect(() => {
    if (search === '') delete filters.search;
    else {
      let tempSearch;
      if (search) {
        tempSearch = search.replace(/[^0-9a-zA-Zžćšđč -]/gi, '');
      }
      filters.search = tempSearch;
    }
    searchDebounced(getFiltered);
  }, [search]);

  useEffect(() => {
    if (partneri.total > 0) dispatch(setPartner(partneri.data[0]));
  }, [partneri, dispatch]);

  const getFiltered = () => {
    const params = new URLSearchParams(filters)

    history.replace({
      pathname: window.location.pathname,
      search: `?${params.toString()}`,
    });
    dispatch(getPartneri({ page, ...filters }));
  };

  return (
    <>
      <div className="title jc-sb">
        <h1 className="heading-primary">Partneri</h1>
        <button className="btn btn__primary btn-dd">
          <PlusLightSvg />
          <p>Novi partner</p>
          <div className="drop-down" id="ddl">
            <Link
              onClick={() => dispatch(setPreduzece({}))}
              to={PREDUZECA.CREATE}
            >
              <PreduzeceSvg />
              <p>Preduzeće</p>
            </Link>
            <Link
              onClick={() => dispatch(setFizickoLice({}))}
              to={FIZICKA_LICA.CREATE}
            >
              <UserSvg />
              <p>Fizičko lice</p>
            </Link>
          </div>
        </button>
      </div>
      <div className="screen-content-info">
        <div className="main-content__box">
          <div className="content">
            <div className="main-content__search-wrapper df mob-fd-column flex-nowrap">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="search df ai-c mob-w-100"
              >
                <button className="search__button"></button>
                <input
                  type="text"
                  className="search__input mr-4"
                  placeholder="Naziv ili PIB Preduzeca ili Fizicko Lice"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </form>
              <Select
                options={options}
                defaultValue={filterObject}
                onChange={(option) => setFilter(option.value)}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: '#F3F4F6',
                    borderRadius: 4,
                    height: '45px',
                    minHeight: 'unset',
                    width: '185px',
                  }),
                }}
              />
            </div>
            {partneri &&
            partneri.data &&
            partneri.data.length === 0 &&
            !partneri.path ? (
              <GridLoader css={spinnerStyleGrid} size={15} />
            ) : partneri && partneri.data && partneri.data.length === 0 ? (
              <div className="msg-center">
                <p>{'Nema partnera u listi'}</p>
              </div>
            ) : (
              <PartneriTable
                partneri={partneri}
                page={page}
                filters={filters}
                openInfo={openInfo}

              />
            )}
          </div>
          {partner?.preduzece_partner && !partner?.fizicko_lice && (
            <PreduzeceDetails
              preduzece={partner?.preduzece_partner}
              infoOpen={infoOpen}
              openInfo={openInfo}
            />
          )}
          {partner?.fizicko_lice && !partner?.preduzece_partner && (
            <FizickoLiceDetails
              fizickoLice={partner?.fizicko_lice}
              infoOpen={infoOpen}
              openInfo={openInfo}
            />
          )}
        </div>
      </div>
      {/* <h1 className="heading-primary">Partneri</h1>
      <div className="main-content__box">
        <div className="content">
          <div className="main-content__search-wrapper df">
            <form className="search df ai-c">
              <button className="search__button"></button>
              <input
                type="text"
                className="search__input"
                placeholder="Naziv ili PIB preduzeca"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </form>
            <select className="btn btn__primary btn__lg ml-xl" value={filter} onChange={(event) => setFilter(event.target.value)}>
              <option value={'sve'}>Sve</option>
              <option value={'fizicko_lice'}>Fizička lica</option>
              <option value={'preduzece'}>Preduzeća</option>
            </select>
          </div>
          <PartneriTable partneri={partneri} />
        </div>
        {partner.preduzece && (
          <PreduzeceDetails preduzece={partner.preduzece} />
        )}
        {partner.fizicko_lice && (
          <FizickoLiceDetails fizickoLice={partner.fizicko_lice} />
        )}
      </div> */}
    </>
  );
};

export default Partneri;
