import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const ModalPredracuni = ({
  onCancel,
  onConfirm,
  racun,
  izProslogMjeseca,
  history,
  isPredracun

}) => {
  const [datum_izdavanja, setDatum_izdavanja] = useState(new Date(racun.datum_izdavanja))

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      onCancel();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const checkDatumIzdavanja = (date) => {
    if (date?.target?.value) {
      const dateParts = date?.target?.value.split('/');
      date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }

    if (date > new Date()) {
      return toast.error(
        'Datum izdavanja ne može biti veći od današnjeg!',
        toastSettings
      );
    }

    let firstDayOfLastMonth = getFirstDayOfLastMonth();

    let lastDayOfLastMonth = new Date();
    lastDayOfLastMonth.setDate(0);
    lastDayOfLastMonth.setHours(23, 59, 59, 0);

    if (date < firstDayOfLastMonth) {
      return toast.error(
        'Datum izdavanja mora biti u tekućem ili prethodnom mjesecu!',
        toastSettings
      );
    }

    
    setDatum_izdavanja(date);
  };

  const getFirstDayOfLastMonth = () => {
    let firstDayOfLastMonth = new Date();
    firstDayOfLastMonth.setDate(0);
    firstDayOfLastMonth.setDate(1);
    firstDayOfLastMonth.setHours(0, 0, 0, 0);

    return firstDayOfLastMonth;
  };
  const handleIzmjeni = (e) => {
    e.stopPropagation();
    const currentPage = new URLSearchParams(window.location.search).get('page') || 1;
    history.push({
      pathname: isPredracun ? `/predracuni/edit/${racun.id}` : `/racuni/bezgotovinski/edit/${racun.id}`,
      state: { page: currentPage }
  });
  onCancel()

};

  return (
    <div className="modal open" onClick={onCancel}>
      <div
        className="modal__content"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="modal__header">
          <span className="modal__close" onClick={onCancel}>
            &times;
          </span>
          <h2 className="heading-secondary m-0">
            {izProslogMjeseca
              ? 'Zadržati datum izdavanja?'
              : 'Fiskalizacija predračuna'}
          </h2>
        </div>
        <div className="modal__body">
          <p>
            {izProslogMjeseca
              ? `Datum izdavanja računa nije u ovom mjesecu. Ukoliko nastavite račun će se fiskalizovati sa datumom izdavanja ${moment(
                  racun.datum_izdavanja
                ).format('DD. MMM YYYY.')}`
              : 'Jeste li sigurni da želite da napravite i fiskalizujete račun od ovog predračuna?'}
          </p>
        </div>

          <div className="modal__footer">
            <button
              type="submit"
              className="btn btn__primary"
              onClick={() => {
                onConfirm(datum_izdavanja);
              }}
            >
              {izProslogMjeseca ? 'Zadrži datum i fiskalizuj' : 'Nastavi'}
            </button>

            {izProslogMjeseca && (
              <button
                type="button"
                className="btn btn__secondary ml-m"
                onClick={handleIzmjeni}
              >
                Izmjeni podatke
              </button>
            )}

            <button
              type="button"
              className="btn btn__link ml-m"
              onClick={onCancel}
            >
              Obustavi
            </button>
          </div>
        
      </div>
    </div>
  );
};

export default ModalPredracuni;
