import * as Yup from 'yup';
import $t from '../lang';

export const PreduzecaSchema = (drzava) =>{
 return Yup.object().shape({
    kratki_naziv: Yup.string()
      .max(191, $t('validacija.unosDugacak'))
      .required($t('preduzecaValidation.kratki_naziv')),
    oblik_preduzeca: Yup.string()
      .max(191, $t('validacija.unosDugacak'))
      .nullable(),
    pib: drzava === 'MNE' ?  Yup.string()
      .min(8, $t('preduzecaValidation.pibKratak'))
      .max(13, $t('preduzecaValidation.pibDugacak'))
      .matches(/^[0-9]+$/, $t('preduzecaValidation.specialCharacters'))
      .required($t('preduzecaValidation.pib')) : Yup.string().required($t('preduzecaValidation.pib')),
    adresa: Yup.string()
      .max(191, $t('validacija.unosDugacak'))
      .required($t('preduzecaValidation.adresa')),
    grad: Yup.string()
      .max(191, $t('validacija.unosDugacak'))
      .required($t('preduzecaValidation.grad')),
    country_code: Yup.string()
      .max(191, $t('validacija.unosDugacak'))
      .required($t('preduzecaValidation.drzava')).nullable(),
    kategorija_id: Yup.number().required($t('preduzecaValidation.kategorija')),
    djelatnost_id: Yup.number().required($t('preduzecaValidation.djelatnost')),
  });
}
