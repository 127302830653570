import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, useFormikContext } from "formik";

import { avansniRacuniSelector } from "../../../store/selectors/RacuniSelector";
import { getAvansniRacuni } from "../../../store/actions/RacuniActions";
import { ReactComponent as DeleteIcon } from '../../../assets/icon/x-delete.svg';
import { ReactComponent as RedirectIcon } from "../../../assets/icon/logout.svg";
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icon/external-link.svg';
import { formatirajCijenu } from "../../../helpers/racuni";
import { toast } from "react-toastify";

toast.configure();

const toastSettings = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const BezgotovinskiAvansniReferenceNaIkof = () => {
    const dispatch = useDispatch();
    const { values, setFieldValue } = useFormikContext();
    const [ ostatakRacuna, setOstatakRacuna ] = useState(0);

    const avansniRacuni = useSelector(avansniRacuniSelector());

    useEffect(() => {
        dispatch(getAvansniRacuni({partner_id: values.partner_id}));
    }, [dispatch, values.partner_id]);

    useEffect(() => {
     if(avansniRacuni && avansniRacuni.length > 0){
        let odabraniRacuni = [];
        values?.referentni_ikofi?.map((ikof) => {
            avansniRacuni.filter((racun) => racun.ikof === ikof)
                .map((item) => (
                    odabraniRacuni.push(parseFloat(item.ukupna_cijena_sa_pdv_popust) - (item.ikof_reference_sum_ukupna_cijena_sa_pdv_popust * -1))
                ))
        });

        let preostalaCijenaAvansnihRacuna = odabraniRacuni.reduce((a, b) =>  a = a + b , 0);
        let ukupnoSaPdv = Object.values(values.stavke).reduce((r, { cijena_sa_pdv_popust, kolicina }) => r + cijena_sa_pdv_popust * kolicina, 0);

        setOstatakRacuna(preostalaCijenaAvansnihRacuna);

        if (preostalaCijenaAvansnihRacuna < ukupnoSaPdv && preostalaCijenaAvansnihRacuna !== 0) {
            toast.info('Ukupan iznos računa je veći od iznosa na izabranim avansnim računima. Potrebno je odabrati dodatni način plaćanja dolje ispod u sekciji Ukupno!',
                toastSettings
            );
            setFieldValue('vise_nacina_placanja', true);
            setFieldValue('dodatni_nacin_placanja', 'ACCOUNT');
        } else {
            setFieldValue('vise_nacina_placanja', false);
            setFieldValue('dodatni_nacin_placanja', null);
        }
     }
    }, [values.referentni_ikofi]);

    if (! avansniRacuni.length) {
        return <div className="main-content__box"><div className="content"><div className="main-content__box--inner-wrapper p-4"><p className="my-4">Nema avansnih računa prema ovom kupcu!</p></div></div></div>;
    }

    let ukupnoSaPdv = Object.values(values.stavke).reduce((r, { cijena_sa_pdv_popust, kolicina }) => r + cijena_sa_pdv_popust * kolicina, 0);

    return (
        <>
            <h2 className="heading-secondary">Avansne uplate / računi</h2>
            <div className="main-content__box">
                <div className="content">
                    <div className="table-overflow-wrapper">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Označite račun
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Redni broj računa
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Ukupna cijena bez pdv
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Ukupan PDV
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Ukupna cijena
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                            Ukupno iskorišteno
                                        </p>
                                    </th>
                                    <th className="cd">
                                        <p className="heading-quaternary">
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(avansniRacuni).map((avansniRacunId) => {
                                    const racun = avansniRacuni[avansniRacunId];

                                    return (
                                        <tr>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="checkbox"
                                                    name="referentni_ikofi"
                                                    value={racun.ikof}
                                                />
                                            </td>
                                            <td>
                                                <a
                                                    href={`/racuni/bezgotovinski/show/${racun.id}`}
                                                    target="_blank"
                                                    className="btn btn__link success"
                                                >
                                                    { racun.redni_broj }/{new Date(racun.datum_izdavanja).getFullYear() }
                                                    <ExternalLinkIcon />
                                                </a>
                                            </td>
                                            <td>{ formatirajCijenu(racun.ukupna_cijena_bez_pdv_popust) }</td>
                                            <td>{ formatirajCijenu(racun.ukupna_cijena_sa_pdv_popust - racun.ukupna_cijena_bez_pdv_popust) }</td>
                                            <td>{ formatirajCijenu(racun.ukupna_cijena_sa_pdv_popust) }</td>
                                            <td>{ formatirajCijenu(racun.ikof_reference_sum_ukupna_cijena_sa_pdv_popust * -1 ?? 0) }</td>
                                            <td>
                                                <a className="" href={`/racuni/bezgotovinski/show/${racun.id}`} target="_blank">
                                                    <span className="btn btn__close danger"><DeleteIcon /></span>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="m-4">
                        Preostali iznos na odabranim avansnim računima: { formatirajCijenu(ostatakRacuna - ukupnoSaPdv) }
                    </div>
                </div>
            </div>
        </>
    );
};

export default BezgotovinskiAvansniReferenceNaIkof;
