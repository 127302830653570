import React from 'react';
import Moment from 'react-moment';
import { formatirajCijenuBez0 } from '../../../helpers/racuni';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icon/external-link.svg';

function BezgotovinskiAvansniNacinPlacanja({ ikofReference, stampa = false }) {
  return (
    <>
      <thead>
        <tr>
          <th className="w-33">
            <p className="heading-quaternary mb-0 nowrap">Redni broj </p>
          </th>
          <th className="w-33">
            <p className="heading-quaternary mb-0 nowrap">Iznos</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {ikofReference.map((avansni, index) => (
          <tr key={index}>
            <td className="w-33">
         {stampa ? <>
          {avansni.redni_broj}/
              <Moment locale="me" format="YYYY">
                {avansni.datum_izdavanja}
              </Moment>
         </> :   <a
                className="btn btn__link"
                href={`/racuni/bezgotovinski/show/${avansni.id}`}
                target="_blank"
              >
                <p className="btn btn__link success">
                  {avansni.redni_broj}/
                  <Moment locale="me" format="YYYY">
                    {avansni.datum_izdavanja}
                  </Moment>
                  <ExternalLinkIcon />
                </p>
              </a>}
            </td>
            <td className="w-33">
              {formatirajCijenuBez0(avansni.ukupna_cijena_sa_pdv_popust)}
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
}

export default BezgotovinskiAvansniNacinPlacanja;
