import React from 'react';
import {
  formatirajCijenu,
  formatirajCijenuBez0,
  formatirajCijenuBez0BezE,
  formatirajCijenuBezE,
} from '../../../helpers/racuni';

const BezgotovinskiTableRow = ({ item, racun }) => {
  const getJedinicniPopust = () => {
    return formatirajCijenuBez0(
      ((Number(item.item.cijena_sa_pdv)-Number(item.item.cijena_sa_pdv_popust)) * item.item.kolicina ))
  }
  return (
    <tr>
      <td className="cd">
        <p className="cd fw-500">{item && item.item && item.item.naziv ? item.item.naziv : ''}</p>
        {item && item.item && item.item.opis
        ? item.item.opis
        : null}
      </td>
      <td className="cd">
        {item && item.item && item.item.kolicina
          ? Number(item.item.kolicina).toString() + ' ' + item.item.jedinica_naziv
          : ''}
      </td>
      <td>
        {item && item.item && item.item.jedinicna_cijena_bez_pdv ?
            formatirajCijenuBez0(Number(item.item.jedinicna_cijena_bez_pdv))
          : ''}
      </td>
      <td>
        {item && item.item && item.item.jedinicna_cijena_bez_pdv
          ?
            formatirajCijenuBez0(Number(item?.item?.cijena_sa_pdv))
          : ''}
      </td>
      <td className="cd">
        {item?.item?.iznos_povrata_sa_pdv < 0 ? (
            formatirajCijenuBez0(item.item.iznos_povrata_sa_pdv)
        ) : (
          item && item.item && (
            item?.item?.popust_procenat && Number(item?.item?.popust_procenat) > 0 ? ( //This one already works
                formatirajCijenuBezE(item?.item?.popust_procenat) + '% (-' +
                formatirajCijenu(
                    (item?.item?.cijena_sa_pdv - item?.item?.cijena_sa_pdv_popust) * item?.item?.kolicina
                ) + ')'
            ) 
        
      
            : item.item.popust_na_jedinicnu_cijenu && Number(item.item.popust_na_jedinicnu_cijenu) > 0 ? ( //This one already works
              '-' + getJedinicniPopust()
            )
          //   : racun && racun.popust_ukupno && Number(racun.popust_ukupno) !== 0 ? (
          //     formatirajCijenuBez0(racun.popust_ukupno) !== '0.00' ? 
          //     '-' + formatirajCijenuBez0(racun.popust_ukupno) 
          //     : ''
          // ) 
          : '/'
        )        
        )}

      </td>
      <td className="cd w-10">
        {item
          ? formatirajCijenu(item?.item?.ukupna_bez_pdv_popust)
          : ''}
      </td>
      <td className="cd w-10">
        {item
          ? formatirajCijenu(item?.item?.ukupna_sa_pdv_popust)
          : ''}
      </td>
    </tr>
  );
};

export default BezgotovinskiTableRow;
