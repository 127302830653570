import React from 'react';
import { ReactComponent as Badge } from '../../assets/icon/badge.svg';
import { ReactComponent as Edit } from '../../assets/icon/edit.svg';
import { ReactComponent as Delete } from '../../assets/icon/delete.svg';
import { ReactComponent as Dots } from '../../assets/icon/dots.svg';
import { Link } from 'react-router-dom';
import { FIZICKA_LICA, PREDUZECA } from '../../constants/routes';
import { useDispatch } from 'react-redux';
import {
  deletePartner,
  getPartneri,
} from '../../store/actions/PartneriActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const PartneriTableRow = ({ item: partner, onItemClick, selectedId }) => {
  const dispatch = useDispatch();
const history = useHistory()
  const handleDelete = (id) => {
    dispatch(deletePartner(id));
    dispatch(getPartneri());
  };


  const handleClick = () => {
    const params = new URLSearchParams(window.location.search) || "";
    window.location.replace(  partner.preduzece_partner
      ? PREDUZECA.EDIT.replace(
          ':id',
          `${partner?.preduzece_partner?.id}`
        )
      : FIZICKA_LICA.EDIT.replace(
          ':id',
          `${partner?.fizicko_lice?.id}`
        ))

      history.push({
        pathname: partner.preduzece_partner
        ? PREDUZECA.EDIT.replace(
            ':id',
            `${partner?.preduzece_partner?.id}`
          )
        : FIZICKA_LICA.EDIT.replace(
            ':id',
            `${partner?.fizicko_lice?.id}`
          ),
        state: { params: params.toString() }
    });

  };

  return (
    <tr
      onClick={() => onItemClick(partner)}
      className={selectedId === partner.id ? 'active' : ''}
    >
      <td>
        <div className="inner-td-wrapper">
          {/* <div
            className="img-round sm"
            style={{ backgroundImage: `url(${partner.preduzece?.logotip})` }}
          ></div> */}
          {/* <img
            // src={partner.preduzece?.logotip} // TODO:mora logotip path logfo.png ili logo
            className="img-round sm"
            alt=""
            // alt={partner.preduzece?.kratki_naziv}
          /> */}
          <div className="td-title">
            <p>
              <span>
                {partner.preduzece_partner
                  ? partner.preduzece_partner?.kratki_naziv
                  : partner.fizicko_lice?.ime +
                      ' ' +
                      partner.fizicko_lice?.prezime ||
                    partner.kontakt_ime + ' ' + partner.kontakt_prezime}
              </span>
              {(partner?.preduzece_partner?.verifikovan === 1 ||
                partner?.preduzece_partner?.verifikovan === true) &&
                partner.preduzece_partner && (
                  <i>
                    <Badge className="icon icon__fill-color-badge sm" />
                  </i>
                )}
            </p>

            {partner.preduzece_partner_id && (
              <h4 className="heading-quaternary">
                {partner.preduzece_partner?.grad}
              </h4>
            )}
          </div>
        </div>
      </td>
      <td className="cl">
        {partner.preduzece_partner?.pib || partner.fizicko_lice?.jmbg}
      </td>

      <td className="cd fw-500">
        {partner.kontakt_telefon ||
          partner.fizicko_lice?.telefon ||
          partner.preduzece_partner?.telefon}
      </td>

      <td>
        <div className="df jc-end ai-c">
          <button type="button" className="btn btn__light-dd btn__xs">
            <Dots className="icon lg" />
            <div className="drop-down" id="ddl">
              {partner?.preduzece_partner?.verifikovan === 0 ||
              partner?.preduzece_partner?.verifikovan === false ||
              partner.fizicko_lice_id ? (
                <a
                  onClick={handleClick}
                >
                  <Edit className="icon icon__dark md" />
                  Izmijeni
                </a>
              ) : null}
              <a onClick={() => handleDelete(partner.id)}>
                <Delete className="icon icon__dark md" />
                Obriši
              </a>
            </div>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default PartneriTableRow;
