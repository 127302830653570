import React from 'react';
import { RACUN, STAVKE } from '../../../constants/layout';

function TabView({view, setView}) {
    return (
        <div className="grid-view tabs">
          <button
            className={'btn' + (view === STAVKE ? ' active' : '')}
            onClick={() => setView(STAVKE)}
          >
           <p>Stavke</p>
          </button>
          <button
            className={'btn' + (view === RACUN ? ' active' : '')}
            onClick={() => setView(RACUN)}
          >
           <p>Račun</p>
          </button>
        </div>
      );
}

export default TabView