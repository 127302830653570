export const STORE_RACUN = 'STORE_RACUN';
export const STORE_BEZGOTOVINSKI_RACUN = 'STORE_BEZGOTOVINSKI_RACUN';

export const GET_RACUNI = 'GET_RACUNI';
export const SET_RACUNI = 'SET_RACUNI';

export const GET_AVANSNI_RACUNI = 'GET_AVANSNI_RACUNI';
export const SET_AVANSNI_RACUNI = 'SET_AVANSNI_RACUNI';

export const GET_IKOF_REFERENCE = 'GET_IKOF_REFERENCE';
export const SET_IKOF_REFERENCE = 'SET_IKOF_REFERENCE';

export const GET_RACUN = 'GET_RACUN';
export const CLEAR_RACUN = 'CLEAR_RACUN';
export const SET_RACUN = 'SET_RACUN';

export const UPDATE_RACUN = 'UPDATE_RACUN';
export const UPDATE_RACUN_PREVIEW = 'UPDATE_RACUN_PREVIEW';

export const DELETE_RACUN = 'DELETE_RACUN';

export const RESET_NOVI_RACUN = 'RESET_NOVI_RACUN';
export const SET_KOLICINA_ROBE = 'SET_KOLICINA_ROBE';
export const SET_KOLICINA_USLUGE = 'SET_KOLICINA_USLUGE';
export const UKLONI_ROBU = 'UKLONI_ROBU';
export const UKLONI_USLUGU = 'UKLONI_USLUGU';

export const GET_STAVKE = 'GET_STAVKE';
export const SET_STAVKE_ROBE = 'SET_STAVKE_ROBE';
export const SET_STAVKE_USLUGE = 'SET_STAVKE_USLUGE';

export const GET_ATRIBUTI_GRUPE = 'GET_ATRIBUTI_GRUPE';
export const SET_ATRIBUTI_GRUPE = 'SET_ATRIBUTI_GRUPE';
export const SET_ODABRANI_ATRIBUT_GRUPA = 'SET_ODABRANI_ATRIBUT_GRUPA';
