import { ErrorMessage, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Label from './Label';

const DropDownStaticStavka = ({
  label,
  options,
  disabled,
  onChangeExtra,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [shouldUpdate, setShouldUpdate] = useState(true); // State to control updates


  // console.log('props', props, 'label', label, 'options', options);

  const selectStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#F3F4F6',
      borderRadius: 4,
      height: '45px',
      minHeight: 'unset',
    }),
  };

  useEffect(() => {
    if (shouldUpdate && props.defaultValue && props.defaultValue.value !== undefined) {
      let option = {
        value: props.defaultValue.value,
      }
      helpers.setValue(option.value);
      // setShouldUpdate(false);
    }
  }, [props?.defaultValue?.value]);


  const handleChange = (option) => {
    //  console.log('Selected option:', option);
    setShouldUpdate(false); // Prevent useEffect from overriding this change
    helpers.setValue(option.value);
    if (onChangeExtra) {
      onChangeExtra(option);
    }
  };

  return (
    <div>
      <Label htmlFor={props.id || props.name} className="form__label">
        {label}
      </Label>
      <Select
        options={options}
        name={field.name}
        value={field.value && options.find((x) => x.value === field.value) ? options.find((x) => x.value === field.value) : props.defaultValue ? props.defaultValue :   ''}
        onChange={handleChange}
        styles={selectStyle}
        isSearchable
        onKeyDown={props.onKeyDown}
        isDisabled={disabled}
      />
      {!!meta.error && (
        <ErrorMessage component="div">{meta.error}</ErrorMessage>
      )}
    </div>
  );
};

export default DropDownStaticStavka;
