import {
  DELETE_RACUN,
  GET_ATRIBUTI_GRUPE,
  GET_RACUN,
  CLEAR_RACUN,
  GET_RACUNI,
  GET_AVANSNI_RACUNI,
  GET_STAVKE,
  RESET_NOVI_RACUN,
  SET_ATRIBUTI_GRUPE,
  SET_KOLICINA_ROBE,
  SET_KOLICINA_USLUGE,
  SET_ODABRANI_ATRIBUT_GRUPA,
  SET_RACUN,
  SET_RACUNI,
  SET_AVANSNI_RACUNI,
  SET_STAVKE_ROBE,
  SET_STAVKE_USLUGE,
  STORE_BEZGOTOVINSKI_RACUN,
  STORE_RACUN,
  UKLONI_ROBU,
  UKLONI_USLUGU,
  UPDATE_RACUN,
  GET_IKOF_REFERENCE,
  SET_IKOF_REFERENCE,
  UPDATE_RACUN_PREVIEW
} from '../actionTypes/RacuniActionTypes';

export const storeRacun = (payload, history) => ({
  type: STORE_RACUN,
  payload: {payload, history}
});

export const updateRacunPreview = (payload, history) => ({
  type: UPDATE_RACUN_PREVIEW,
  payload: {payload, history}
});

export const storeBezgotovinskiRacun = (payload, history) => ({
  type: STORE_BEZGOTOVINSKI_RACUN,
  payload: {payload, history}
});

export const getRacuni = (payload) => ({
  type: GET_RACUNI,
  payload,
});

export const setRacuni = (payload) => ({
  type: SET_RACUNI,
  payload,
});

export const getAvansniRacuni = (payload) => ({
  type: GET_AVANSNI_RACUNI,
  payload,
});

export const getIkofReference = (payload) => ({
  type: GET_IKOF_REFERENCE,
  payload,
});

export const setAvansniRacuni = (payload) => ({
  type: SET_AVANSNI_RACUNI,
  payload,
});

export const setIkofReference = (payload) => ({
  type: SET_IKOF_REFERENCE,
  payload,
});

export const getRacun = (payload) => ({
  type: GET_RACUN,
  payload,
});

export const clearRacun = (payload) => ({
  type: CLEAR_RACUN,
});

export const setRacun = (payload) => ({
  type: SET_RACUN,
  payload,
});

export const updateRacun = (payload, history) => ({
  type: UPDATE_RACUN,
  payload: {payload, history},
});

export const deleteRacun = (payload) => ({
  type: DELETE_RACUN,
  payload,
});

export const resetNoviRacun = () => ({
  type: RESET_NOVI_RACUN,
});

export const setKolicinaRobe = (roba, kolicina, popust, tipPopusta) => ({
  type: SET_KOLICINA_ROBE,
  payload: { roba, kolicina, popust, tipPopusta },
});

export const setKolicinaUsluge = (usluga, kolicina, popust, tipPopusta) => ({
  type: SET_KOLICINA_USLUGE,
  payload: { usluga, kolicina, popust, tipPopusta },
});

export const ukloniRobu = (roba) => ({
  type: UKLONI_ROBU,
  payload: roba,
});

export const ukloniUslugu = (usluga) => ({
  type: UKLONI_USLUGU,
  payload: usluga,
});

export const getStavke = (payload) => ({
  type: GET_STAVKE,
  payload,
});

export const setStavkeRobe = (payload) => ({
  type: SET_STAVKE_ROBE,
  payload,
});

export const setStavkeUsluge = (payload) => ({
  type: SET_STAVKE_USLUGE,
  payload,
});

export const getAtributiGrupe = (payload) => ({
  type: GET_ATRIBUTI_GRUPE,
  payload,
});

export const setAtributiGrupe = (payload) => ({
  type: SET_ATRIBUTI_GRUPE,
  payload,
});

export const setOdabraniAtributGrupa = (payload) => ({
  type: SET_ODABRANI_ATRIBUT_GRUPA,
  payload,
});
