import React, { useState, useEffect } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { Link, Prompt } from 'react-router-dom';
import { ReactComponent as LinkSvg } from '../../../assets/icon/link.svg';

import BezgotovinskiStavkeFieldArray from './BezgotovinskiStavkeFieldArray';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRacuni,
  getStavke,
  storeBezgotovinskiRacun,
} from '../../../store/actions/RacuniActions';
import { getZavisniTroskovi } from '../../../store/actions/ZavisniTroskoviActions';
import BezgotovinskiPorezi from './BezgotovinskiPorezi';
import BezgotovinskiUkupno from './BezgotovinskiUkupno';
import BezgotovinskiHeader from './BezgotovinskiHeader';
import { RACUNI, PREDRACUNI, ULAZNI_RACUNI } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { poreziService } from '../../../services/PoreziService';
import BezgotovinskiStavkeForm from './BezgotovinskiStavkeForm';
import { storePredracun } from '../../../store/actions/PredracuniActions';
import { toast } from 'react-toastify';
import BezgotovinskiAvansniReferenceNaIkof from './BezgotovinskiAvansniReferenceNaIkof';
import BezgotovinskiOdobrenjaReferenceNaIkof from './BezgotovinskiOdobrenjaReferenceNaIkof';
import { renderTitles } from '../../../helpers/bezgotovinskiForm';
import {
  getUlazniRacuni,
  storeUlazniRacun,
} from '../../../store/actions/UlazniRacuniActions';
import BezgotovinskiFooter from './BezgotovinskiFooter';
import BezgotovinskiZavisniTroskovi from './BezgotovinskiZavisniTroskovi';
import BezgotovinskiZavisniTroskoviFieldArray from './BezgotovinskiZavisniTroskoviFieldArray';
import BezgotovinskiDokumenta from './BezgotovinskiDokumenta';
import Modal from '../../shared/forms/Modal';
import ReactDOM from "react-dom";
import { formatirajCijenu, getUkupnaCijenaSaPdv } from '../../../helpers/racuni';
import ModalUlazniRacuni from '../../shared/forms/ModalUlazniRacuni';
import { TIPOVI_POREZA } from '../../../constants/racuni';
import { avansniRacuniSelector } from '../../../store/selectors/RacuniSelector';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const Bezgotovinski = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathName = window.location.pathname;
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  let saberi = true
  const [porezi, setPorezi] = useState([]);
  const [isEditingStavka, setIsEditingStavka] = useState(false)
  const [change, setChange] = useState(false)
  const avansniRacuni = useSelector(avansniRacuniSelector());

  const titles = renderTitles(pathName);

  useEffect(() => {
    dispatch(getStavke({sortiraj: true}));
    if (pathName.includes('/ulazni-racuni')) {
      dispatch(getZavisniTroskovi());
    }

    (async () => setPorezi((await poreziService.getPorezi()).data))();
  }, [dispatch]);






  const napraviKorektivni = (e, pathName, noviRacun, history) => {
    e.stopPropagation();
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

    const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);


    ReactDOM.render(
        <Modal
            title="Korektivni račun?"
            message=""
            onConfirm={(value) => {napraviRacun(pathName, {...noviRacun, vrijemeKorektivnog: value}, history); confirm()}}
            onCancel={cancel}
            confirmText="Kreiraj račun"
            cancelText="Obustavi"
            racunIzOvogMjeseca={daLiSuAvansniIzProslogMjeseca(noviRacun)}
        />
        , node
    );
  };


  const daLiSuAvansniIzProslogMjeseca = (values) =>{
    let prosliMjesec = false
    const date = new Date()
    values?.referentni_ikofi.map((ikof) =>{
      avansniRacuni.map((avansni) =>{
        if(avansni.ikof === ikof){
          if(new Date(avansni.datum_izdavanja) > new Date(date.getFullYear(), date.getMonth(), 1)){
              prosliMjesec =  true
            
          }
        }
      })
    })



    return prosliMjesec;
  }

  const handleSubmit = (values) => {
    values.stavke.forEach(stavka => {
      const cijenaBezPdv = parseFloat(stavka.cijena_bez_pdv) || 0;
      const popust = parseFloat(stavka.popust) || 0;

      // Check if 'cijena_bez_pdv_popust' is NaN or null, and then calculate the new value
      if (Number.isNaN(parseFloat(stavka.cijena_bez_pdv_popust)) || stavka.cijena_bez_pdv_popust === null) {
          stavka.cijena_bez_pdv_popust = cijenaBezPdv - popust;
      }
  });
    // cijena_bez_pdv_popust change if null end
    let fileArray = [];
    files.map((file) => {
      fileArray.push(file.getFileEncodeDataURL());
    });

    if (values.tip_racuna === 'knjizno' && !values.referentni_ikofi.length) {
      toast.error(
        'Morate odabrati referentne račune za ovog kupca!',
        toastSettings
      );
      return;
    }

    const noviRacun = {
      ...values,
      vrsta_racuna: 'bezgotovinski',
      popust_procenat: values.tip_popusta === 'procenat' ? values.popust : null,
      popust_iznos: values.tip_popusta === 'iznos' ? values.popust : null,
      popust_na_cijenu_bez_pdv: values.popust_bez_pdv,
      datum_izdavanja: values.datum_izdavanja?.toISOString().split('T')[0],
      datum_za_placanje: values.datum_za_placanje?.toISOString().split('T')[0],
      datum_uplate: values.datum_uplate?.toISOString().split('T')[0],
      korektivni_racun: values.korektivni_racun === '0' ? 0 : 1,
      korektivni_racun_vrsta:
        values.korektivni_racun === '0' ? null : values.korektivni_racun,
      fiskalizuj: values.fiskalizuj,
      napraviRacunIzPredracuna: values.napraviRacun,
      tip_slanja: values.tip_slanja ? values.tip_slanja : null,
      datum_slanja: values.datum_slanja ? values.datum_slanja : null,
      vrijeme: values.vrijeme ? values.vrijeme : null,
      vazi_do: values.vazi_do ? values.vazi_do : null,
      vrijeme_slanja:
        values.vrijeme_slanja !== 'bez_slanja' ? values.vrijeme_slanja : null,
      tekst: values.tekst ? values.tekst : null,
      referentni_ikofi: values?.referentni_ikofi
        ? values?.referentni_ikofi
        : [],
      files: fileArray,
      status:
        values?.nacin_placanja === 'ADVANCE' && !values?.vise_nacina_placanja
          ? 'placen'
          : values?.status,
    };
    // fix za bezgotovinski kada je odabran popust bez pdva start
    for (let i = 0; i < values.stavke.length; i++) {
      if (values.stavke[i]?.tip_popusta === 'iznos_popusta_bez_pdv') {
        let cijenaBezPdvSaPopustom = values.stavke[i]?.cijena_bez_pdv - Number(values.stavke[i]?.popust || 0);
        noviRacun.stavke[i].cijena_sa_pdv_popust = cijenaBezPdvSaPopustom * (Number(values.stavke[i]?.porez?.stopa) + 1);
      }
    }
   // fix za bezgotovinski kada je odabran popust bez pdva end
    if (!values.partner_id) {
      if (pathName.includes('/ulazni-racuni')) {
        toast.error(
          'Dobavljač nedostaje! Izaberite dobavljača iz liste dobavljača',
          toastSettings
        );
      } else {
        toast.error(
          'Kupac nedostaje! Izaberite kupca iz liste kupaca',
          toastSettings
        );
      }
      return;
    } else if (values.vrijeme_slanja !== 'bez_slanja' && !values.tekst) {
      toast.error('Morate unijeti tekst za podsjetnik', toastSettings);
      return;
    } else if (!values.stavke.length) {
      toast.error(
        'Stavke nedostaju! Morate unijeti makar jednu stavku. Kada izaberete stavku pritisnite dugme "Dodaj stavku na račun".',
        toastSettings
      );
      return;
    } else if (
      values.nacin_placanja === 'ADVANCE' &&
      !values.referentni_ikofi.length
    ) {
      toast.error(
        'Morate odbarati referentne račune kod avansnog plaćanja!',
        toastSettings
      );
      return;
    } else if (pathName === '/ulazni-racuni/create' && !values.redni_broj) {
      toast.error('Broj računa je obavezan', toastSettings);
      return;
    }
    if(values.nacin_placanja === "ADVANCE" && values.fiskalizuj === true  && daLiSuAvansniIzProslogMjeseca(noviRacun) === false){
        napraviKorektivni(window.event, pathName, noviRacun, history)
    }else{
      napraviRacun(pathName, noviRacun, history)
    }

 
  };

  const napraviRacun = (pathName, noviRacun, history) => {
    switch (pathName) {
      case '/predracuni/create':
        dispatch(storePredracun(noviRacun, history));
        // dispatch(getRacuni({ tip: 'predracun' }));
        // history.push(PREDRACUNI.INDEX);
        break;
      case '/ulazni-racuni/create':
        dispatch(storeUlazniRacun(noviRacun, history));
        // dispatch(getUlazniRacuni());
        // history.push(ULAZNI_RACUNI.INDEX);
        break;
      default:
        dispatch(storeBezgotovinskiRacun(noviRacun, history));
        // dispatch(getRacuni({ tip: 'racun' }));
        // history.push(RACUNI.INDEX);
    }
  }

  const today = new Date();
  const seven_days = new Date();
  seven_days.setDate(seven_days.getDate() + 7);

  const initialValues = {
    stavke: [],
    zavisni_troskovi: [],
    korektivni_racun: '0',
    datum_izdavanja: today,
    datum_za_placanje: seven_days,
    pdv_obveznik: 1,
    status: pathName === '/predracuni/create' ? 'kreiran' : 'nijeplacen',
    nacin_placanja: 'ACCOUNT',
    niz_stavke: [],
    niz_zavisni_troskovi: [],
    referentni_ikofi: [],
    popustObjekat: {},
    tip_racuna: 'racun',
    vrijeme_slanja: 'bez_slanja',
    uplate_racuna_djelimicne: [],
    uplate_racuna_djelimicne_prikaz: []
  };

  const saberiTrue = () => saberi = true
  const saberiFalse = () =>{
   saberi = false}

  const showModal = (e, values, setFieldValue) => {
  const  ukupnoSaPdv =   values.zavisni_troskovi.length > 0
  ? values.zavisni_troskovi
      .map((zavisni_trosak) => zavisni_trosak.cijena_bez_pdv)
      .reduce((a, b) => +a + +b)
  : 0;
    e.stopPropagation();
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

    const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);

    let stavke = []

    values.stavke.map((stavka) => {
      if(stavka && stavka.zavisni_troskovi){
        stavke.push(stavka)
      }
    })



    ReactDOM.render(
      <ModalUlazniRacuni
          title="Podjela zavisnih troškova po stavkama"
          message={`Iznos od ${formatirajCijenu(ukupnoSaPdv)} biti ce podijeljen po stavkama pojedinacno ${formatirajCijenu(ukupnoSaPdv/values.stavke.length)}`}
          onConfirm={() => {
            dodajZavisneTroskoveNaStavke(e, values, setFieldValue);
            confirm()
          }}
          onCancel={cancel}
          confirmText="Podijeli"
          cancelText="Obustavi"
          stavke={stavke}
          saberiTrue={saberiTrue}
          saberiFalse={saberiFalse}
      />
      , node
  );
  }


  function dodajZavisneTroskoveNaStavke(e, values, setFieldValue) {
    e.preventDefault();
    const ukupnaCijenaZavisnihTroskova =
      values.zavisni_troskovi.length > 0
        ? values.zavisni_troskovi
            .map((zavisni_trosak) => zavisni_trosak.cijena_bez_pdv)
            .reduce((a, b) => +a + +b)
        : 0;

    const zavisniTrosakPoStavki =
      ukupnaCijenaZavisnihTroskova / values.stavke.length;
    for (let i = 0; i < values.stavke.length; i++) {
      const zavissniTrosak = values?.stavke[i]?.zavisni_troskovi ?? values?.stavke[i]?.ulazne_stavke_kalkulacije?.zavisni_troskovi ?? 0
      const ukupno = Number(zavisniTrosakPoStavki) + Number(zavissniTrosak)
      setFieldValue(`stavke[${i}].zavisni_troskovi`, saberi ? ukupno : zavisniTrosakPoStavki);
      const marza = values?.stavke[i]?.marza ? Number(values?.stavke[i]?.marza) : values?.stavke[i]?.ulazne_stavke_kalkulacije?.marza ? Number(values?.stavke[i]?.ulazne_stavke_kalkulacije?.marza) : 0

        const nabavna_cijena = values?.stavke[i]?.nabavna_cijena ?? +values?.stavke[i]?.ulazne_stavke_kalkulacije?.cijena_bez_pdv -(marza/100+1) -zavissniTrosak ?? 0
        const nabavnaNovaCijena = (values?.stavke[i]?.zavisni_troskovi || values?.stavke[i]?.ulazne_stavke_kalkulacije?.zavisni_troskovi) && nabavna_cijena ? Number(nabavna_cijena) : 0
        
        const novaNabavnaCijena = values?.stavke[i]?.zavisni_troskovi || values?.stavke[i]?.ulazne_stavke_kalkulacije?.zavisni_troskovi ? (Number(nabavna_cijena)) + ( saberi ? ukupno : zavisniTrosakPoStavki) : +values?.stavke[i]?.cijena_bez_pdv + ( saberi ? ukupno : zavisniTrosakPoStavki) 

        
        
        if(values?.stavke[i]?.zavisni_troskovi || values?.stavke[i]?.ulazne_stavke_kalkulacije?.zavisni_troskovi){
        setFieldValue(`stavke[${i}].nabavna_cijena`, nabavnaNovaCijena);
        }else{
            setFieldValue(`stavke[${i}].nabavna_cijena`, 0);

        }
        let nova_cijena_bez_pdv =
      novaNabavnaCijena * (marza/ 100 + 1);

      setFieldValue(`stavke[${i}].nova_cijena_bez_pdv`, nova_cijena_bez_pdv);


      const porez = TIPOVI_POREZA.find((x) => x.id === values?.stavke[i]?.novi_porez_id)
      const porezStopa = porez && porez.value ?  porez.value : 0.21

      setFieldValue(
        `stavke[${i}].nova_ukupna_cijena`,
        nova_cijena_bez_pdv * (1 + Number(porezStopa))
      );
      setFieldValue(
        `stavke[${i}].novi_pdv`,
        nova_cijena_bez_pdv * Number(porezStopa)
      );


      setFieldValue(`stavke[${i}].novi_porez_id`, porez?.id ?? 4);
        setFieldValue(`stavke[${i}].novi_porez`, porez?.value ?? 0.21);
        setFieldValue(`stavke[${i}].novi_porez_naziv`, porez?.label ?? '21%');
        setFieldValue(`stavke[${i}].marza`, marza);
        setFieldValue(`stavke[${i}].izmijeni_stavku`, false);

      


      setFieldValue(`stavke[${i}].postoji_zavisni`, true);
    } 

    toast.success(
      'Uspješno ste dodali zavisne troškove na stavke!',
      toastSettings
    );

    saberi = true
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const renderStavkeForm = (values) => {
    if (values.tip_racuna === 'avans' && values.stavke.length > 0) {
      return null;
    }
    

    return (
      <>
        <h2 className="heading-secondary">
          {values.tip_racuna === 'racun' || values.tip_racuna === 'predracun'
            ? 'Nova stavka / pretraga stavki'
            : 'Nova stavka'}
        </h2>
        <div className="main-content__box" style={{ display: 'block' }}>
          <div className="main-content__box--header">
            <div className="row">
              <div className="col-xl-4 col-md-4 col-12">
                <h4 className="heading-quaternary">Naziv usluge / robe</h4>
              </div>
              <div className="col-xl-2 col-md-2 tabp-none">
                <h4 className="heading-quaternary">jedinična cijena</h4>
              </div>
              <div className="col-xl-2 col-md-2 tabp-none">
                <h4 className="heading-quaternary">JM / Količina</h4>
              </div>
              <div className="col-xl-2 col-md-2 tabp-none">
                <h4 className="heading-quaternary">PDV stopa</h4>
              </div>
              <div className="col-xl-2 col-md-2 tabp-none">
                <h4 className="heading-quaternary txt-right">
                  {values.tip_racuna === 'knjizno'
                    ? 'Iznos povrata robe'
                    : values.tip_racuna !== "avans" ? 'Tip Popusta/iznos' : ""}
                </h4>
              </div>
            </div>
          </div>
          <BezgotovinskiStavkeForm
            pathName={pathName}
            show={show}
            setShow={setShow}
            isEditingStavka={isEditingStavka}
            setIsEditingStavka={setIsEditingStavka}
            change={change}
          />
        </div>
      </>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      //innerRef={formikRef}
      // enableReinitialize
      onSubmit={handleSubmit}
      // validationSchema={BezgotovinskiSchema}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values, dirty, setFieldValue, isSubmitting }) => (
        <Form onKeyDown={handleKeyDown}>
          <Prompt
            when={dirty && !isSubmitting}
            message="Ukoliko nastavite podaci koje ste unijeli neće biti sačuvani"
          />
          <div className="screen-content">
            <Link to={`${titles.link}?page=1`} className="back-link df">
              <LinkSvg />
              <p>{titles.povratak}</p>
            </Link>

            <h1 className="heading-primary">{titles.title}</h1>

            <BezgotovinskiHeader pathName={pathName} />

            {/* STAVKE */}
            {renderStavkeForm(values)}

            <FieldArray name="stavke">
              {(arrayHelpers) => (
                <BezgotovinskiStavkeFieldArray
                  {...arrayHelpers}
                  pathName={pathName}
                  setShow={setShow}
                  setIsEditingStavka={setIsEditingStavka}
                  isEditingStavka={isEditingStavka}
                  change={change}
                  setChnage={setChange}
                />
              )}
            </FieldArray>
            {/* STAVKE */}

            {values.nacin_placanja === 'ADVANCE' &&
              values.partner_id &&
              values.tip_racuna === 'racun' && (
                <BezgotovinskiAvansniReferenceNaIkof />
              )}

            {values.tip_racuna === 'knjizno' && values.partner_id && (
              <BezgotovinskiOdobrenjaReferenceNaIkof />
            )}

            {pathName === '/ulazni-racuni/create' && (
              <>
                <h2 className="heading-secondary">Novi zavisni troškovi</h2>
                <div className="main-content__box" style={{ display: 'block' }}>
                  <div className="main-content__box--header">
                    <div className="row">
                      <div className="col-xl-4 col-md-4 col-12">
                        <h4 className="heading-quaternary">
                          naziv / broj dokumenta
                        </h4>
                      </div>
                      <div className="col-xl-2 col-md-2 tabp-none">
                        <h4 className="heading-quaternary">datum</h4>
                      </div>
                      <div className="col-xl-2 col-md-2 tabp-none">
                        <h4 className="heading-quaternary">oslob. / stopa 0</h4>
                      </div>
                      <div className="col-xl-2 col-md-2 tabp-none">
                        <h4 className="heading-quaternary">snižena / opšta</h4>
                      </div>
                      <div className="col-xl-2 col-md-2 tabp-none">
                        <h4 className="heading-quaternary txt-right">
                          Ukupna cijena
                        </h4>
                      </div>
                    </div>
                  </div>
                  <BezgotovinskiZavisniTroskovi />
                </div>

                <FieldArray name="zavisni_troskovi">
                  {(arrayHelpers) => (
                    <BezgotovinskiZavisniTroskoviFieldArray {...arrayHelpers} />
                  )}
                </FieldArray>

                <button
                  className="btn btn__primary mb-4"
                  type="button"
                  disabled={values.zavisni_troskovi.length ===0  ? true : false}
                  onClick={(e) =>
                    showModal(e, values, setFieldValue)
                  }
                >
                  Podijeli zavisne troškove po stavkama
                </button>
              </>
            )}

            <BezgotovinskiPorezi />
            <BezgotovinskiUkupno />

            {pathName === '/ulazni-racuni/create' && (
              <BezgotovinskiDokumenta files={files} setFiles={setFiles} />
            )}

            <BezgotovinskiFooter pathName={pathName} history={history} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Bezgotovinski;
