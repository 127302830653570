import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../shared/forms/DropDown';
import { jediniceMjereService } from '../../../services/JediniceMjereService';
import DropDownStatic from '../../shared/forms/DropDownStatic';
import DropDownStaticStavka from '../../shared/forms/DropDownStaticStavka';
import { TIPOVI_POPUSTA, TIPOVI_POREZA } from '../../../constants/racuni';
import {
  formatirajCijenu,
  getUkupanPdv,
  getUkupnaCijenaSaPdv,
  getUkupnaCijenaBezPdv,
  getUkupnaCijenaSaPdvBezPopusta,
  getUkupnaCijenaBezPdvBezPopusta,
  getUkupnaNovaCijenaBezPdv,
  getUkupnaNovaCijenaSaPdv,
  getUkupanPdvBezPopusta,
} from '../../../helpers/racuni';
import { toast } from 'react-toastify';
import StavkeDropdown from '../NoviRacun/StavkeDropdown';
import { authPreduzeceSelector } from '../../../store/selectors/PreduzecaSelector';
import {
  storeUsluga,
  updateUsluga,
} from '../../../store/actions/UslugeActions';
import BezgotovinskiNoveCijeneStavkiForm from './BezgotovinskiNoveCijeneStavkiForm';

function PositiveNumberField({ field, form, setIsStavkaChanged, ...props }) {
  const handleChange = (event) => {
    const { name, value } = event.target;
    let sanitizedValue = value;
    if (value && Number(value) < 0) {
      sanitizedValue = '';
    }
    if (sanitizedValue !== field.value) {
      if (setIsStavkaChanged) {
        setIsStavkaChanged(true);
      }
    }
    form.setFieldValue(name, sanitizedValue);
  };
  return <input {...field} {...props} onChange={handleChange} />;
}
const blockInvalidChar = (e) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

toast.configure();
const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const BezgotovinskiStavkeForm = ({ pathName, setIsEditingStavka, change, isEditingStavka }) => {
  const { values, setFieldValue } = useFormikContext();
  const [odabranaStavka, setOdabranaStavka] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [lastClickedInput, setLastClickedInput] = useState('bez_pdv');
  const dispatch = useDispatch();
  const authPreduzece = useSelector(authPreduzeceSelector());
  const [azurirajStavku, setAzurirajStavku] = useState(false);
  const [show, setShow] = useState(false);
  const [isStavkaChanged, setIsStavkaChanged] = useState(false);
  const [isStavkaSetManually, setIsStavkaSetManually] = useState(false);
  const [nabanvanCijena, setNabanvanCijena] = useState(values?.niz_stavke[0]?.nabavna_cijena ??  '')

  const [clearValue, setClearValue] = useState(false)


  useEffect(() => {
    setNabanvanCijena(values?.niz_stavke[0]?.nabavna_cijena ?? '')
  }, [values?.niz_stavke[0]?.nabavna_cijena])


  useEffect(() => {
    if(isEditingStavka){
      dodajStavku(values)
    }
  },[isEditingStavka, change])


  const setSelectedType = (option, type) => {
    setCheckbox(false);
    setIsStavkaChanged(false);
    if (type === 'addManually') {
      setShow(true);
      setOdabranaStavka(false);
      setIsStavkaSetManually(true);
      clearAllValues();

      setFieldValue(`niz_stavke[0].naziv`, option);
      if (!values?.niz_stavke[0]?.porez_id) {
        if (authPreduzece.pdv_obveznik) {
          setFieldValue(`niz_stavke[0].porez`, 0.21);
          setFieldValue(`niz_stavke[0].porez_id`, 4);
          setFieldValue(`niz_stavke[0].porez_naziv`, '21%');
        } else {
          setFieldValue(`niz_stavke[0].porez`, 0.0);
          setFieldValue(`niz_stavke[0].porez_id`, 1);
          setFieldValue(`niz_stavke[0].porez_naziv`, 'Oslobodjen PDV');
        }
      }

      setFieldValue(`niz_stavke[0].jedinica_mjere_id`, 1);
      setFieldValue(`niz_stavke[0].jedinica_mjere[naziv]`, 'kom');
      setFieldValue(`niz_stavke[0].odabranaUsluga`, null);
    } else {
      setIsStavkaSetManually(false);
      setOdabranaStavka(true);
      setShow(true);
      const roba = option.roba ? option.roba : null;
      setFieldValue(`niz_stavke[0].naziv`, roba ? roba.naziv : option.naziv);
      setFieldValue(`niz_stavke[0].opis`, roba ? roba.opis : option.opis);
      setFieldValue(
        `niz_stavke[0].cijena_bez_pdv`,
        roba ? roba.cijene_roba[0].cijena_bez_pdv : option.cijena_bez_pdv
      );

      setFieldValue(
        `niz_stavke[0].cijena_bez_pdv_popust`,
        roba
          ? roba.cijene_roba[0].cijena_bez_pdv_popust
          : option.cijena_bez_pdv_popust
      );

      setFieldValue(`niz_stavke[0].roba_id`, option.id);
      setFieldValue(
        `niz_stavke[0].ukupna_cijena`,
        roba ? +roba.cijene_roba[0].ukupna_cijena : +option.ukupna_cijena
      );

      setFieldValue(
        `niz_stavke[0].cijena_sa_pdv_popust`,
        roba
          ? +roba.cijene_roba[0].cijena_sa_pdv_popust
          : option.cijena_sa_pdv_popust
      );

      setFieldValue(`niz_stavke[0].kolicina`, 1);
      setFieldValue(
        `niz_stavke[0].porez`,
        roba ? roba.cijene_roba[0].porez.stopa : option?.porez?.stopa
      );
      setFieldValue(
        `niz_stavke[0].porez_id`,
        roba ? roba.cijene_roba[0].porez.id : option?.porez?.id
      );
      setFieldValue(
        `niz_stavke[0].porez_naziv`,
        roba ? roba.cijene_roba[0].porez.naziv : option?.porez?.naziv
      );
      setFieldValue(
        `niz_stavke[0].jedinica_mjere[naziv]`,
        option?.jedinica_mjere?.naziv
      );
      if (roba) {
        setFieldValue(
          `niz_stavke[0].popust`,
          option?.atribut_robe?.popust_procenti
        );
      } else {
        setFieldValue(
          `niz_stavke[0].popust`,
          option?.grupa?.popust_procenti > 0
            ? option?.grupa?.popust_procenti
            : null
        );
      }

      setFieldValue(
        `niz_stavke[0].odabranaUsluga.popust`,
        roba
          ? parseFloat(option?.atribut_robe?.popust_procenti)
          : parseFloat(option?.grupa?.popust_procenti)
      );

      roba
        ? setFieldValue(
            `niz_stavke[0].odabranaUsluga.roba.jedinica_mjere_id`,
            1
          )
        : setFieldValue(
            `niz_stavke[0].jedinica_mjere_id`,
            option.jedinica_mjere.id
          );

      setFieldValue(
        `niz_stavke[0].tip_popusta`,
        values.niz_stavke[0]?.odabranaUsluga?.tip_popusta ?? 'procenat'
      );
    }

  };

  // checkboz showing logic start
  const checkIfSacuvajStavkuShowed = () => {
    if (pathName.includes('/ulazni-racuni')) {
      return false;
    }
    if (odabranaStavka && isStavkaChanged) {
      return true;
    } else if (!odabranaStavka && isStavkaSetManually) {
      return true;
    }
    return false;
  };
  // checkbox showing logic end

  const setManualValues = (values, event, type) => {
    setIsStavkaChanged(true);
    setFieldValue(
      `niz_stavke[0].porez`,
      values.porez > 0 ? values.porez : null
    );

    if (!values?.kolicina) {
      setFieldValue(`niz_stavke[0].kolicina`, 1);
    }

    if (type === 'bez_pdv') {
      setFieldValue(
        `niz_stavke[0].cijena_bez_pdv`,
        event.target.value > 0 ? event.target.value : null
      );
      setFieldValue(`niz_stavke[0].cijena_bez_pdv_popust`, event.target.value);
      setFieldValue(`niz_stavke[0].nabavna_cijena`, event.target.value);

      setFieldValue(
        `niz_stavke[0].ukupna_cijena`,
        event.target.value * (1 + +values.porez)
      );
      setFieldValue(
        `niz_stavke[0].cijena_sa_pdv_popust`,
        event.target.value * (1 + +values.porez)
      );

      if (odabranaStavka) {
        setFieldValue(
          `niz_stavke[0].odabranaUsluga.cijena_bez_pdv`,
          event.target.value
        );
        setFieldValue(
          `niz_stavke[0].odabranaUsluga.cijena_bez_pdv_popust`,
          event.target.value
        );
        setFieldValue(
          `niz_stavke[0].odabranaUsluga.nabavna_cijena`,
          event.target.value
        );

        setFieldValue(
          `niz_stavke[0].odabranaUsluga.ukupna_cijena`,
          event.target.value * (1 + +values.odabranaUsluga.porez.stopa)
        );
        setFieldValue(
          `niz_stavke[0].odabranaUsluga.cijena_sa_pdv_popust`,
          event.target.value * (1 + +values.odabranaUsluga.porez.stopa)
        );
      }
    }

    if (type === 'sa_pdv') {
      setFieldValue(`niz_stavke[0].ukupna_cijena`, event.target.value);
      setFieldValue(`niz_stavke[0].cijena_sa_pdv_popust`, event.target.value);

      setFieldValue(
        `niz_stavke[0].cijena_bez_pdv`,
        event.target.value / (1 + +values.porez)
      );
      setFieldValue(
        `niz_stavke[0].cijena_bez_pdv_popust`,
        event.target.value / (1 + +values.porez)
      );
      setFieldValue(
        `niz_stavke[0].nabavna_cijena`,
        event.target.value / (1 + +values.porez)
      );

      if (odabranaStavka) {
        setFieldValue(
          `niz_stavke[0].odabranaUsluga.ukupna_cijena`,
          +event.target.value
        );
        setFieldValue(
          `niz_stavke[0].odabranaUsluga.cijena_sa_pdv_popust`,
          +event.target.value
        );

        setFieldValue(
          `niz_stavke[0].odabranaUsluga.cijena_bez_pdv`,
          +event.target.value / (1 + +values.odabranaUsluga.porez.stopa)
        );
        setFieldValue(
          `niz_stavke[0].odabranaUsluga.cijena_bez_pdv_popust`,
          +event.target.value / (1 + +values.porez)
        );
        setFieldValue(
          `niz_stavke[0].odabranaUsluga.nabavna_cijena`,
          +event.target.value / (1 + +values.odabranaUsluga.porez.stopa)
        );
      }
    }
  };

  const setManualPdv = (values, option) => {
    setFieldValue(`niz_stavke[0].porez`, option.value);
    setFieldValue(`niz_stavke[0].porez_id`, option.id);
    setFieldValue(`niz_stavke[0].porez_naziv`, option.label);

    if (values.odabranaUsluga && values.odabranaUsluga.porez) {
      setFieldValue(`niz_stavke[0].odabranaUsluga.porez.stopa`, option.value);
      setFieldValue(`niz_stavke[0].odabranaUsluga.porez.id`, option.id);
      setFieldValue(`niz_stavke[0].odabranaUsluga.porez.naziv`, option.label);
    }

    if (lastClickedInput === 'bez_pdv') {
      if (values?.cijena_bez_pdv) {
        setFieldValue(
          `niz_stavke[0].ukupna_cijena`,
          values.cijena_bez_pdv * (1 + +option.value)
        );
        setFieldValue(
          `niz_stavke[0].cijena_sa_pdv_popust`,
          values.cijena_bez_pdv * (1 + +option.value)
        );
      }

      if (values?.iznos_povrata_bez_pdv) {
        setFieldValue(
          `niz_stavke[0].iznos_povrata_sa_pdv`,
          values.iznos_povrata_bez_pdv * (1 + +option.value)
        );
      }
    }

    if (lastClickedInput === 'sa_pdv') {
      if (values?.ukupna_cijena) {
        setFieldValue(
          `niz_stavke[0].cijena_bez_pdv`,
          values.ukupna_cijena / (1 + +option.value)
        );
        setFieldValue(
          `niz_stavke[0].cijena_bez_pdv_popust`,
          values.ukupna_cijena / (1 + +option.value)
        );
      }

      if (values?.iznos_povrata_sa_pdv) {
        setFieldValue(
          `niz_stavke[0].iznos_povrata_bez_pdv`,
          values.iznos_povrata_sa_pdv * (1 + +option.value)
        );
      }
    }
  };

  const setManualJedinicaMjere = (option) => {
    setFieldValue(`niz_stavke[0].jedinica_mjere_id`, option.value);
    setFieldValue(`niz_stavke[0].jedinica_mjere[naziv]`, option.label);
  };

  const clearAllValues = () => {
    setFieldValue('niz_stavke[0].odabranaUsluga', undefined);
    setFieldValue('niz_stavke[0].naziv', '');
    setFieldValue('niz_stavke[0].opis', '');
    setFieldValue('niz_stavke[0].popust', '');
    setFieldValue('niz_stavke[0].cijena_bez_pdv', '');
    setFieldValue('niz_stavke[0].cijena_bez_pdv_popust', '');
    setFieldValue('niz_stavke[0].cijena_sa_pdv_popust', '');
    setFieldValue('niz_stavke[0].ukupna_cijena', '');
    setFieldValue('niz_stavke[0].kolicina', 1);
    setFieldValue('niz_stavke[0].jedinica_mjere.naziv', '');
    setFieldValue('niz_stavke[0].nova_cijena_bez_pdv', '');
    setFieldValue('niz_stavke[0].nova_ukupna_cijena', '');
    setFieldValue('niz_stavke[0].marza', '');
    setFieldValue('niz_stavke[0].nabavna_cijena', '');
    setFieldValue('niz_stavke[0].nova_jedinica_mjere_id', '');
    setFieldValue('niz_stavke[0].novi_porez_id', '');
    setFieldValue('niz_stavke[0].zavisni_troskovi', '');
    setFieldValue('niz_stavke[0].novi_pdv', '');
    setFieldValue('niz_stavke[0].iznos_povrata_bez_pdv', '');
    setFieldValue('niz_stavke[0].iznos_povrata_sa_pdv', '');
    setFieldValue('niz_stavke[0].tip_popusta', undefined);
    setNabanvanCijena('')
    setFieldValue('niz_stavke[0].tip_popusta', undefined);
    setFieldValue('niz_stavke[0].tip_popusta_object', '');
    setCheckbox(false);
    setClearValue(!clearValue)
    setAzurirajStavku(false);
  };

  function exists(arr, search) {
    return arr.some((row) => row.porez_id === search);
  }

  function handleSetAzurirajStavku(stavka, value = null) {
    if (pathName.includes('/ulazni-racuni')) {
      if (stavka === true) {
        if (
         value &&
          values.niz_stavke[0]?.nabavna_cijena !== '' &&
          (!values.niz_stavke[0]?.nova_cijena_bez_pdv ||
            values.niz_stavke[0]?.nova_cijena_bez_pdv <= 0) &&
          (!values.niz_stavke[0]?.nova_ukupna_cijena ||
            values.niz_stavke[0]?.nova_ukupna_cijena <= 0)
        ) {
          toast.error(
            'Da bi ste promjenili cijenu, pravilno popunite sva potrebna polja!',
            toastSettings
          );
          return;
        }
      }

      setAzurirajStavku(stavka);
    } else {
      if (stavka === true) {
        if (
          !values.niz_stavke[0]?.nova_cijena_bez_pdv ||
          values.niz_stavke[0]?.nova_cijena_bez_pdv <= 0 ||
          !values.niz_stavke[0]?.nova_ukupna_cijena ||
          values.niz_stavke[0]?.nova_ukupna_cijena <= 0 ||
          !values.niz_stavke[0]?.nabavna_cijena ||
          +values.niz_stavke[0]?.nabavna_cijena < 0
        ) {
          toast.error(
            'Da bi ste promjenili cijenu, pravilno popunite sva potrebna polja!',
            toastSettings
          );
          return;
        }
      }
      setAzurirajStavku(stavka);
    }
  }

  function makeId(length) {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      dodajStavku(values);
    }
  };
  const handleKeyDownWithValidation = (event) => {
    handleKeyDown(event);
    blockInvalidChar(event);
  };

  const dodajStavku = (values) => {
    setCheckbox(false);
    setOdabranaStavka(false);
    setIsStavkaChanged(false);
    setIsStavkaSetManually(false);
    setFieldValue('niz_stavke[0].start_dodaj', true)
    if (values.tip_racuna === 'knjizno') {
      if (
        !values?.niz_stavke[0]?.cijena_bez_pdv &&
        !values?.niz_stavke[0]?.iznos_povrata_bez_pdv
      ) {
        toast.error(
          'Morate unijeti ili cijenu ili iznos povrata!',
          toastSettings
        );
      }
    }

    if (!values?.niz_stavke[0]?.naziv) {
      toast.error('Stavka mora imati naziv!', toastSettings);
    } else if (
      !values?.niz_stavke[0]?.cijena_bez_pdv &&
      values.tip_racuna !== 'knjizno'
    ) {
      toast.error('Stavka mora imati cijenu!', toastSettings);
    } else if (!values) {
      toast.error('Stavka mora imati jedinicu mjere!', toastSettings);
    } else if (!values?.niz_stavke[0]?.porez_id) {
      toast.error('Stavka mora imati porez!', toastSettings);
    } else if (!values?.niz_stavke[0]?.kolicina) {
      toast.error('Stavka mora imati količinu!', toastSettings);
    } else if (
    !values.niz_stavke[0].iznos_povrata_bez_pdv && ( getUkupnaCijenaBezPdv(values.niz_stavke[0]) <= 0 ||
      getUkupnaCijenaSaPdv(values.niz_stavke[0]) <= 0 || Number(values.niz_stavke[0].popust).toFixed(2) ===  Number(values.niz_stavke[0].cijena_bez_pdv).toFixed(2))
    ) {
      toast.error('Cijena stavke ne može biti negativna ili 0!', toastSettings);
    } else if (
      getUkupnaNovaCijenaBezPdv(values.niz_stavke[0]) <= 0 ||
      getUkupnaNovaCijenaSaPdv(values.niz_stavke[0]) <= 0
    ) {
      toast.error('Nova cijena stavke ne može biti negativna!', toastSettings);
    } else {
      const odabranaUsluga = values.niz_stavke[0].odabranaUsluga;
      if (odabranaUsluga?.roba || odabranaUsluga?.ukupna_cijena) {
        odabranaUsluga.popust = parseFloat(values.niz_stavke[0].popust);
        odabranaUsluga.ukupna_cijena = values.niz_stavke[0].ukupna_cijena ?? 0;
        odabranaUsluga.porez = {
          naziv: `${parseInt(values.niz_stavke[0].porez * 100)}%`,
          id: values.niz_stavke[0].porez_id,
          stopa: values.niz_stavke[0].porez,
        };
        odabranaUsluga.porez_id = values.niz_stavke[0].porez_id;
        if (odabranaUsluga.roba) {
          if (values.niz_stavke[0].tip_popusta === 'iznos') {
            odabranaUsluga.cijena_bez_pdv_popust =
              parseFloat(odabranaUsluga.roba?.cijene_roba[0].cijena_bez_pdv) -
              parseFloat(values.niz_stavke[0].popust);

            odabranaUsluga.cijena_sa_pdv_popust =
              parseFloat(odabranaUsluga.roba?.cijene_roba[0].ukupna_cijena) -
              parseFloat(values.niz_stavke[0].popust);
          }

          if (values.niz_stavke[0].tip_popusta === 'procenat') {
            odabranaUsluga.cijena_bez_pdv_popust =
              parseFloat(odabranaUsluga.roba?.cijene_roba[0].cijena_bez_pdv) -
              parseFloat(odabranaUsluga.roba?.cijene_roba[0].cijena_bez_pdv) *
                (parseFloat(values.niz_stavke[0].popust) / 100);

            odabranaUsluga.cijena_sa_pdv_popust =
              parseFloat(odabranaUsluga.roba?.cijene_roba[0].ukupna_cijena) -
              parseFloat(odabranaUsluga.roba?.cijene_roba[0].ukupna_cijena) *
                (parseFloat(values.niz_stavke[0].popust) / 100);
          }
        } else {
          if (values.niz_stavke[0].tip_popusta === 'iznos_popusta_bez_pdv') {
            odabranaUsluga.cijena_bez_pdv_popust =
              parseFloat(odabranaUsluga.cijena_bez_pdv) -
              parseFloat(
                values.niz_stavke[0].popust ? values.niz_stavke[0].popust : 0
              );

            odabranaUsluga.cijena_sa_pdv_popust =
              parseFloat(odabranaUsluga.cijena_bez_pdv_popust) *
              parseFloat(odabranaUsluga.porez);
          }

          if (values.niz_stavke[0].tip_popusta === 'iznos_popusta_sa_pdv') {
            odabranaUsluga.cijena_sa_pdv_popust =
              parseFloat(odabranaUsluga.ukupna_cijena) -
              parseFloat(
                values.niz_stavke[0].popust ? values.niz_stavke[0].popust : 0
              );

            odabranaUsluga.cijena_bez_pdv_popust =
            (parseFloat(odabranaUsluga.ukupna_cijena) - parseFloat(odabranaUsluga.popust)) / (parseFloat(odabranaUsluga.porez.stopa) + 1)

          }

          if (values.niz_stavke[0].tip_popusta === 'procenat') {
            odabranaUsluga.cijena_bez_pdv_popust =
              parseFloat(odabranaUsluga.cijena_bez_pdv) -
              parseFloat(odabranaUsluga?.cijena_bez_pdv) *
                (parseFloat(
                  values.niz_stavke[0].popust ? values.niz_stavke[0].popust : 0
                ) /
                  100);

            odabranaUsluga.cijena_sa_pdv_popust =
              parseFloat(odabranaUsluga.ukupna_cijena) -
              parseFloat(odabranaUsluga.ukupna_cijena) *
                (parseFloat(
                  values.niz_stavke[0].popust ? values.niz_stavke[0].popust : 0
                ) /
                  100);
          }
        }
        odabranaUsluga.kolicina = values.niz_stavke[0]?.kolicina;
        if (
          values.niz_stavke[0]?.nova_cijena_bez_pdv &&
          values.niz_stavke[0]?.nova_ukupna_cijena &&
          values.niz_stavke[0]?.novi_porez_id
        ) {
          odabranaUsluga.nova_cijena_bez_pdv =
            values.niz_stavke[0]?.nova_cijena_bez_pdv;
          odabranaUsluga.nova_ukupna_cijena =
            values.niz_stavke[0]?.nova_ukupna_cijena;
          odabranaUsluga.marza = values.niz_stavke[0]?.marza ?? 0;
          odabranaUsluga.novi_porez_id = values.niz_stavke[0]?.novi_porez_id;
          odabranaUsluga.novi_porez = values.niz_stavke[0]?.novi_porez;
          odabranaUsluga.novi_porez_naziv = values.niz_stavke[0]?.novi_porez_naziv;

          odabranaUsluga.izmijeni_stavku = azurirajStavku;
          odabranaUsluga.zavisni_troskovi =
            values.niz_stavke[0]?.zavisni_troskovi;
          odabranaUsluga.nabavna_cijena = values.niz_stavke[0]?.nabavna_cijena;
          odabranaUsluga.novi_pdv = values.niz_stavke[0]?.novi_pdv;
        }

        odabranaUsluga.tip_popusta = values.niz_stavke[0]?.tip_popusta;

        odabranaUsluga.id = makeId(20);
        odabranaUsluga.iznos_povrata_bez_pdv = values.niz_stavke[0]
          ?.iznos_povrata_bez_pdv
          ? values.niz_stavke[0]?.iznos_povrata_bez_pdv
          : 0;
        odabranaUsluga.iznos_povrata_sa_pdv = values.niz_stavke[0]
          ?.iznos_povrata_sa_pdv
          ? values.niz_stavke[0]?.iznos_povrata_sa_pdv
          : 0;
        odabranaUsluga.opis = values?.niz_stavke[0]?.opis;
        odabranaUsluga.jedinica_mjere_id =
          values?.niz_stavke[0]?.jedinica_mjere_id;
        odabranaUsluga.jedinica_mjere = values?.niz_stavke[0]?.jedinica_mjere;
        values.stavke.push(odabranaUsluga);
      } else {
        if (
          values?.niz_stavke[0]?.tip_popusta === 'iznos_popusta_bez_pdv' &&
          values?.niz_stavke[0]?.popust
        ) {
          values.niz_stavke[0].cijena_bez_pdv_popust -=
            values.niz_stavke[0].popust;

          values.niz_stavke[0].cijena_sa_pdv_popust =
            values.niz_stavke[0].cijena_bez_pdv_popust *
            (values.niz_stavke[0].porez + 1);
        }

        if (
          values?.niz_stavke[0]?.tip_popusta === 'iznos_popusta_sa_pdv' &&
          values?.niz_stavke[0]?.popust
        ) {
          values.niz_stavke[0].cijena_sa_pdv_popust -=
            values.niz_stavke[0].popust;

          values.niz_stavke[0].cijena_bez_pdv_popust =
            values.niz_stavke[0].cijena_sa_pdv_popust /
            (values.niz_stavke[0].porez + 1);
        }

        if (
          values?.niz_stavke[0]?.tip_popusta === 'procenat' &&
          values?.niz_stavke[0]?.popust
        ) {
          const popust_bez_pdv_iznos =
            values.niz_stavke[0].cijena_bez_pdv_popust *
            (values.niz_stavke[0].popust / 100);

          const popust_sa_pdv_iznos =
            values.niz_stavke[0].cijena_sa_pdv_popust *
            (values.niz_stavke[0].popust / 100);

          values.niz_stavke[0].cijena_bez_pdv_popust -=
            parseFloat(popust_bez_pdv_iznos);
          values.niz_stavke[0].cijena_sa_pdv_popust -=
            parseFloat(popust_sa_pdv_iznos);
        }
let stavkaTemp = {
  naziv: values.niz_stavke[0].naziv,
  opis: values.niz_stavke[0].opis,
  popust: values.niz_stavke[0].popust,
  cijena_bez_pdv: values.niz_stavke[0].cijena_bez_pdv !== "" ? values.niz_stavke[0].cijena_bez_pdv : 0,
  cijena_bez_pdv_popust: values.niz_stavke[0].cijena_bez_pdv_popust
    ? values.niz_stavke[0].cijena_bez_pdv_popust
    : 0,
  cijena_sa_pdv_popust: values.niz_stavke[0].cijena_sa_pdv_popust !== "" ? values.niz_stavke[0].cijena_sa_pdv_popust : 0,
  kolicina: values.niz_stavke[0].kolicina,
  ukupna_cijena: values.niz_stavke[0].ukupna_cijena !== "" ? values.niz_stavke[0].ukupna_cijena : 0,
  porez_id: values.niz_stavke[0].porez_id,
  jedinica_mjere_id: values.niz_stavke[0].jedinica_mjere_id,
  jedinica_mjere: values.niz_stavke[0].jedinica_mjere,
  tip_popusta: values.niz_stavke[0].tip_popusta,

  usluga_id: 1,
  id: makeId(20),
  porez: {
    naziv: `${parseInt(values.niz_stavke[0].porez * 100)}%`,
    id: values.niz_stavke[0].porez_id,
    stopa: values.niz_stavke[0].porez,
  },
  iznos_povrata_bez_pdv: values?.niz_stavke[0]?.iznos_povrata_bez_pdv
    ? values?.niz_stavke[0]?.iznos_povrata_bez_pdv
    : 0,
  iznos_povrata_sa_pdv: values?.niz_stavke[0]?.iznos_povrata_sa_pdv
    ? values?.niz_stavke[0]?.iznos_povrata_sa_pdv
    : 0,
}

 if (
          values.niz_stavke[0].nova_cijena_bez_pdv &&
          values.niz_stavke[0].novi_porez_id &&
          values.niz_stavke[0].nova_ukupna_cijena
        ) {
        stavkaTemp ={
          ...stavkaTemp,
          
            marza: values.niz_stavke[0].marza
              ? values.niz_stavke[0].marza
              : 0,
            nova_cijena_bez_pdv: values.niz_stavke[0].nova_cijena_bez_pdv
              ? values.niz_stavke[0].nova_cijena_bez_pdv
              : null,
            nova_ukupna_cijena: values.niz_stavke[0].nova_ukupna_cijena
              ? values.niz_stavke[0].nova_ukupna_cijena
              : null,
            novi_porez_id: values.niz_stavke[0].novi_porez_id
              ? values.niz_stavke[0].novi_porez_id
              : null,
            izmijeni_stavku: azurirajStavku,
            zavisni_troskovi: values.niz_stavke[0].zavisni_troskovi,
            nabavna_cijena: values.niz_stavke[0].nabavna_cijena,
            novi_pdv: values.niz_stavke[0]?.novi_pdv
          }
        
        }
        values.stavke.push(stavkaTemp);

       
      }

      if (pathName.includes('/ulazni-racuni')) {
        if (
          azurirajStavku &&
          values.niz_stavke[0]?.roba_id
        ) {
          dispatch(
            updateUsluga({
              naziv: odabranaUsluga.naziv,
              opis: odabranaUsluga.opis,
              cijena_bez_pdv:
                odabranaUsluga && odabranaUsluga.nova_cijena_bez_pdv
                  ? odabranaUsluga.nova_cijena_bez_pdv
                  : odabranaUsluga.cijena_bez_pdv,
              ukupna_cijena:
                odabranaUsluga && odabranaUsluga.nova_ukupna_cijena
                  ? odabranaUsluga.nova_ukupna_cijena
                  : odabranaUsluga.ukupna_cijena ?? 0,
              jedinica_mjere_id: odabranaUsluga.jedinica_mjere_id,
              porez_id:
                odabranaUsluga && odabranaUsluga.novi_porez_id
                  ? odabranaUsluga.novi_porez_id
                  : odabranaUsluga.porez_id,
              status: true,
              grupa_id: 1,
              id: values.niz_stavke[0]?.roba_id,
            })
          );
        }


        if(azurirajStavku && !values.niz_stavke[0]?.roba_id){
          dispatch(
            storeUsluga({
              naziv: values.niz_stavke[0].naziv,
              opis: values.niz_stavke[0].opis,
              cijena_bez_pdv: values.niz_stavke[0].nova_cijena_bez_pdv ? values.niz_stavke[0].nova_cijena_bez_pdv : values.niz_stavke[0].cijena_bez_pdv,
              ukupna_cijena: values.niz_stavke[0].nova_ukupna_cijena ? values.niz_stavke[0].nova_ukupna_cijena : values.niz_stavke[0].ukupna_cijena ?? 0,
              jedinica_mjere_id: values.niz_stavke[0].jedinica_mjere_id,
              porez_id:values.niz_stavke[0].novi_porez_id ? values.niz_stavke[0].novi_porez_id :  values.niz_stavke[0].porez_id,
              status: true,
              grupa_id: 1,
            })
          );
          
        }
      } else {
        if (checkbox) {
          if (isStavkaSetManually) {
            dispatch(
              storeUsluga({
                naziv: values.niz_stavke[0].naziv,
                opis: values.niz_stavke[0].opis,
                cijena_bez_pdv: values.niz_stavke[0].cijena_bez_pdv,
                ukupna_cijena: values.niz_stavke[0].ukupna_cijena ?? 0,
                jedinica_mjere_id: values.niz_stavke[0].jedinica_mjere_id,
                porez_id: values.niz_stavke[0].porez_id,
                status: true,
                grupa_id: 1,
              })
            );
          } else {
            dispatch(
              updateUsluga({
                naziv: values.niz_stavke[0].naziv,
                opis: values.niz_stavke[0].opis,
                cijena_bez_pdv: values.niz_stavke[0].cijena_bez_pdv,
                ukupna_cijena: values.niz_stavke[0].ukupna_cijena ?? 0,
                jedinica_mjere_id: values.niz_stavke[0].jedinica_mjere_id,
                porez_id: values.niz_stavke[0].porez_id,
                status: true,
                grupa_id: 1,
                id: values.niz_stavke[0]?.roba_id,
              })
            );
          }
        }
      }

      clearAllValues();
      setShow(false);
      setFieldValue(`niz_stavke[0].edit`, false);
      setIsEditingStavka(false)
    }
  };

  const setIznosPovrata = (value, type, values) => {
    if (type === 'bez_pdv') {
      setFieldValue('niz_stavke[0].iznos_povrata_bez_pdv', +value);
      setFieldValue(
        `niz_stavke[0].iznos_povrata_sa_pdv`,
        +value * (1 + +values.porez)
      );
    }

    if (type === 'sa_pdv') {
      setFieldValue('niz_stavke[0].iznos_povrata_sa_pdv', +value);
      setFieldValue(
        `niz_stavke[0].iznos_povrata_bez_pdv`,
        +value / (1 + +values.porez)
      );
    }
  };

  function renderPopustPolja() {
    if (values.tip_racuna === 'avans') {
      return null;
    }

    if (values.tip_racuna === 'knjizno') {
      return (
        <>
          <div className="form__group">
            <label htmlFor="" className="form__label bm-show">
              Bez PDV
            </label>
            <input
              name={`niz_stavke[0].iznos_povrata_bez_pdv`}
              type="number"
              className="form__input"
              step=".01"
              placeholder="Bez PDV"
              title="Iznos povrata bez PDV-a"
              value={values?.niz_stavke[0]?.iznos_povrata_bez_pdv ?? ''}
              min={0}
              onKeyDown={handleKeyDownWithValidation}
              onChange={(event) => {
                setIznosPovrata(
                  event.target.value,
                  'bez_pdv',
                  values.niz_stavke[0]
                );
              }}
            />
          </div>
          <div className="form__group">
            <label htmlFor="" className="form__label bm-show">
              Sa pdv
            </label>
            <input
              name={`niz_stavke[0].iznos_povrata_sa_pdv`}
              type="number"
              className="form__input"
              step=".01"
              placeholder="Sa PDV"
              title="Iznos povrata sa PDV-om"
              min={0}
              onKeyDown={handleKeyDownWithValidation}
              value={values?.niz_stavke[0]?.iznos_povrata_sa_pdv ?? ''}
              onChange={(event) => {
                setIznosPovrata(
                  event.target.value,
                  'sa_pdv',
                  values.niz_stavke[0]
                );
              }}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="form__group">
          <label htmlFor="" className="form__label bm-show">
            Tip popusta
          </label>
          <DropDownStaticStavka
            disabled={
              values.niz_stavke[0] && values.niz_stavke[0].naziv ? false : true
            }
            name={`niz_stavke[0].tip_popusta`}
            onKeyDown={handleKeyDown}
            defaultValue={
              values.niz_stavke[0]?.tip_popusta_object
                ? values.niz_stavke[0].tip_popusta_object
                : ''
            }
            options={TIPOVI_POPUSTA}
          />
        </div>
        <div className="form__group">
          <label htmlFor="" className="form__label bm-show">
            Popust
          </label>
          <input
            name={`niz_stavke[0].popust`}
            type="number"
            min={0}
            onKeyDown={handleKeyDownWithValidation}
            className="form__input"
            step=".01"
            placeholder="Popust"
            value={values?.niz_stavke[0]?.popust ?? ''}
            onChange={(event) => {
              setFieldValue(`niz_stavke[0].popust`, event.target.value);
            }}
          />
        </div>
      </>
    );
  }
  
  return (
    <>
      <div className="main-content__box--body mb-20 mt-4">
        <div className="container">
          <div className="section-box">
            <div className="section-box__left">
              <div className="section-box__left--top">
                <div className="form-group mb-0">
                  <StavkeDropdown
                    key="1"
                    id="1"
                    name={`niz_stavke[0].odabranaUsluga`}
                    className="form__input"
                    onChangeExtra={(option, type) =>
                    { 
                      setSelectedType(option, type)
                      setTimeout(() => {
                        setNabanvanCijena('')
                      }, 100);
                    }

                    }
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
              <div className="section-box__left--bottom">
                <div className="form-group mt-15">
                  <Field name="niz_stavke[0].opis">
                    {({ field, form }) => (
                      <textarea
                        {...field} // automatically attaches 'name', 'value', 'onChange', 'onBlur'
                        id="niz_stavke[0].opis"
                        cols="30"
                        rows="8"
                        className="form__textarea df"
                        placeholder="Opis stavke"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          field.onChange(e); // Important: this maintains Formik's internal state update
                          setIsStavkaChanged(true);
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className="section-box__right">
              <div className="section-box__right--top-wrap">
                <div className="el">
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      Bez PDV11
                    </label>
                    <Field
                      type="text"
                      name={`niz_stavke[0].cijena_bez_pdv`}
                      className="form__input"
                      value={values?.niz_stavke[0]?.cijena_bez_pdv ?? ''}
                      placeholder="Bez PDV"
                      title="Jedinična cijena bez PDV-a"
                      onKeyDown={handleKeyDown}
                      readOnly={!show}
                      onChange={(event) => {
                        setLastClickedInput('bez_pdv');
                        setManualValues(values.niz_stavke[0], event, 'bez_pdv');
                        setIsStavkaChanged(true);
                      }}
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      Sa PDV
                    </label>
                    {/* TODO: izgaseno edit Cijena dok se ne rijesi slanje izmijenjene cijene */}
                    <input
                      name={`niz_stavke[0].ukupna_cijena`}
                      type="number"
                      value={values.niz_stavke[0]?.ukupna_cijena ?? ''}
                      readOnly={!show}
                      className="form__input"
                      placeholder="Sa PDV"
                      title="Jedinična cijena sa PDV-om"
                      onKeyDown={handleKeyDownWithValidation}
                      onChange={(event) => {
                        setLastClickedInput('sa_pdv');
                        setManualValues(values.niz_stavke[0], event, 'sa_pdv');
                      }}
                    />
                  </div>
                </div>
                <div className="el">
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      Jedinica mjere
                    </label>
                    <DropDown
                      onKeyDown={handleKeyDown}
                      name={`niz_stavke[0].jedinica_mjere_id`}
                      isDisabled={!show || values.tip_racuna === 'avans'}
                      onChangeExtra={(option) => {
                        setManualJedinicaMjere(option);
                        setIsStavkaChanged(true);
                      }}
                      defaultValue={
                        values.niz_stavke[0]?.jedinica_mjere?.naziv
                          ? {
                              value: values.niz_stavke[0]?.jedinica_mjere?.id,
                              label:
                                values.niz_stavke[0]?.jedinica_mjere?.naziv,
                            }
                          : 'Select...'
                      }
                      loadOptions={
                        jediniceMjereService.getJediniceMjereDropdown
                      }
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      Količina
                    </label>
                    <Field
                      onKeyDown={handleKeyDown}
                      name="niz_stavke[0].kolicina"
                      type="number"
                      component={PositiveNumberField}
                      min={0}
                      className="form__input"
                      step="1"
                      defaultValue={1}
                    />
                  </div>
                </div>
                <div className="el">
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      Stopa PDV-a
                    </label>
                    <label
                      htmlFor="stavke.0.jedinica_mjere_id"
                      className="form__label"
                    />
                    <DropDownStaticStavka
                      onKeyDown={handleKeyDown}
                      name={`niz_stavke[0].porez`}
                      disabled={
                        !show ||
                        values.tip_racuna === 'avans' ||
                        !authPreduzece.pdv_obveznik
                      }
                      options={TIPOVI_POREZA}
                      defaultValue={
                        values.niz_stavke[0]?.porez_id
                          ? TIPOVI_POREZA.find((porez) => porez.id === values.niz_stavke[0]?.porez_id)
                          : authPreduzece.pdv_obveznik ? TIPOVI_POREZA[0] : TIPOVI_POREZA[3]     
                      }
                      onChangeExtra={(option) => {
                        setManualPdv(values.niz_stavke[0], option);
                        setIsStavkaChanged(true);
                      }}
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="" className="form__label bm-show">
                      PDV
                    </label>
                    <input
                      type="text"
                      name={`niz_stavke[0].ukupan_pdv`}
                      onKeyDown={handleKeyDown}
                      className="form__input"
                      placeholder="PDV iznos"
                      value={
                        values.niz_stavke[0]?.cijena_bez_pdv
                          ? values.niz_stavke[0]?.cijena_bez_pdv *
                            values.niz_stavke[0]?.porez
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="el">{renderPopustPolja()}</div>
              </div>
              <div className="section-box__right--bottom-wrap">
                <div className="price">
                  <div className="form__box pb-110">
                    <div>
                      <p className="txt-light">Ukupan iznos PDV-a</p>
                    </div>
                    <div className="heading-secondary ">
                      {values?.niz_stavke[0]?.popust &&
                      formatirajCijenu(
                        getUkupanPdvBezPopusta(values.niz_stavke[0])
                      ) !==
                        formatirajCijenu(getUkupanPdv(values.niz_stavke[0])) && 
                          getUkupanPdvBezPopusta(values.niz_stavke[0])
                        > 0 ? (
                        <del style={{ marginRight: '10px' }}>
                          {formatirajCijenu(
                            getUkupanPdvBezPopusta(values.niz_stavke[0])
                          )}
                        </del>
                      ) : (
                        ''
                      )}
                      {formatirajCijenu(getUkupanPdv(values.niz_stavke[0]))}
                    </div>
                  </div>
                </div>
                <div className="price">
                  <div className="form__box pb-110">
                    <div>
                      <p className="txt-light">Ukupna cijena bez PDV-a</p>
                    </div>
                    <div className="heading-secondary mb-0" name="tekst">
                      {values?.niz_stavke[0]?.popust &&
                      formatirajCijenu(
                        getUkupnaCijenaBezPdvBezPopusta(values.niz_stavke[0])
                      ) !==
                        formatirajCijenu(
                          getUkupnaCijenaBezPdv(values.niz_stavke[0])
                        ) && 
                        getUkupnaCijenaBezPdvBezPopusta(values.niz_stavke[0])
                      > 0 ? (
                        <del style={{ marginRight: '10px' }}>
                          {formatirajCijenu(
                            getUkupnaCijenaBezPdvBezPopusta(
                              values.niz_stavke[0]
                            )
                          )}
                        </del>
                      ) : (
                        ''
                      )}
                      {formatirajCijenu(
                        getUkupnaCijenaBezPdv(values.niz_stavke[0])
                      )}
                    </div>
                  </div>
                </div>
                <div className="price">
                  <div className="form__box pb-110">
                    <div>
                      <p className="txt-light">Ukupna cijena sa PDV-om</p>
                    </div>
                    <div className="heading-secondary mb-0">
                      {values?.niz_stavke[0]?.popust &&
                      formatirajCijenu(
                        getUkupnaCijenaSaPdvBezPopusta(values.niz_stavke[0])
                      ) !==
                        formatirajCijenu(
                          getUkupnaCijenaSaPdv(values.niz_stavke[0])
                        ) && 
                        getUkupnaCijenaSaPdvBezPopusta(values.niz_stavke[0])
                      > 0 ? (
                        <del style={{ marginRight: '10px' }}>
                          {formatirajCijenu(
                            getUkupnaCijenaSaPdvBezPopusta(values.niz_stavke[0])
                          )}
                        </del>
                      ) : (
                        ''
                      )}
                      <del style={{ marginRight: '10px' }}></del>
                      {formatirajCijenu(
                        getUkupnaCijenaSaPdv(values.niz_stavke[0])
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ display: checkIfSacuvajStavkuShowed() ? '' : 'none' }}
            >
              <input
                type="checkbox"
                id="checkbox"
                name="checkbox"
                checked={checkbox} // Ensure this line is here
                onChange={() => setCheckbox(!checkbox)}
                onKeyDown={handleKeyDown}
              />
              <label htmlFor="checkbox" className="ml-2">
                {isStavkaSetManually
                  ? 'Dodaj stavku u listu svih stavki'
                  : 'Sačuvaj izmjene stavke u listu svih stavki'}
              </label>
            </div>
          </div>
        </div>
      </div>
      {pathName.includes('/ulazni-racuni') && (
        <>
          <div className="main-content__box--header">
            <div className="row">
              <div className="col-xl-4 col-md-4 col-12">
                <h4 className="heading-quaternary">
                  Nabavna cijena / Ukupna cijena
                </h4>
              </div>
              <div className="col-xl-2 col-md-2 tabp-none">
                <h4 className="heading-quaternary">trošak / PDV</h4>
              </div>
              <div className="col-xl-2 col-md-2 tabp-none">
                <h4 className="heading-quaternary">Marža(%)</h4>
              </div>
              <div className="col-xl-2 col-md-2 tabp-none">
                <h4 className="heading-quaternary">Cijena bez pdv</h4>
              </div>
              <div className="col-xl-2 col-md-2 tabp-none">
                <h4 className="heading-quaternary txt-right">PDV iznos</h4>
              </div>
            </div>
          </div>
          <BezgotovinskiNoveCijeneStavkiForm
            onEnterPress={handleKeyDown}
            azurirajStavku={azurirajStavku}
            setAzurirajStavku={handleSetAzurirajStavku}
            authPreduzece={authPreduzece}
            odabranaStavka={odabranaStavka}
            nabanvanCijena={nabanvanCijena}
            setNabanvanCijena={setNabanvanCijena}
            clearValue={clearValue}
          />
        </>
      )}
      <hr className="hr-main" />
      <div
        title="Dodaj stavku na račun (Enter)"
        onClick={() => dodajStavku(values)}
        className="main-content__box--footer nova-stavka__hover"
      >
        <span className="link">+ Dodaj stavku na račun</span>
      </div>
    </>
  );
};

export default BezgotovinskiStavkeForm;
