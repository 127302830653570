import React from 'react';
import ReactDOM from "react-dom";
import { BASE_URL } from '../../../config';
import Modal from '../../shared/forms/Modal';

const BezgotovinskiFiles = ({ files }) => {
  const url = BASE_URL.slice(0, -4)

  const previewFile = (file) => {
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

    const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);

    ReactDOM.render(
        <Modal
            title=""
            message=""
            url={`${url}/storage/${file}`}
            onConfirm={confirm}
            onCancel={cancel}
            confirmText="Zatvori"
            newTabButton={`${url}/storage/${file}`}
        />
        , node
    );
  }

  return (
    <>
    <h2 className="heading-secondary">Dokumenti</h2>
     <div className="main-content__box mt-3">
        <div className="content" style={{ width: "100%" }}>
            <div className="table-overflow-wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="cd">
                                <p className="heading-quaternary txt-left">
                                    Fajlovi
                                </p>
                            </th>
                            <th className="cd">
                                <p className="heading-quaternary txt-left">
                                    Otvori u novom tabu
                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {files?.map((file) => {
                            return (
                                <tr>
                                    <td className="txt-left" onClick={() => previewFile(file.dokument)} style={{cursor: "pointer"}}>
                                       {file.naziv}
                                    </td>
                                    <td className="txt-left">
                                        <a href={`${url}/storage/${file.dokument}`} target="_blank">
                                            Otvori
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
     </div>
    </>
  );
};

export default BezgotovinskiFiles;
