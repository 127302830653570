import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import ReactDatePicker from 'react-datepicker';

import {
  POSALJI_PODSJETNIK,
  VRIJEME_PODSJETNIKA,
  DAN_SLANJA_NEDELJA,
} from '../../../constants/racuni';
import DropDownStatic from '../../shared/forms/DropDownStatic';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authPreduzeceSelector } from '../../../store/selectors/PreduzecaSelector';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  formatirajCijenuBez0,
  getUkupnaCijenaSaPdv,
} from '../../../helpers/racuni';
import Modal from '../../shared/forms/Modal';
import ReactDOM from 'react-dom';

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const BezgotovinskiStatusPodsjetnici = (props = null) => {
  const { values, setFieldValue } = useFormikContext();
  const { params } = useRouteMatch();
  const pathName = window.location.pathname;
  const [iznos, setIznos] = useState('');
  const [datum, setDatum] = useState(new Date());
  const [opis, setOpis] = useState('');
  const [tekstPodsjetnik, setTekstPodsjetnik] = useState(
    values &&
      values.podsjetnici &&
      values.podsjetnici.length > 0 &&
      values?.podsjetnici[0]?.vrijeme_slanja
      ? values?.podsjetnici[0]?.vrijeme_slanja
      : values.vrijeme_slanja
      ? values.vrijeme_slanja
      : 'bez_slanja'
  );
  const [vrijemePodsjetnik, setVrijemePodsjetnik] = useState(
    values && values.tip_slanja ? values.tip_slanja : ''
  );
  const [tekst, setTekst] = useState(
    values &&
      values.podsjetnici &&
      values.podsjetnici.length > 0 &&
      values?.podsjetnici[0]?.tekst
      ? values?.podsjetnici[0]?.tekst
      : values.tekst
      ? values.tekst
      : ''
  );
  const authPreduzece = useSelector(authPreduzeceSelector());
  const DAN_SLANJA_MJESEC = [];
  const VRIJEME = [];

  useEffect(() => {
    if (props?.odabraniRacun?.tip_slanja) {
      setVrijemePodsjetnik(props.odabraniRacun.tip_slanja);
    }

    if (props?.odabraniRacun?.podsjetnici) {
      setTekstPodsjetnik(props?.odabraniRacun?.podsjetnici[0]?.vrijeme_slanja);
      setTekst(
        props?.odabraniRacun?.podsjetnici[0]
          ? props?.odabraniRacun?.podsjetnici[0].tekst
          : ''
      );
    }
  }, [props.odabraniRacun]);

  for (let i = 0; i < 24; i++) {
    VRIJEME.push({ value: i, label: i < 10 ? `0${i}:00` : `${i}:00` });
  }

  if (vrijemePodsjetnik === 'mjesecni') {
    for (let i = 1; i < 32; i++) {
      DAN_SLANJA_MJESEC.push({ value: i, label: `Svakog ${i}. u mjesecu` });
    }
  }

  const setVrijemeSlanjaPodsjetnik = (option) => {
    if (authPreduzece.najjaci_paket === 1) {
      toast.error(
        'Ova usluga nije dostupna korisnicima osnovnog paketa!',
        toastSettings
      );
      setFieldValue('vrijeme_slanja', 'bez_slanja');
      return;
    }
    setTekstPodsjetnik(option.value);
  };

  const getVrijemeSlanja = (vrijemeSlanja) => {
    switch (vrijemeSlanja) {
      case '2_dana_prije_isteka_roka':
        return { label: 'Pošalji 2 dana prije roka plaćanja' };
      case '5_dana_prije_isteka_roka':
        return { label: 'Pošalji 5 dana prije roka plaćanja' };
      case '10_dana_prije_isteka_roka':
        return { label: 'Pošalji 10 dana prije roka plaćanja' };
      case 'dan_isteka_roka':
        return { label: 'Pošalji na dan isteka roka plaćanja' };
      default:
    }
  };

  // automatsko slanje
  const getDanUSedmici = (dan) => {
    switch (dan) {
      case '1':
        return { label: 'Svakog ponedjeljka' };
      case '2':
        return { label: 'Svakog utorka' };
      case '3':
        return { label: 'Svake srijede' };
      case '4':
        return { label: 'Svakog četvrtka' };
      case '5':
        return { label: 'Svakog petka' };
      default:
    }
  };

  const getTipSlanja = (tip) => {
    switch (tip) {
      case 'dnevni':
        return 'Svaki dan';
      case 'sedmicni':
        return 'Svake nedelje';
      case 'mjesecni':
        return 'Svakog mjeseca';
      case 'godisnji':
        return 'Svake godine';
      default:
    }
  };

  const getDefaultValueDanUSedmici = (odabraniRacun) => {
    if (
      odabraniRacun?.datum_slanja &&
      odabraniRacun?.tip_slanja === 'sedmicni'
    ) {
      return getDanUSedmici(odabraniRacun.datum_slanja);
    }

    return DAN_SLANJA_NEDELJA[0];
  };

  const getDefaultValueDanUMjesecu = (odabraniRacun) => {
    if (
      odabraniRacun?.datum_slanja &&
      odabraniRacun?.tip_slanja === 'mjesecni'
    ) {
      return { label: `Svakog ${odabraniRacun.datum_slanja}. u mjesecu` };
    }

    return DAN_SLANJA_MJESEC[0];
  };

  const getDefaultValueDatum = (odabraniRacun) => {
    if (
      odabraniRacun?.datum_slanja &&
      odabraniRacun?.tip_slanja === 'godisnji'
    ) {
      return new Date(odabraniRacun.datum_slanja);
    }

    return new Date();
  };

  const setTipSlanja = (option) => {
    setFieldValue('datum_slanja', null);
    setFieldValue('tip_slanja', option.value);
    setVrijemePodsjetnik(option.value);
  };

console.log(authPreduzece)

  const getStatusi = () => {
    if (
      pathName === '/predracuni/create' ||
      pathName === '/predracuni/edit/' + params.id
    ) {
      return (
        <div className="col-md-3">
          <div className="form__label">Status</div>
          <div className="form__group">
            <div className="form__radio-group">
              <input
                type="radio"
                className="form__radio-input"
                id="notPaid"
                value="nijeplacen"
                name="status"
                checked={values && values.status === 'kreiran'}
                onChange={() => setFieldValue('status', 'kreiran')}
                defaultChecked
              />
              <label htmlFor="notPaid" className="form__radio-label">
                <span className="form__radio-button"></span>
                Kreiran
              </label>
            </div>
            <div className="form__radio-group">
              <input
                type="radio"
                className="form__radio-input"
                id="uncollectible"
                value="nenaplativ"
                name="status"
                checked={values && values.status === 'poslat'}
                onChange={() => setFieldValue('status', 'poslat')}
              />
              <label htmlFor="uncollectible" className="form__radio-label">
                <span className="form__radio-button"></span>
                Poslat
              </label>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-3">
          <div className="form__label">Status</div>
          <div className="form__group">
            <div className="form__radio-group">
              <input
                type="radio"
                className="form__radio-input"
                id="paid"
                value="placen"
                name="status"
                checked={values && values.status === 'placen'}
                onChange={() => setFieldValue('status', 'placen')}
              />
              <label htmlFor="paid" className="form__radio-label">
                <span className="form__radio-button"></span>
                Plaćen
              </label>
            </div>
            {values.stavke.length > 0 &&
              (Number(ukupnoSaPdv) - Number(ukupnoiznosRacun)) > 0 && (
                <div className="form__radio-group">
                  <input
                    type="radio"
                    className="form__radio-input"
                    id="djelimicno_placen"
                    value="djelimicno_placen"
                    name="status"
                    checked={values && values.status === 'djelimicno_placen'}
                    onChange={() => {
                      if (authPreduzece.najjaci_paket === 1 || authPreduzece.najjaci_paket === null) {
                        showModalforStart();
                      } else {
                        setFieldValue('status', 'djelimicno_placen');
                      }
                    }}
                  />
                  <label
                    htmlFor="djelimicno_placen"
                    className="form__radio-label"
                  >
                    <span className="form__radio-button"></span>
                    Djelimično plaćen{' '}
                    <span
                      className="tag tag__neutral"
                      title="Opcija dostupna korisnicima Start paketa"
                    >
                      Start
                    </span>
                  </label>
                </div>
              )}
            <div className="form__radio-group">
              <input
                type="radio"
                className="form__radio-input"
                id="notPaid"
                value="nijeplacen"
                name="status"
                checked={values && values.status === 'nijeplacen'}
                onChange={() => setFieldValue('status', 'nijeplacen')}
                defaultChecked
              />
              <label htmlFor="notPaid" className="form__radio-label">
                <span className="form__radio-button"></span>
                Nije plaćen
              </label>
            </div>
            <div className="form__radio-group">
              <input
                type="radio"
                className="form__radio-input"
                id="uncollectible"
                value="nenaplativ"
                name="status"
                checked={values && values.status === 'nenaplativ'}
                onChange={() => setFieldValue('status', 'nenaplativ')}
              />
              <label htmlFor="uncollectible" className="form__radio-label">
                <span className="form__radio-button"></span>
                Nenaplativ
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderPodsjetnici = () => {
    if (
      pathName !== '/predracuni/create' &&
      pathName !== '/predracuni/edit/' + params.id &&
      values?.tip_racuna !== 'avans' &&
      values?.nacin_placanja !== 'ADVANCE' &&
      values?.tip_racuna !== 'knjizno' &&
      pathName !== '/ulazni-racuni/create' &&
      pathName !== '/ulazni-racuni/edit/' + params.id
    ) {
      return (
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h2 className="heading-secondary mb-0">
                  Podsjetnici za plaćanje
                </h2>
                <span
                  className="tag tag__neutral"
                  title="Opcija dostupna korisnicima Start paketa"
                >
                  Start
                </span>
                <p className="txt-light b-mob-mb-20">
                  Možete izabrati da se podsjetnici za plaćanje šalju kupcu
                  ukoliko račun nije označen kao plaćen.
                </p>
              </div>
              <div className="col-md-4">
                <div className="form__group">
                  <label className="form__label">Pošalji podsjetnik</label>
                  <DropDownStatic
                    name="vrijeme_slanja"
                    options={POSALJI_PODSJETNIK}
                    defaultValue={
                      props?.odabraniRacun?.podsjetnici
                        ? getVrijemeSlanja(
                            props?.odabraniRacun?.podsjetnici[0]?.vrijeme_slanja
                          )
                        : POSALJI_PODSJETNIK[0]
                    }
                    onChangeExtra={(option) =>
                      setVrijemeSlanjaPodsjetnik(option)
                    }
                  />
                </div>
              </div>
              <div className="col-md-4">
                {tekstPodsjetnik !== 'bez_slanja' ? (
                  <div className="form__group">
                    <label className="form__label">Tekst podsjetnika</label>
                    <textarea
                      name="tekst"
                      id=""
                      cols="30"
                      rows="6"
                      className="form__input"
                      defaultValue={tekst}
                      onBlur={(event) => {
                        setTekst(event.target.value);
                        setFieldValue('tekst', event.target.value);
                      }}
                    ></textarea>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <hr />
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h2 className="heading-secondary mb-0">
                  Automatizovano slanje periodičnih računa
                </h2>
                <span
                  className="tag tag__neutral"
                  title="Opcija dostupna korisnicima Start paketa"
                >
                  Start
                </span>
                <p className="txt-light b-mob-mb-20">
                  Možete izabrati da se novi račun sa gore navedenim stavkama
                  šalje kupcu svakog dana, nedjelje, mjeseca ili godine za
                  usluge koje se ponavljaju tako da ne morate ručno kreirati
                  svaki put novi račun
                </p>
              </div>
              <div className="col-xl-4">
                <div className="form__group">
                  <label className="form__label">
                    Kreiraj i pošalji novi račun
                  </label>

                  <DropDownStatic
                    name="tip_slanja"
                    options={VRIJEME_PODSJETNIKA}
                    onChangeExtra={(option) => setTipSlanja(option)}
                    defaultValue={{
                      label: props?.odabraniRacun?.tip_slanja
                        ? getTipSlanja(props?.odabraniRacun?.tip_slanja)
                        : 'Select...',
                    }}
                  />
                </div>
              </div>
              {vrijemePodsjetnik !== 'dnevni' && vrijemePodsjetnik ? (
                <div className="col-xl-4">
                  <div className="form-group mb-20">
                    <label className="form__label">Dan za slanje</label>
                    {vrijemePodsjetnik === 'sedmicni' ? (
                      <DropDownStatic
                        name="datum_slanja"
                        options={DAN_SLANJA_NEDELJA}
                        defaultValue={getDefaultValueDanUSedmici(
                          props.odabraniRacun
                        )}
                        onChangeExtra={(option) =>
                          setFieldValue('datum_slanja', option.value)
                        }
                      />
                    ) : null}

                    {vrijemePodsjetnik === 'mjesecni' ? (
                      <DropDownStatic
                        name="datum_slanja"
                        options={DAN_SLANJA_MJESEC}
                        defaultValue={getDefaultValueDanUMjesecu(
                          props.odabraniRacun
                        )}
                        onChangeExtra={(option) =>
                          setFieldValue('datum_slanja', option.value)
                        }
                      />
                    ) : null}

                    {vrijemePodsjetnik === 'godisnji' ? (
                      <ReactDatePicker
                        selected={
                          values.datum_slanja
                            ? new Date(values.datum_slanja)
                            : new Date()
                        }
                        onChange={(date) => setFieldValue('datum_slanja', date)}
                        className="form__input w-100"
                        placeholderText="Datum Slanja"
                        dateFormat="dd/MM/yyyy"
                      />
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="form__label">Vrijeme slanja</label>
                    <DropDownStatic
                      name="vrijeme"
                      options={VRIJEME}
                      defaultValue={
                        props?.odabraniRacun?.vrijeme
                          ? {
                              label:
                                props?.odabraniRacun?.vrijeme < 10
                                  ? `0${props?.odabraniRacun?.vrijeme}:00`
                                  : `${props?.odabraniRacun?.vrijeme}:00`,
                            }
                          : VRIJEME[0]
                      }
                      onChangeExtra={(option) =>
                        setFieldValue('vrijeme', option.value)
                      }
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label className="form__label">Važi do dana</label>
                    <ReactDatePicker
                      selected={
                        values.vazi_do ? new Date(values.vazi_do) : new Date()
                      }
                      onChange={(date) => setFieldValue('vazi_do', date)}
                      className="form__input w-100"
                      placeholderText="Važi do"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              ) : null}

              {vrijemePodsjetnik === 'dnevni' && vrijemePodsjetnik ? (
                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="form__label">Vrijeme slanja</label>
                    <DropDownStatic
                      name="vrijeme"
                      options={VRIJEME}
                      defaultValue={
                        props?.odabraniRacun?.vrijeme
                          ? {
                              label:
                                props?.odabraniRacun?.vrijeme < 10
                                  ? `0${props?.odabraniRacun?.vrijeme}:00`
                                  : `${props?.odabraniRacun?.vrijeme}:00`,
                            }
                          : VRIJEME[0]
                      }
                      onChangeExtra={(option) =>
                        setFieldValue('vrijeme', option.value)
                      }
                    />
                  </div>

                  <div className="form-group mt-4">
                    <label className="form__label">Važi do dana</label>
                    <ReactDatePicker
                      selected={
                        values.vazi_do ? new Date(values.vazi_do) : new Date()
                      }
                      onChange={(date) => setFieldValue('vazi_do', date)}
                      className="form__input w-100"
                      placeholderText="Važi do"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <hr />
        </>
      );
    }
  };

  let ukupnoSaPdv = 0;
  let ukupnoiznos = 0;

  let ukupnoiznosRacun = 0;

  if (
    values.uplate_racuna_djelimicne_racun &&
    values.uplate_racuna_djelimicne_racun.length > 0
  ) {
    ukupnoiznosRacun = Object.values(values.uplate_racuna_djelimicne_racun).reduce(
      (r, { datum, iznos }) => r + Number(iznos),
      0
    );
  }


  if (
    values.uplate_racuna_djelimicne_prikaz &&
    values.uplate_racuna_djelimicne_prikaz.length > 0
  ) {
    ukupnoiznos = Object.values(values.uplate_racuna_djelimicne_prikaz).reduce(
      (r, { datum, iznos }) => r + Number(iznos),
      0
    );
  }

  if (!values.ukupna_cijena_sa_pdv_popust) {
    if (values.tip_racuna === 'knjizno') {
      ukupnoSaPdv = Object.values(values.stavke)
        .reduce(
          (r, { cijena_sa_pdv_popust, kolicina, iznos_povrata_sa_pdv }) =>
            r + cijena_sa_pdv_popust * kolicina + iznos_povrata_sa_pdv,
          0
        )
    } else {
      ukupnoSaPdv = Object.values(values.stavke)
        .reduce((r, stavka) => r + getUkupnaCijenaSaPdv(stavka), 0)
    }
  } else {
    ukupnoSaPdv =
      values && values.ukupna_cijena_sa_pdv_popust
        ? Number(values.ukupna_cijena_sa_pdv_popust)
        : 0;
  }

  const renderStatusi = () => {
    if (values?.nacin_placanja === 'ADVANCE' && !values?.vise_nacina_placanja) {
      return;
    }

    if (values?.tip_racuna !== 'avans' && values?.tip_racuna !== 'knjizno') {
      return <>{getStatusi()}</>;
    }
  };


  const showModalforStart = () => {
    const node = document.getElementById('custom-prompt');

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

    const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);

    ReactDOM.render(
      <Modal
        title="Start Paket"
        message="            Ova funkcionalnost je dostupna samo korisnicima Start paketa"
        onConfirm={confirm}
        onCancel={cancel}
        confirmText="Zatvori"
      />,
      node
    );
  };

  const handleDodajNoviIznos = () => {
    if (!values.stavke.length) {
      toast.error(
        'Stavke nedostaju! Morate unijeti makar jednu stavku. Kada izaberete stavku pritisnite dugme "Dodaj stavku na račun".',
        toastSettings
      );
      return;
    }

    if(Number(iznos) === 0){
      toast.error(
        'Iznos mora biti veći od 0',
        toastSettings
      );
      return;
    }

    if (Number(iznos) > Number(ukupnoSaPdv - ukupnoiznos)) {
      toast.error(
        'Uneseni iznos je veći od iznosa koja je ostao za uplatu',
        toastSettings
      );
      return;
    }

    if (!values.uplate_racuna_djelimicne_prikaz) {
      values.uplate_racuna_djelimicne_prikaz = [];
    }
    values.uplate_racuna_djelimicne_prikaz = [
      ...values.uplate_racuna_djelimicne_prikaz,
      { iznos: iznos, datum_uplate: datum, opis: opis },
    ];
    values.uplate_racuna_djelimicne = [
      ...values.uplate_racuna_djelimicne,
      { iznos: iznos, datum_uplate: datum, opis: opis },
    ];
    setIznos('');
    setDatum(new Date());
    setOpis('');
    if (
      Number(iznos) === Number(ukupnoSaPdv) ||
      Number(ukupnoSaPdv - ukupnoiznos - iznos) <= 0
    ) {
      setFieldValue('status', 'placen');
    }
  };

  const renderDjelimicnoStavke = () => {
    // if(values.status !== "djelimicno_placen"){
    //   return;
    // }

    return (
      <div className="col-md-4">
        <h2 className="heading-secondary">Status</h2>
        <p className="txt-light b-mob-mb-20">Označite status računa</p>
        {/* <p className="txt-light">Označite status računa</p> */}
        {((values &&
          values.uplate_racuna_djelimicne_prikaz &&
          values.uplate_racuna_djelimicne_prikaz.length > 0 ) && (values.stavke.length > 0 &&
            (Number(ukupnoSaPdv) - Number(ukupnoiznosRacun)) > 0) )&& (
            <div className=" h-70 mt-50">
              {/* <div className="col-l txt-light"> */}
              <p className="mb-10 txt-light">Do sada uplaćeno</p>
              {values.uplate_racuna_djelimicne_prikaz.map((uplata, index) => (
                <div key={index} className="df jc-sb ai-start">
                  <div>
                    <p className="txt-light">
                      {moment(uplata.datum_uplate).format('DD. MMM YYYY.')}
                    </p>
                    <p className="mb-10 txt-light">{uplata.opis}</p>
                  </div>
                  <p>{formatirajCijenuBez0(uplata.iznos)}</p>
                </div>
              ))}
              <div className="df jc-sb ai-start mt-20">
                <p className="txt-light">Ostalo za uplatu: </p>
                <p>
                  {formatirajCijenuBez0(
                    values.stavke.length > 0
                      ? Number(ukupnoSaPdv) - Number(ukupnoiznos) > 0 ? Number(ukupnoSaPdv) - Number(ukupnoiznos)  : 0
                      : 0
                  )}
                </p>
              </div>
            </div>
          )}
      </div>
    );
  };

  const renderDjelimicnoPlacanje = () => {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form__group">
            <label htmlFor="" className="form__label">
              Iznos uplate
            </label>
            <input
              // name={`niz_stavke[0].iznos_povrata_bez_pdv`}
              type="number"
              className="form__input"
              step=".01"
              placeholder=""
              title="Iznos uplate"
              value={iznos}
              min={0}
              onChange={(event) => {
                setIznos(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="" className="form__label">
              Datum
            </label>
            <ReactDatePicker
              selected={datum ? new Date(datum) : new Date()}
              onChange={(date) => setDatum(date)}
              className="form__input w-100"
              placeholderText="Datum uplate"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        <div className="col-md-12 mt-3">
          <div className="form__group">
            <label className="form__label">Opis <span className="txt-light"> - Nije Obavezno</span></label>
            

            <textarea
              name="tekst"
              id=""
              cols="30"
              rows="6"
              className="form__input"
              value={opis}
              onChange={(event) => setOpis(event.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderPodsjetnici()}
      <div className="container">
        <div className="row">
          {renderDjelimicnoStavke()}
          {renderStatusi()}
          <div className="col-md-5">
            {values.status === 'djelimicno_placen' && (
              <>
                {renderDjelimicnoPlacanje()}
                <button
                  type="button"
                  onClick={handleDodajNoviIznos}
                  disabled={iznos === '' || datum === ''}
                  className="btn btn__link mt-4"
                >
                  <a>+ Dodaj novi iznos uplate</a>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BezgotovinskiStatusPodsjetnici;
